import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Chip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Check from '@material-ui/icons/Check';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { getDiscount, getPlans } from '~/functions/BuyNowFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '40px',
  },
  portfolioSizeButtons: {
    paddingTop: '40px',
    display: 'flex',
    gap: theme.spacing(2),
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  buttonsContainer: {
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: theme.spacing(1),
    },
    marginBottom: theme.spacing(3),
  },
  unitSelectionButton: {
    color: theme.palette.primary,
    border: '1px solid',
    borderColor: 'rgba(67, 28, 93, .25)',
    padding: '6px 16px',
    textTransform: 'none',
    boxSize: 'border-box',
  },
  unitSelectionButtonSelected: {
    color: theme.palette.primary,
    border: '1px solid',
    borderColor: 'rgba(67, 28, 93, .5)',
    backgroundColor: 'rgba(67, 28, 93, .1)',
    padding: '6px 16px',
    textTransform: 'none',
  },
  cardsContainer: {
    width: '100%',
    maxWidth: '600px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  card: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 6,
    paddingBottom: 6,
    height: '100%',
    maxHeight: '290px',
    width: '100%',
    maxWidth: '264px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '10px',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
  },
  cardContentContainer: {
    width: '95%',
  },
  discountTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  },
  percentOffTextContainer: {
    display: 'flex',
    color: theme.palette.primary.main,
    justifyContent: 'center',
  },
  savingsTextContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    marginBottom: 10,
  },
  unitsIncludedTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  priceAfterTrialTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 32.8,
  },
  buttonContainer: {
    width: '100%',
  },
  boldText: {
    fontWeight: 'bold',
  },
  greyText: {
    color: theme.palette.text.secondary,
  },
  strikeThroughText: {
    textDecoration: 'line-through',
  },
  containedButton: {
    marginTop: '20px',
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    textTransform: 'none',
  },
  outlinedButton: {
    marginTop: '20px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    textTransform: 'none',
  },
  bottomTextContainer: {
    marginTop: 30,
    width: '100%',
    paddingBottom: 20,
  },
  limitedTimeOfferChipContainer: {
    alignSelf: 'flex-start',
    marginTop: '-19px',
    position: 'absolute',
  },
  limitedTimeOfferChip: {
    'borderRadius': '4px',
    'backgroundColor': '#FFCD00',
    'fontWeight': 700,
    'fontSize': '12px',
    'marginBottom': '10px',
    'maxHeight': '24px',
    '& span': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
}));

export default function View({ isLimitedTimeOffer, isFurnishedFinder }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const location = useLocation();
  const isDevelopment =
    process.env.NODE_ENV !== 'production' ||
    process.env.REACT_APP_VERCEL_ENV === 'preview' ||
    window?.location?.href?.includes?.('dev.reihub.net');

  const discount = getDiscount(isLimitedTimeOffer || isFurnishedFinder);

  const plans = getPlans(discount);

  const [plan, setPlan] = useState(plans[0]);
  const unitSelection = plan.unitsIncluded;

  const tracking = useSegmentTrack();
  useEffect(() => {
    tracking('select_pricing loaded', { location: 'Select Pricing Page' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tryItFreeClicked = () => {
    tracking('try_it_free clicked', { location: 'Select Pricing Page', unitSelection });
    history.push('/register');
  };

  const buyNowClicked = () => {
    tracking('buy_now clicked', { location: 'Select Pricing Page', unitSelection });
    if (isLimitedTimeOffer || isFurnishedFinder) {
      return history.push(`/register-profile-limited?unitsIncluded=${unitSelection}`);
    }
    return history.push(`/register-profile?unitsIncluded=${unitSelection}`);
  };

  const unitsButtonClicked = (maxUnits) => {
    if (!maxUnits) {
      tracking('unlimited_units_clicked', { location: 'Buy Now - Select Pricing' });
    } else {
      tracking(`up_to_${maxUnits}_units_clicked`, { location: 'Buy Now - Select Pricing' });
    }
  };

  const getDollarAmount = (price) => `$${price.toString().split('.')[0]}`;

  const getCentsAmount = (price) => {
    const cents = price.toString().split('.')[1];
    if (cents) {
      if (cents.length === 1) {
        return `.${cents}0`;
      }
      return `.${cents}`;
    }
    return '.00';
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography align="center" variant="h4" className={classes.boldText}>
          Simple Pricing for Real Estate Investors
        </Typography>
      </Box>
      <Box className={classes.buttonsContainer}>
        {plans.map((item) => (
          <Button
            key={item.id}
            onClick={() => {
              setPlan(item);
              unitsButtonClicked(item.maxUnits);
            }}
            className={item.id === plan.id ? classes.unitSelectionButtonSelected : classes.unitSelectionButton}
            color="primary"
          >
            {item.maxUnits ? `Up to ${item.maxUnits} Units` : 'Unlimited Units'}
          </Button>
        ))}
      </Box>
      <Box className={classes.cardsContainer}>
        <Card className={classes.card}>
          {location.pathname === '/select-pricing-limited' ? (
            <Box className={classes.limitedTimeOfferChipContainer}>
              <Chip label="Limited Time Offer" className={classes.limitedTimeOfferChip} />
            </Box>
          ) : null}
          {location.pathname === '/furnished-finder' ? (
            <Box className={classes.limitedTimeOfferChipContainer}>
              <Chip label="Furnished Finder Special" className={classes.limitedTimeOfferChip} />
            </Box>
          ) : null}
          <CardContent className={classes.cardContentContainer}>
            <Box className={classes.discountTextContainer}>
              <Typography variant="h4" className={classes.boldText}>
                {getDollarAmount(plan.discountPrice)}
              </Typography>
              <Typography variant="h6" className={classes.boldText}>
                {getCentsAmount(plan.discountPrice)}
              </Typography>
              <Typography variant="h4" className={classes.boldText}>
                /mo
              </Typography>
            </Box>
            <Box className={classes.percentOffTextContainer}>
              <Typography align="center" variant="caption" className={classes.boldText}>
                {/* 50% Off for 3 Months */}
                {`${discount.discountPercent}% Off for ${discount.duration} Months`}
              </Typography>
            </Box>
            <Box className={classes.savingsTextContainer}>
              <Typography variant="caption" className={classes.greyText}>
                {'Was '}
                <Typography variant="caption" className={classes.strikeThroughText}>
                  {`$${plan.price.toFixed(2)}`}
                </Typography>
              </Typography>
              <Typography variant="caption">{' | '}</Typography>
              <Typography variant="caption">{`Save $${(plan.price - plan.discountPrice).toFixed(2)}`}</Typography>
            </Box>
            <Box className={classes.unitsIncludedTextContainer}>
              <Check fontSize="small" />
              <Typography variant="body2">
                {plan.unitsIncluded === 'unlimited'
                  ? 'Unlimited units included'
                  : `Up to ${plan.maxUnits} units included`}
              </Typography>
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                className={classes.containedButton}
                variant="contained"
                color="primary"
                disableElevation
                fullWidth
                onClick={() => buyNowClicked()}
              >
                Buy Now & Save
              </Button>
            </Box>
          </CardContent>
        </Card>
        <Typography align="center" variant="h6" className={classes.boldText}>
          or
        </Typography>
        <Card className={classes.card}>
          <CardContent className={classes.cardContentContainer}>
            <Box className={classes.discountTextContainer}>
              <Typography variant="h4" className={classes.boldText}>
                {`$${plan.price}/mo`}
              </Typography>
            </Box>
            <Box className={classes.priceAfterTrialTextContainer}>
              <Typography align="center" variant="caption" className={classes.boldText}>
                Price after 14 day free trial
              </Typography>
            </Box>
            <Box className={classes.unitsIncludedTextContainer}>
              <Check fontSize="small" />
              <Typography variant="body2">
                {plan.unitsIncluded === 'unlimited'
                  ? 'Unlimited units included'
                  : `Up to ${plan.maxUnits} units included`}
              </Typography>
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                className={classes.outlinedButton}
                variant="outlined"
                color="primary"
                disableElevation
                fullWidth
                onClick={() => tryItFreeClicked()}
              >
                Try it For Free
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box className={classes.bottomTextContainer}>
        <Typography align="center" variant="h6" className={classes.boldText}>
          {discount.footerText
            ? discount.footerText
            : `Buy now and save ${discount.discountPercent}%! Or try for 14 days free — no credit card required.`}
        </Typography>
      </Box>
      {!isDevelopment ? (
        <ReCAPTCHA sitekey="6LdCnJscAAAAANpuCS4G9OWfER1Sphen8uXne-bD" size="invisible" badge="bottomright" />
      ) : null}
    </Box>
  );
}

View.defaultProps = {
  isLimitedTimeOffer: false,
  isFurnishedFinder: false,
};

View.propTypes = {
  isLimitedTimeOffer: PropTypes.bool,
  isFurnishedFinder: PropTypes.bool,
};
