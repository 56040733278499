import React, { useContext, useState } from 'react';
import { ListItemIcon, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { ReportProblemOutlined } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { PersonContext } from '~/contexts/PersonContext';
import { remove } from '~/feathersFunctionalWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
  bulletPointsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(0.5),
  },
  cancelIcon: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.error.main,
    width: `${theme.tokens.components.icon.size.xmd}px`,
    height: `${theme.tokens.components.icon.size.xmd}px`,
  },
  bulletPointContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
  },
  mainCTA: {
    width: '400px',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available',
    },
  },
  secondaryCTA: {
    width: '400px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available',
    },
  },
  actionButtonsContainer: {
    marginTop: theme.spacing(3),
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  alert: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
}));

function CancelSubscriptionDialog({ isOpen, closeDialog, subscriptionName, location, loseAccessItems, title }) {
  const [step, setStep] = useState('cancel');
  const [error, setError] = useState(null);
  const [isRemovingSubscription, setIsRemovingSubscription] = useState(false);
  const classes = useStyles();

  const { organizationId, logOut, adminLogin, expertSupportSubscription, setOrganizationId, triggerSnackbar } =
    useContext(PersonContext);
  const tracking = useSegmentTrack();
  const isExpertBookkeepingSubscription = subscriptionName === 'Expert Bookkeeping Support';

  const handleCloseDialog = () => {
    setStep('cancel');
    closeDialog();
  };

  const handleCancelSubscription = async (event) => {
    event.preventDefault();

    tracking('cancel_my_subscription clicked', { location });

    try {
      setIsRemovingSubscription(true);
      await remove('stripe-subscriptions', organizationId, {
        query: {
          shouldCancelExpertSubscriptionOnly: isExpertBookkeepingSubscription,
        },
      });
      setIsRemovingSubscription(false);
      if (isExpertBookkeepingSubscription) {
        setOrganizationId(organizationId);
        triggerSnackbar({
          horizontal: 'center',
          message: 'You successfully unsubscribed.',
          type: 'success',
        });
        return handleCloseDialog();
      }
      setStep('goodbye');
    } catch (subscriptionErr) {
      setIsRemovingSubscription(false);
      setError({
        message: `We're sorry, your subscription could not be cancelled - please contact us. Message: ${subscriptionErr.message}`,
      });
    }
  };

  const getDialogContent = () => {
    if (step === 'cancel') {
      return (
        <DialogContent>
          <form onSubmit={handleCancelSubscription}>
            <Typography variant="h5" className={classes.title} id="alert-dialog-title">
              {title}
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              You will lose access to:
            </Typography>
            <Box className={classes.bulletPointsBox}>
              {loseAccessItems.map((item, idx) => (
                <ListItemIcon key={idx} className={classes.bulletPointContainer}>
                  <CancelIcon className={classes.cancelIcon} />
                  <Typography variant="body1" color="textPrimary">
                    {item}
                  </Typography>
                </ListItemIcon>
              ))}
            </Box>

            {expertSupportSubscription && subscriptionName === 'REI Hub' ? (
              <Alert icon={<ReportProblemOutlined />} severity="warning" className={classes.alert}>
                You will also be canceling your Expert Support Subscription.
              </Alert>
            ) : null}

            <Box className={classes.actionButtonsContainer}>
              {error && <Typography color="error">{error.message}</Typography>}
              <Button
                variant="contained"
                color="error"
                className={classes.mainCTA}
                type="submit"
                size="large"
                disabled={isRemovingSubscription}
              >
                Cancel My Subscription
              </Button>
              <Button
                variant="outlined"
                className={classes.secondaryCTA}
                size="large"
                color="primary"
                onClick={() => {
                  handleCloseDialog();
                  tracking('go_back clicked', { location });
                }}
              >
                Go Back
              </Button>
            </Box>
          </form>
        </DialogContent>
      );
    }

    if (step === 'goodbye') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Subscription Cancelled</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              We have successfully cancelled your subscription.
            </Typography>
          </DialogContent>
          <DialogActions>
            {adminLogin && (
              <Button onClick={handleCloseDialog} color="primary">
                Close (Admin)
              </Button>
            )}
            {isExpertBookkeepingSubscription ? null : (
              <Button onClick={logOut} color="primary">
                Sign Out
              </Button>
            )}
          </DialogActions>
        </>
      );
    }

    return null;
  };

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {getDialogContent()}
    </Dialog>
  );
}

CancelSubscriptionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  location: PropTypes.string,
  loseAccessItems: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  subscriptionName: PropTypes.string,
};

CancelSubscriptionDialog.defaultProps = {
  title: 'Cancel your REI Hub subscription?',
  subscriptionName: 'REI Hub',
  location: 'Cancel Subscription Modal',
  loseAccessItems: [
    'All your portfolio’s financial records',
    'Seamless real estate-specific bookkeeping',
    'Insights into your rental investments',
    'Easy tax preparation',
  ],
};

export default CancelSubscriptionDialog;
