import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  autocompleteField: {
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(25% - 8px)',
    },
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
  actionsContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  rowActionsSecondaryContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      margin: 'auto',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      marginLeft: 'auto',
    },
    gap: theme.spacing(2.5),
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  errorContainer: {
    border: '1px solid red',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    width: 'fit-content',
    textAlign: 'right',
    marginLeft: 'auto',
  },
  infoAlert: {
    border: `1px solid ${theme.palette.info._states.outlinedBorder}`,
  },
  infoTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
  },
  errorAlert: {
    border: `1px solid ${theme.palette.error._states.outlinedBorder}`,
  },
  mobileDescriptionField: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
  },
  descriptionField: {
    width: 'calc(50% - 16px)',
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
  },
  saveButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  loanBox: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.default}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2.5),
    gap: theme.spacing(2),
  },
  loanAmountsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  loanAmountsRow: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    gap: theme.spacing(2),
  },
  loanAmountField: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '33%',
    },
    backgroundColor: 'white',
  },
  loanAmountsTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    gap: theme.spacing(1),
  },
  expectedLoanAmountText: {
    color: theme.palette.error.dark,
  },
  netIncomeSectionContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    gap: theme.spacing(2),
  },
  netIncomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.default}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(2.5),
    gap: theme.spacing(2),
    width: '100%',
  },
  netIncomeBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  netIncomeRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2),
  },
  netIncomeRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
  },
  netIncomeRowSecondary: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  netIncomeAutocompleteField: {
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '75%',
    },
    borderRadius: theme.shape.borderRadius,
  },
  netIncomeDeleteIcon: {
    'cursor': 'pointer',
    'color': theme.palette.action.active,
    'alignSelf': 'center',
    '&:hover': {
      color: theme.palette.grey[800],
    },
  },
  netIncomeAmountField: {
    backgroundColor: 'white',
  },
  netIncomeBottomRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  netIncomeAmountLine: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    alignSelf: 'center',
  },
  netIncomeNetAmountLine: {
    marginLeft: 'auto',
    alignSelf: 'center',
    marginTop: -theme.spacing(2),
  },
}));
