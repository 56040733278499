import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getFilteredUnitOptions } from '../Autocomplete/Library';
import useScopeFields from '../TransactionFields/hooks/useScopeFields';
import ScopeFields from '../TransactionFields/ScopeFields';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  autocompleteField: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(25% - 8px)',
    },
  },
}));

export default function QuickBookRowScopeSelect({ journal, setJournal }) {
  const {
    isLoading,
    propertyOptions,
    scopeOptions,
    subportfolioOptions,
    unitOptions,
    handlePropertyChange,
    handleScopeChange,
    handleSubportfolioChange,
    handleUnitChange,
  } = useScopeFields({
    setJournal,
  });

  const classes = useStyles();

  if (isLoading) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <ScopeFields.TransactionScopeField
        className={classes.autocompleteField}
        journal={journal}
        options={scopeOptions}
        onChange={handleScopeChange}
      />
      <ScopeFields.SubportfolioField
        className={classes.autocompleteField}
        journal={journal}
        options={subportfolioOptions}
        onChange={handleSubportfolioChange}
      />
      <ScopeFields.PropertyField
        className={classes.autocompleteField}
        journal={journal}
        options={propertyOptions}
        onChange={handlePropertyChange}
      />
      {unitOptions.length > 0 && (
        <ScopeFields.UnitField
          className={classes.autocompleteField}
          journal={journal}
          options={getFilteredUnitOptions(unitOptions, journal.propertyIdSelect?.id, { addAll: true })}
          onChange={handleUnitChange}
        />
      )}
    </Box>
  );
}

QuickBookRowScopeSelect.propTypes = {
  journal: PropTypes.object.isRequired,
  setJournal: PropTypes.func.isRequired,
};
