import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { find, remove } from '../feathersWrapper';
import { handleCheckboxChange } from '../functions/InputHandlers';

import DialogTemplate from './DialogTemplate';

// const styles = {};

class RemovePortfolioDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      step: 'cancel',
      confirmCancel: false,
      contactUs: false,
    };
  }

  removePortfolio = async (event) => {
    event.preventDefault();

    const { confirmCancel } = this.state;
    const { organizationId, closeDialog, onModified } = this.props;
    const { id } = this.context;

    if (!confirmCancel) {
      return;
    }

    const adminResult = await find(this, 'administrators', {
      query: {
        personId: id,
        organizationId,
        $limit: 1,
      },
    });

    for (let i = 0; i < adminResult.data.length; i++) {
      await remove(this, 'administrators', adminResult.data[i].id);
    }

    onModified();
    closeDialog();
  };

  getDialogContent = () => {
    const { step, confirmCancel } = this.state;
    const { closeDialog } = this.props;

    if (step === 'cancel') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Really Remove Portfolio?</DialogTitle>
          <DialogContent>
            <form onSubmit={this.removePortfolio}>
              <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                <Typography variant="body1">
                  Are you sure you want to remove this portfolio and its data from your account?
                </Typography>
              </Box>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={confirmCancel}
                      onChange={handleCheckboxChange(this)}
                      name="confirmCancel"
                      required
                    />
                  }
                  label="Yes, remove portfolio"
                />
              </FormControl>
              <Box marginTop={2} marginBottom={1}>
                <Typography color="error">
                  <Button
                    variant="outlined"
                    color="inherit"
                    type="submit"
                    size="large"
                    disabled={!confirmCancel}
                    fullWidth
                    disableElevation
                  >
                    Remove Portfolio
                  </Button>
                </Typography>
              </Box>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                fullWidth
                disableElevation
                onClick={closeDialog}
              >
                Nevermind
              </Button>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.Intercom('show')} color="primary">
              Contact Us
            </Button>
          </DialogActions>
        </>
      );
    }

    return null;
  };

  render() {
    return (
      <DialogTemplate
        open
        scroll="body"
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.getDialogContent()}
      </DialogTemplate>
    );
  }
}

RemovePortfolioDialog.contextType = PersonContext;

RemovePortfolioDialog.defaultProps = {};

RemovePortfolioDialog.propTypes = {
  organizationId: PropTypes.number.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onModified: PropTypes.func.isRequired,
};

export default RemovePortfolioDialog;
