import React, { useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

import useUpdateUserOnboarding from '../hooks/useUpdateUserOnboarding';

const SEGMENT_LOCATION = 'Onboarding - Questions: About You';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  boxWrapper: {
    boxShadow:
      '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
    backgroundColor: theme.palette.background.paper,
    padding: '24px 20px',
    borderRadius: '4px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '900px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '600px',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  formControlLabel: {
    marginLeft: '9px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  submitButton: {
    marginInline: 'auto',
    color: theme.palette.primary.contrastText,
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
  },
  backButton: {
    marginInline: 'auto',
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
  },
}));

export default function AboutYou() {
  const [totalUnits, setTotalUnits] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');

  const { userOnboarding } = useOnboardingContext();

  useEffect(() => {
    if (userOnboarding?.totalUnits >= 0) {
      setTotalUnits(userOnboarding.totalUnits);
    }
    if (userOnboarding?.yearsOfExperience) {
      setYearsOfExperience(userOnboarding.yearsOfExperience);
    }
  }, [userOnboarding?.totalUnits, userOnboarding?.yearsOfExperience]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateUserOnboarding = useUpdateUserOnboarding();
  const tracking = useSegmentTrack();
  const history = useHistory();
  const { triggerSnackbar } = useContext(PersonContext);

  const onSubmit = async (event) => {
    try {
      event.preventDefault();

      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      await updateUserOnboarding({
        totalUnits,
        yearsOfExperience,
      });

      tracking('next clicked', { location: SEGMENT_LOCATION });

      history.push('/previous-process');
    } catch {
      setIsSubmitting(false);
      triggerSnackbar({
        type: 'error',
        message: 'Something went wrong. Please try again.',
      });
    }
  };

  const onBack = () => {
    tracking('go_back clicked', { location: SEGMENT_LOCATION });
    history.goBack();
  };

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.boxWrapper}>
        <form onSubmit={onSubmit}>
          <Box mx="auto" mb={2}>
            <Typography variant="h6" gutterBottom>
              How many total rental units do you own or manage?
            </Typography>
          </Box>
          <Box mb={2}>
            <FormControl margin="dense" fullWidth>
              <InputLabel required>Number of Units</InputLabel>
              <NumberFormat
                value={totalUnits}
                required
                thousandSeparator
                decimalScale={0}
                fixedDecimalScale
                onValueChange={(value) => {
                  const { floatValue } = value;
                  setTotalUnits(floatValue);
                }}
                customInput={Input}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === '' || (floatValue <= 999 && floatValue >= 0);
                }}
              />
            </FormControl>
          </Box>
          <Box mb={3}>
            <Typography variant="h6">How long have you owned or managed rentals?</Typography>
            <RadioGroup
              aria-label="time owned"
              name="yearsOfExperience"
              value={yearsOfExperience}
              onChange={(event) => setYearsOfExperience(event.target.value)}
              required
              className={classes.formControlLabel}
            >
              <FormControlLabel value="LESS_THAN_YEAR" control={<Radio required />} label="Less than a year" />
              <FormControlLabel value="ONE_TO_FOUR_YEARS" control={<Radio required />} label="1-4 years" />
              <FormControlLabel value="FIVE_TO_TEN_YEARS" control={<Radio required />} label="5-10 years" />
              <FormControlLabel value="MORE_THAN_TEN_YEARS" control={<Radio required />} label="More than 10 years" />
              <FormControlLabel
                value="DONT_MANAGE_RENTALS"
                control={<Radio required />}
                label="I don’t manage any rentals yet"
              />
            </RadioGroup>
          </Box>

          <Box className={classes.buttonsContainer}>
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
              className={classes.submitButton}
            >
              Next
            </Button>
            <Button
              color="secondary"
              disabled={isSubmitting}
              size="large"
              fullWidth
              variant="outlined"
              onClick={onBack}
              className={classes.backButton}
            >
              Go Back
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
