import React, { forwardRef } from 'react';
import MuiIconButton from '@material-ui/core/IconButton';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles, { COLOR, SIZE } from './styles';

const IconButton = forwardRef(({ children, className, color = COLOR.DEFAULT, size = SIZE.MEDIUM, ...rest }, ref) => {
  const classes = useStyles({ color, size });

  return (
    <MuiIconButton className={cx(classes.iconButton, className)} ref={ref} {...rest}>
      {children}
    </MuiIconButton>
  );
});

IconButton.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  'children': PropTypes.node.isRequired,
  'className': PropTypes.string,
  'color': PropTypes.oneOf(Object.values(COLOR)),
  'size': PropTypes.oneOf(Object.values(SIZE)),
};

export default IconButton;
