import React from 'react';
import PropTypes from 'prop-types';

import { addressLabel, nameLabel } from '~/components/Autocomplete/Library';

import AutocompleteField, { autocompleteFieldProps } from './AutocompleteField';

function TransactionScopeField({ journal = {}, label = 'Transaction Scope', required = true, ...rest }) {
  return (
    <AutocompleteField
      label={label}
      required={required}
      value={journal.journalScopeSelect}
      getOptionLabel={nameLabel}
      {...rest}
    />
  );
}

const commonProps = {
  ...autocompleteFieldProps,
  journal: PropTypes.object.isRequired,
};

TransactionScopeField.propTypes = commonProps;

function SubportfolioField({ journal = {}, label = 'Sub-Portfolio', required = true, ...rest }) {
  if (journal.journalScopeSelect?.id !== 'Sub-Portfolio') {
    return null;
  }

  return (
    <AutocompleteField
      label={label}
      required={required}
      value={journal.entityIdSelect}
      getOptionLabel={nameLabel}
      {...rest}
    />
  );
}

SubportfolioField.propTypes = commonProps;

function PropertyField({ journal = {}, label = 'Property', required = true, ...rest }) {
  if (journal.journalScopeSelect?.id !== 'Property/Unit') {
    return null;
  }

  return (
    <AutocompleteField
      label={label}
      required={required}
      value={journal.propertyIdSelect}
      getOptionLabel={addressLabel}
      {...rest}
    />
  );
}

PropertyField.propTypes = commonProps;

function UnitField({ journal = {}, label = 'Unit', required = true, ...rest }) {
  const isMultiUnitProperty =
    journal.journalScopeSelect?.id === 'Property/Unit' && journal.propertyIdSelect?.unitSelection === 'multi';

  if (!isMultiUnitProperty) {
    return null;
  }

  return (
    <AutocompleteField
      label={label}
      required={required}
      value={journal.unitIdSelect}
      getOptionLabel={nameLabel}
      {...rest}
    />
  );
}

UnitField.propTypes = commonProps;

const ScopeFields = {
  TransactionScopeField,
  SubportfolioField,
  PropertyField,
  UnitField,
};

export default ScopeFields;
