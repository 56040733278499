export const SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};
export const VARIANT = {
  STANDARD: 'standard',
  FILLED: 'filled',
  OUTLINED: 'outlined',
};
