import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import { Widget } from '@uploadcare/react-widget/dist/cjs';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';

import { Autocomplete } from '@material-ui/lab';
import { ButtonBase } from '@material-ui/core';
import { find, get, create } from '../../feathersWrapper';
import { PersonContext } from '../../contexts/PersonContext';
import {
  asyncSetState,
  asyncHandleChange,
  handleTextFieldChange,
  handleChange,
  handleNumberFormatChange,
  handleKeyboardDatePickerChange,
  handleUploaderChange,
  handleTransactionScopeChange,
  handleAutocompleteChange,
} from '../../functions/InputHandlers';

import { sumProperty } from '../../functions/SumFunctions';

import { getManualBasicJournal, getDebitJournalLine } from '../SearchSelect/TransactionTypeOptions';

import LinkBase from '../LinkBase';

import TransactionScope from '../TransactionScope';
import { setJournalScope, setInitialJournalScope } from '../../functions/JournalFunctions';
import { getPaymentAccountOptions, nameLabel } from '../Autocomplete/Library';

const styles = (theme) => ({
  amountCell: {
    width: '30%',
    maxWidth: '120px',
  },
  hideWidget: {
    display: 'none',
  },
  red: {
    color: 'red',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddLoanPaymentDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  async componentDidMount() {
    const { yodleeTransaction, loanAccountId, loanAccountName } = this.props;
    this.setState(await this.getInitialState());
    await asyncSetState({ loading: false }, this);
    if (yodleeTransaction) {
      if (yodleeTransaction.account.type === 'Liability') {
        await asyncHandleChange('nested_journal_debitLines_0_yodleeTransactionId', yodleeTransaction.id, this);
        this.handleLoanAccountChange(
          'nested_journal_debitLines_0_accountIdSelect',
          {
            id: yodleeTransaction.accountId,
            name: yodleeTransaction.account.name,
          },
          false,
        );
      } else {
        const { journal } = this.state;
        const newCreditLine = { ...journal.creditLines[0] };
        newCreditLine.accountId = yodleeTransaction.accountId;
        newCreditLine.accountIdSelect = {
          id: yodleeTransaction.accountId,
          name: yodleeTransaction.account.name,
        };
        newCreditLine.yodleeTransactionId = yodleeTransaction.id;
        await asyncHandleChange('nested_journal_creditLines_0', newCreditLine, this);
        if (loanAccountId) {
          this.handleLoanAccountChange(
            'nested_journal_debitLines_0_accountIdSelect',
            {
              id: loanAccountId,
              name: loanAccountName,
            },
            false,
          );
        }
      }
    }
  }

  getInitialState = async () => {
    const { organizationId } = this.context;
    const { yodleeTransaction } = this.props;
    const initialState = {
      submitting: false,
      journal: null,
      loanAccount: null,
      loanAccounts: [],
      templateIncomplete: false,
      key: 'paymentOverview',
      error: null,
      displayNotesSection: false,
    };

    const loanAccounts = await find(this, 'accounts', {
      query: {
        organizationId,
        type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
        inactive: { $or: [null, false] },
        $limit: 500,
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    initialState.loanAccounts = loanAccounts.data;
    initialState.paymentAccountOptions = await getPaymentAccountOptions(this);

    if (loanAccounts.total === 0) {
      initialState.key = 'noLoans';
    }

    const journal = getManualBasicJournal();
    journal.debitLines.push(getDebitJournalLine());
    journal.type = 'Loan Payment';
    journal.description = 'Loan Payment';

    if (yodleeTransaction) {
      journal.date = yodleeTransaction.date;
      journal.creditLines[0].credit = yodleeTransaction.amount;
    }

    initialState.journal = journal;

    return initialState;
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    return value;
  };

  getStepContent = (key) => {
    const { journal, loanAccount, error, loanAccounts, paymentAccountOptions, displayNotesSection } = this.state;
    const { classes, yodleeTransaction } = this.props;

    switch (key) {
      case 'noLoans':
        return (
          <>
            <DialogTitle id="alert-dialog-title">No Active Loan Accounts Found</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                You must add a loan account before booking payments.&nbsp;
                <LinkBase to="/accounts/loans?add=1">Click here to add accounts</LinkBase>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </>
        );
      case 'paymentOverview':
        return (
          <form onSubmit={this.goToPaymentDetails}>
            <DialogTitle id="alert-dialog-title">Add Loan Payment (1 of 2)</DialogTitle>
            <DialogContent>
              {yodleeTransaction && (
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Transaction
                  </FormLabel>
                  <Typography>
                    {moment(yodleeTransaction.date).format('M/D/YYYY')}
                    <Box px={2} component="span">
                      |
                    </Box>
                    <NumberFormat
                      displayType="text"
                      value={yodleeTransaction.amount}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                    />
                    <Box px={2} component="span">
                      |
                    </Box>
                    {yodleeTransaction.account.name}
                  </Typography>
                </FormControl>
              )}
              <KeyboardDatePicker
                label="Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={journal.date}
                onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <Autocomplete
                options={loanAccounts}
                getOptionLabel={nameLabel}
                value={journal.debitLines[0].accountIdSelect}
                onChange={(event, value) =>
                  this.handleLoanAccountChange('nested_journal_debitLines_0_accountIdSelect', value, false)
                }
                getOptionSelected={(option, value) => option.id === value.id}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Loan Account"
                    margin="dense"
                    fullWidth
                    required
                  />
                )}
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel required>Payment Amount</InputLabel>
                <NumberFormat
                  value={journal.creditLines[0].credit}
                  required
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={handleNumberFormatChange('nested_journal_creditLines_0_credit', this)}
                  customInput={Input}
                />
                {loanAccount &&
                !loanAccount.templateIncomplete &&
                journal.creditLines[0].credit !== loanAccount.mortgagePaymentAmount ? (
                  <FormHelperText>
                    {'The payment amount does not match your template. '}
                    <LinkBase to={`/accounts/loans?edit=${loanAccount.id}`}>Click here to update</LinkBase>
                  </FormHelperText>
                ) : null}
                <TransactionScope
                  journal={journal}
                  transactionScopeChange={(newScopeValues) => {
                    handleTransactionScopeChange(journal, newScopeValues, this);
                  }}
                />
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Continue
              </Button>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        );
      case 'paymentDetail':
        return (
          <form onSubmit={this.addTransaction}>
            <DialogTitle id="alert-dialog-title">Add Loan Payment (2 of 2)</DialogTitle>
            <DialogContent>
              {(loanAccount.templateIncomplete ||
                journal.creditLines[0].credit !== loanAccount.mortgagePaymentAmount) && (
                <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                  <Typography variant="body2">
                    {
                      'Did you know: We can make entering loan payments easier by automatically splitting your principal, interest, and escrow. Update your loan payment template by '
                    }
                    <LinkBase to={`/accounts/loans?edit=${loanAccount.id}`}>clicking here</LinkBase>
                  </Typography>
                </Box>
              )}
              {yodleeTransaction && (
                <FormControl margin="dense" fullWidth>
                  <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                    Transaction
                  </FormLabel>
                  <Typography>
                    {moment(yodleeTransaction.date).format('M/D/YYYY')}
                    <Box px={2} component="span">
                      |
                    </Box>
                    <NumberFormat
                      displayType="text"
                      value={yodleeTransaction.amount}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                    />
                    <Box px={2} component="span">
                      |
                    </Box>
                    {yodleeTransaction.account.name}
                  </Typography>
                </FormControl>
              )}
              <Autocomplete
                options={paymentAccountOptions}
                getOptionLabel={nameLabel}
                value={journal.creditLines[0].accountIdSelect}
                onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {
                      ...params /* eslint-disable-line react/jsx-props-no-spreading */
                    }
                    label="Paid From Account"
                    margin="dense"
                    fullWidth
                    required
                  />
                )}
              />
              <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                <FormControl margin="dense" fullWidth>
                  <InputLabel required>Principal Amount</InputLabel>
                  <NumberFormat
                    value={journal.debitLines[0].debit}
                    thousandSeparator
                    required
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={handleNumberFormatChange('nested_journal_debitLines_0_debit', this)}
                    customInput={Input}
                  />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <InputLabel required>Interest Amount</InputLabel>
                  <NumberFormat
                    value={journal.debitLines[1].debit}
                    thousandSeparator
                    required
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={handleNumberFormatChange('nested_journal_debitLines_1_debit', this)}
                    customInput={Input}
                  />
                </FormControl>
                {loanAccount.mortgageEscrowAccountId && (
                  <FormControl margin="dense" fullWidth>
                    <InputLabel required>Escrow Amount</InputLabel>
                    <NumberFormat
                      value={journal.debitLines[2].debit}
                      thousandSeparator
                      required
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                      onValueChange={handleNumberFormatChange('nested_journal_debitLines_2_debit', this)}
                      customInput={Input}
                    />
                  </FormControl>
                )}
                <Typography variant="body2" component="div">
                  <NumberFormat
                    displayType="text"
                    value={sumProperty(journal.debitLines, 'debit')}
                    thousandSeparator
                    prefix="Total: $"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
                {parseFloat(sumProperty(journal.debitLines, 'debit')) !== parseFloat(journal.creditLines[0].credit) && (
                  <Typography variant="body2" color="error">
                    <NumberFormat
                      displayType="text"
                      value={journal.creditLines[0].credit}
                      thousandSeparator
                      prefix="Expected: $"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </Typography>
                )}
              </Box>
              <TextField
                label="Description"
                fullWidth
                margin="dense"
                InputProps={{
                  value: journal.description,
                  name: 'nested_journal_description',
                  onChange: handleTextFieldChange(this),
                }}
              />
              {displayNotesSection && (
                <TextField
                  label="Additional Notes (optional)"
                  fullWidth
                  multiline
                  minRows="3"
                  maxRows="8"
                  variant="filled"
                  color="secondary"
                  margin="dense"
                  InputProps={{
                    value: journal.notes,
                    name: 'nested_journal_notes',
                    onChange: handleTextFieldChange(this),
                  }}
                />
              )}
              {!displayNotesSection && (
                <Box pb={2} pt={2}>
                  <ButtonBase
                    component="div"
                    className={classes.notesButton}
                    onClick={() => {
                      this.setState({ displayNotesSection: true });
                    }}
                  >
                    Add Additional Notes
                  </ButtonBase>
                </Box>
              )}
              <FormControl margin="dense">
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  Attachments
                </FormLabel>
                <div className={journal.attachmentURL ? null : classes.hideWidget}>
                  <Widget
                    ref={this.uploadcareWidget}
                    multiple
                    tabs="file url camera"
                    value={journal.attachmentURL}
                    onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
                  />
                </div>
                {!journal.attachmentURL && (
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.editImageButton}
                    onClick={() => {
                      this.uploadcareWidget.current.openDialog();
                    }}
                  >
                    Click to Add Pictures or Files
                  </Button>
                )}
                {journal.attachmentURL && (
                  <Button
                    color="primary"
                    className={classes.editImageButton}
                    onClick={async () => {
                      await asyncHandleChange('nested_journal_attachmentURL', '', this);
                      this.uploadcareWidget.current.reloadInfo();
                    }}
                  >
                    Remove All Attachments
                  </Button>
                )}
              </FormControl>
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" color="primary" disableElevation>
                Save Transaction
              </Button>
              <Button onClick={this.closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        );
      default:
        return null;
    }
  };

  handleLoanAccountChange = async (name, value) => {
    if (value !== null) {
      const { yodleeTransaction } = this.props;
      const account = await get(this, 'accounts', value.id);

      const { journal } = this.state;
      const newJournal = { ...journal };
      if (!yodleeTransaction) {
        newJournal.creditLines[0].credit = account.mortgagePaymentAmount;
      }
      newJournal.propertyId = account.propertyId;
      newJournal.entityId = account.entityId;
      newJournal.unitId = account.unitId;
      await setInitialJournalScope(newJournal, this);

      await asyncHandleChange('journal', newJournal, this);

      if (
        account.mortgagePaymentAmount === null ||
        account.lastCurrentBalance === null ||
        account.mortgageInterestRate === null
      ) {
        account.templateIncomplete = true;
      }
      if (account.mortgageEscrowAccountId && account.mortgageEscrowTransferAmount === null) {
        account.templateIncomplete = true;
      }

      if (account.templateIncomplete) {
        account.help =
          'Simplify booking loan payments by using a payment template. To complete your template, please add the:';
        account.help = account.mortgagePaymentAmount === null ? `${account.help} payment amount,` : account.help;
        account.help = account.lastCurrentBalance === null ? `${account.help} current balance,` : account.help;
        account.help = account.mortgageInterestRate === null ? `${account.help} interest rate,` : account.help;
        account.help =
          account.mortgageEscrowAccountId && account.mortgageEscrowTransferAmount === null
            ? `${account.help} escrow transfer,`
            : account.help;
        account.help = account.help.substring(0, account.help.length - 1);
        account.help = `${account.help}. `;
      }
      handleChange('loanAccount', account, this);
    }

    handleChange(name, value, this);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  goToPaymentDetails = async (event) => {
    event.preventDefault();
    const { journal, loanAccount } = this.state;
    const { organizationId } = this.context;

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    journal.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;

    const loanInterestAccountData = await find(this, 'accounts', {
      query: {
        organizationId,
        default: loanAccount.type2 === 'Mortgage' ? 'mortgageInterestExpense' : 'otherInterestExpense',
      },
    });
    journal.debitLines[1].accountId = loanInterestAccountData.data[0].id;

    setJournalScope(journal);

    if (!journal.creditLines[0].yodleeTransactionId && loanAccount.mortgagePaymentAccountId) {
      journal.creditLines[0].accountId = loanAccount.mortgagePaymentAccountId;
      journal.creditLines[0].accountIdSelect = journal.creditLines[0].accountId
        ? {
            id: loanAccount.mortgagePaymentAccountId,
            name: loanAccount.mortgagePaymentAccount.name,
          }
        : null;
    }

    if (loanAccount.mortgageEscrowAccountId) {
      journal.debitLines.push(getDebitJournalLine());
      journal.debitLines[2].accountId = loanAccount.mortgageEscrowAccountId;
      if (loanAccount.mortgagePaymentAmount === journal.creditLines[0].credit) {
        journal.debitLines[2].debit = loanAccount.mortgageEscrowTransferAmount;
      }
    }

    if (loanAccount.mortgagePaymentAmount === journal.creditLines[0].credit) {
      if (!loanAccount.templateIncomplete) {
        let newTransactions;
        let startingBalance = loanAccount.lastCurrentBalance;
        let loanPI = loanAccount.mortgagePaymentAmount;
        if (loanAccount.mortgageEscrowAccountId) {
          loanPI -= loanAccount.mortgageEscrowTransferAmount;
        }
        if (journal.date > loanAccount.lastCurrentBalanceDate) {
          newTransactions = await create(this, 'reports', {
            organizationId,
            reportName: 'accountJournalTotals',
            startDate: moment(loanAccount.lastCurrentBalanceDate).add(1, 'days').format('YYYY-MM-DD'),
            endDate: journal.date,
            accountId: loanAccount.id,
          });
          startingBalance = parseFloat(loanAccount.lastCurrentBalance) + parseFloat(newTransactions[0].netCredits);
        } else {
          newTransactions = await create(this, 'reports', {
            organizationId,
            reportName: 'accountJournalTotals',
            endDate: loanAccount.lastCurrentBalanceDate,
            startDate: journal.date,
            accountId: loanAccount.id,
          });
          startingBalance =
            (loanAccount.lastCurrentBalance + loanPI - newTransactions[0].netCredits) /
            (1 + loanAccount.mortgageInterestRate / 12);
        }
        const interest =
          Math.round(startingBalance * parseFloat(loanAccount.mortgageInterestRate / 12).toPrecision(12) * 100) / 100;

        journal.debitLines[1].debit = interest;
        journal.debitLines[0].debit = loanPI - interest;
      }
    }

    this.setState({
      journal: { ...journal },
      key: 'paymentDetail',
      error: null,
    });
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const { submitting, journal } = this.state;

    const { onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    const debitAmount = sumProperty(journal.debitLines, 'debit');
    const creditAmount = sumProperty(journal.creditLines, 'credit');

    if (debitAmount !== creditAmount) {
      this.setState({ error: { message: 'The total does not match the expected payment amount' } });
      return;
    }

    this.setState({ submitting: true });

    journal.organizationId = organizationId;
    journal.amount = debitAmount;
    journal.creditLines[0].accountId = journal.creditLines[0].accountIdSelect.id;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { loading, key } = this.state;

    if (loading) {
      return null;
    }

    return this.getStepContent(key);
  }
}

AddLoanPaymentDialogContent.contextType = PersonContext;

AddLoanPaymentDialogContent.defaultProps = {
  yodleeTransaction: null,
  loanAccountId: null,
  loanAccountName: null,
};

AddLoanPaymentDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
  loanAccountId: PropTypes.node,
  loanAccountName: PropTypes.node,
};

export default withStyles(styles)(AddLoanPaymentDialogContent);
