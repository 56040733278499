import React, { useState } from 'react';
import { Box, makeStyles, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';

import DialogTemplate from './DialogTemplate';

const useStyles = makeStyles((theme) => ({
  autocompleteFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

export default function FilterAccountsDialog({ filter, closeDialog, updateFilter, institutionNames, accountMasks }) {
  const classes = useStyles();
  const [filterState, setFilterState] = useState(filter);

  const filterUpdate = (event) => {
    event.preventDefault();
    updateFilter(filterState);
    closeDialog();
  };

  return (
    <DialogTemplate
      open
      scroll="body"
      maxWidth="sm"
      fullWidth
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="editAccountDialog"
    >
      <form onSubmit={filterUpdate}>
        <DialogTitle id="alert-dialog-title">Filter Accounts</DialogTitle>
        <DialogContent>
          <Box className={classes.autocompleteFieldsContainer}>
            <Autocomplete
              options={institutionNames}
              value={filterState.institutionName}
              getOptionLabel={(option) => option}
              getOptionSelected={(option, value) => option === value}
              onChange={(_event, value) => {
                setFilterState({ ...filterState, institutionName: value });
              }}
              renderInput={(params) => <TextField {...params} label="Financial Institution Name" fullWidth />}
            />
            <Autocomplete
              options={accountMasks}
              value={filterState.accountMask}
              getOptionLabel={(option) => `xxxx-${option}`}
              getOptionSelected={(option, value) => option === value}
              onChange={(_event, value) => {
                setFilterState({ ...filterState, accountMask: value });
              }}
              renderInput={(params) => <TextField {...params} label="Last Four Digits of Account Number" fullWidth />}
            />
          </Box>
          <FormControl margin="none" fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterState.showInactive}
                  onChange={(event) => {
                    setFilterState({ ...filterState, showInactive: event.target.checked });
                  }}
                />
              }
              label="Show inactive accounts"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Update Filter
          </Button>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </DialogTemplate>
  );
}

FilterAccountsDialog.defaultProps = {};

FilterAccountsDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  institutionNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  accountMasks: PropTypes.arrayOf(PropTypes.string).isRequired,
};
