import { paramsForServer } from 'feathers-hooks-common';

import client from './feathers';

export const get = (service, id) => {
  const feathersParams = paramsForServer({ url: window.location.href });
  return client.service(service).get(id, feathersParams);
};

export const find = (service, params) => {
  const deafaultParams = paramsForServer({ url: window.location.href });
  const feathersParams = {
    ...deafaultParams,
    ...params,
  };
  return client.service(service).find(feathersParams);
};

export const create = (service, newInstance) => {
  const feathersParams = paramsForServer({ url: window.location.href });
  return client.service(service).create(newInstance, feathersParams);
};

export const patch = (service, id, data) => {
  const feathersParams = paramsForServer({ url: window.location.href });
  return client.service(service).patch(id, data, feathersParams);
};

export const patchMultiple = (service, params, data) => {
  const deafaultParams = paramsForServer({ url: window.location.href });
  const feathersParams = {
    ...deafaultParams,
    ...params,
  };
  return client.service(service).patch(null, data, feathersParams);
};

export const remove = (service, id, params = {}) => {
  const defaultParams = paramsForServer({
    url: window.location.href,
  });

  const feathersParams = {
    ...defaultParams,
    ...params,
  };

  return client.service(service).remove(id, feathersParams);
};

export const removeMultiple = (service, params) => {
  const deafaultParams = paramsForServer({ url: window.location.href });
  const feathersParams = {
    ...deafaultParams,
    ...params,
  };
  return client.service(service).remove(null, feathersParams);
};
