import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { PersonContext } from '../contexts/PersonContext';
import { asyncSetState } from '../functions/InputHandlers';
import history from '../history';

const styles = (theme) => ({
  tooltip: {
    position: 'relative',
    width: '100%',
    zIndex: theme.zIndex.drawer + 2,
    marginTop: '-50px',
  },
  arrow: {
    zIndex: theme.zIndex.drawer + 3,
    position: 'relative',
    textAlign: 'center',
    top: '-1px',
    marginBottom: '-16px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  checks: {
    marginRight: '16px',
    color: theme.palette.primary.main,
  },
});

class DemoTour extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      runTour: true,
      stepIndex: 0,
    };
  }

  async componentDidMount() {
    await asyncSetState({ run: true }, this);
    setTimeout(() => {
      document.activeElement.blur();
    }, 100);
  }

  getStep = (index) => {
    const { partnerName } = this.context;
    const { classes } = this.props;
    let stepArray = [
      {
        isDialog: true,
        url: '/dashboard',
        content: (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Typography variant="h5" gutterBottom>
                Welcome!
              </Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`REI Hub helps you keep easy, accurate books for your properties.
                  We'll guide you through our site to show you how.`}
              </Typography>
            </Box>
            <Box pb={1}>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} px={3}>
                <Button color="secondary" variant="outlined" size="large" fullWidth onClick={this.nextStep}>
                  Next
                </Button>
                <Button color="primary" onClick={this.closeTour}>
                  Close
                </Button>
              </Box>
            </Box>
          </DialogContent>
        ),
      },
      {
        url: '/properties',
        content: (
          <>
            <DialogTitle id="alert-dialog-title">Properties</DialogTitle>
            <DialogContent>
              <Typography variant="body1">REI Hub is built for property-based bookkeeping. Use it to track:</Typography>
              <Box mt={1.5} ml={1.5}>
                <Typography align="left" variant="body1">
                  <span className={classes.checks}>✔</span>
                  Single and multi-unit properties
                </Typography>
                <Typography align="left" variant="body1">
                  <span className={classes.checks}>✔</span>
                  Long and short term rentals
                </Typography>
                <Typography align="left" variant="body1">
                  <span className={classes.checks}>✔</span>
                  Residential and commercial
                </Typography>
                <Typography align="left" variant="body1">
                  <span className={classes.checks}>✔</span>
                  Flips and rehabs
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.nextStep} color="primary" variant="contained" disableElevation>
                Next
              </Button>
              <Button onClick={this.closeTour} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ),
      },
    ];

    if (partnerName) {
      stepArray.push({
        url: '/accounts/partner',
        content: (
          <>
            <DialogTitle id="alert-dialog-title">{partnerName}</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`We'll automatically import your collected payments from ${partnerName}.
                Our systems work together to give you instant access to up-to-date financials with all transactions booked to the appropriate property or unit.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.nextStep} color="primary" variant="contained" disableElevation>
                Next
              </Button>
              <Button onClick={this.closeTour} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ),
      });
    }

    stepArray = stepArray.concat([
      {
        url: '/accounts/banking',
        content: (
          <>
            <DialogTitle id="alert-dialog-title">Bank Accounts</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`REI Hub securely connects to the bank and credit card accounts that you use to manage your business.
                  Over 20,000 financial institutions are supported through our networks.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.nextStep} color="primary" variant="contained" disableElevation>
                Next
              </Button>
              <Button onClick={this.closeTour} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ),
      },
      {
        url: '/accounts/banking/import-feed',
        content: (
          <>
            <DialogTitle id="alert-dialog-title">Transaction Import Feed</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Recent transactions in linked accounts are automatically downloaded to your import feed.
                  Use our built in real estate transaction templates to categorize transactions to the appropriate expense account and property in just a few clicks - or create matching rules to start doing the work for you.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.nextStep} color="primary" variant="contained" disableElevation>
                Next
              </Button>
              <Button onClick={this.closeTour} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ),
      },
      {
        url: '/accounts/loans',
        content: (
          <>
            <DialogTitle id="alert-dialog-title">Loans</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Add mortgages and other loans to complete your financial picture.
                  REI Hub even recreates your amortization schedule to split each month's payment into principal, interest, and escrow components
                  for accurate views of profitability and cashflow.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.nextStep} color="primary" variant="contained" disableElevation>
                Next
              </Button>
              <Button onClick={this.closeTour} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ),
      },
      {
        url: '/reports',
        content: (
          <>
            <DialogTitle id="alert-dialog-title">Reports</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`Understand your portfolio's performance year-round with 30+ built-in reports.
                At tax time, automatically check your financials for accuracy and commonly overlooked real estate deductions
                with the Tax Review and then download everything that you or your CPA needs to complete your return in the Tax Packet.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.nextStep} color="primary" variant="contained" disableElevation>
                Next
              </Button>
              <Button onClick={this.closeTour} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ),
      },
      {
        url: '/chart-of-accounts',
        content: (
          <>
            <DialogTitle id="alert-dialog-title">Chart of Accounts</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {`For easy bookkeeping, REI Hub's default expense categories align with the IRS Schedule E filed by most rental property owners.
                To track additional categories, accounts or sub-accounts can be created by editing the chart of accounts.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.nextStep} color="primary" variant="contained" disableElevation>
                Next
              </Button>
              <Button onClick={this.closeTour} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ),
      },
      {
        isDialog: true,
        url: '/dashboard',
        content: (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Typography variant="h5" gutterBottom>
                Congratulations!
              </Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`You've got the basics.
                Close the tour to keep exploring our demo portfolio or get started now on your own bookkeeping.`}
              </Typography>
            </Box>
            <Box pb={1}>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} px={3}>
                <Button color="secondary" variant="outlined" size="large" fullWidth onClick={this.closeTour}>
                  Keep Exploring
                </Button>
                <Box mt={1}>
                  <Button color="secondary" variant="outlined" size="large" fullWidth onClick={this.getStarted}>
                    Get Started Now
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        ),
      },
    ]);
    return stepArray[index];
  };

  nextStep = async () => {
    const { stepIndex } = this.state;
    const nextStep = this.getStep(stepIndex + 1);

    await history.push(nextStep.url);
    this.setState({ stepIndex: stepIndex + 1 });
  };

  closeTour = async () => {
    this.setState({ runTour: false });
  };

  getStarted = async () => {
    const { id, logOut, partnerName, setOrganizationId } = this.context;

    if (id) {
      setOrganizationId(null);
      return;
    }

    await logOut();
    if (partnerName === 'TurboTenant') {
      window.location.replace('https://rental.turbotenant.com/owners/payments/accounting');
      return;
    }
    if (partnerName === 'RentRedi') {
      window.location.replace('https://app.rentredi.com/accounting');
      return;
    }
    history.replace('/register');
  };

  render() {
    const { runTour, stepIndex } = this.state;
    const { classes } = this.props;
    const step = this.getStep(stepIndex);

    if (!runTour) {
      return null;
    }

    if (step.isDialog) {
      return (
        <Dialog open scroll="body" maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
          {step.content}
        </Dialog>
      );
    }

    return (
      <Box>
        <Backdrop className={classes.backdrop} open />
        <Paper className={classes.tooltip}>{step.content}</Paper>
        <div className={classes.arrow}>
          <svg width="32" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polygon points="0,0,16 16,32 0" fill="#fff" />
          </svg>
        </div>
      </Box>
    );
  }
}

DemoTour.contextType = PersonContext;

DemoTour.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(DemoTour);
