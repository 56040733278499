import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import QuickBookRowActions from '~/components/QuickBookRow/QuickBookRowActions';
import QuickBookRowAutocomplete from '~/components/QuickBookRow/QuickBookRowAutocomplete';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import { setInitialJournalScope } from '~/components/QuickBookRow/transactionTemplates/helpers/journalFunctions';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getDebitJournalLine, getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create, find, get } from '~/feathersFunctionalWrapper';
import { setJournalScope } from '~/functions/JournalFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

const AMOUNT_MISMATCH_ERROR = 'The total does not match the expected payment amount';

export default function QuickBookRowLoanPaymentTemplate({
  transaction,
  onTransactionAction,
  onTemplateLoad,
  loanTemplateMatch,
}) {
  const tracking = useSegmentTrack();
  const { organizationId, organizationName } = useContext(PersonContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [loanAccountOptions, setLoanAccountOptions] = useState([]);
  const [loanAccount, setLoanAccount] = useState(null);
  const [hasLoans, setHasLoans] = useState(true);
  const [journal, setJournal] = useState(null);
  const [debitLinesTotal, setDebitLinesTotal] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleLoanAccountChange = useCallback(
    async (value, journal) => {
      const account = await get('accounts', value.id);
      const journalObject = { ...journal };

      journalObject.debitLines.forEach((line) => {
        line.debit = 0;
      });
      journalObject.debitLines[0].accountIdSelect = value;

      journalObject.propertyId = account.propertyId;
      journalObject.entityId = account.entityId;
      journalObject.unitId = account.unitId;

      if (account.mortgageEscrowAccountId) {
        journalObject.debitLines.push(getDebitJournalLine());
        journalObject.debitLines[2].accountId = account.mortgageEscrowAccountId;
        if (account.mortgagePaymentAmount === journalObject.creditLines[0].credit) {
          journalObject.debitLines[2].debit = account.mortgageEscrowTransferAmount;
        }
      } else if (journalObject.debitLines.length === 3) {
        journalObject.debitLines.pop();
      }

      const loanInterestAccountData = await find('accounts', {
        query: {
          organizationId,
          default: account.type2 === 'Mortgage' ? 'mortgageInterestExpense' : 'otherInterestExpense',
        },
      });
      journalObject.debitLines[1].accountId = loanInterestAccountData.data[0].id;

      if (
        account.mortgagePaymentAmount === null ||
        account.lastCurrentBalance === null ||
        account.mortgageInterestRate === null
      ) {
        account.templateIncomplete = true;
      }
      if (account.mortgageEscrowAccountId && account.mortgageEscrowTransferAmount === null) {
        account.templateIncomplete = true;
      }

      if (account.mortgagePaymentAmount === journalObject.creditLines[0].credit) {
        if (!account.templateIncomplete) {
          let newTransactions;
          let startingBalance;
          let loanPI = account.mortgagePaymentAmount;
          if (account.mortgageEscrowAccountId) {
            loanPI -= account.mortgageEscrowTransferAmount;
          }
          if (journalObject.date > account.lastCurrentBalanceDate) {
            newTransactions = await create('reports', {
              organizationId,
              reportName: 'accountJournalTotals',
              startDate: moment(account.lastCurrentBalanceDate).add(1, 'days').format('YYYY-MM-DD'),
              endDate: journal.date,
              accountId: account.id,
            });
            startingBalance = parseFloat(account.lastCurrentBalance) + parseFloat(newTransactions[0].netCredits);
          } else {
            newTransactions = await create('reports', {
              organizationId,
              reportName: 'accountJournalTotals',
              endDate: account.lastCurrentBalanceDate,
              startDate: journalObject.date,
              accountId: account.id,
            });
            startingBalance =
              (account.lastCurrentBalance + loanPI - newTransactions[0].netCredits) /
              (1 + account.mortgageInterestRate / 12);
          }
          const interest =
            Math.round(startingBalance * parseFloat(account.mortgageInterestRate / 12).toPrecision(12) * 100) / 100;

          journalObject.debitLines[1].debit = interest;
          journalObject.debitLines[0].debit = loanPI - interest;
        }
      }

      await setInitialJournalScope(journalObject, organizationName);

      setLoanAccount(account);
      setJournal(journalObject);
    },
    [organizationId, organizationName],
  );

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const getData = async () => {
      const loanAccounts = await find('accounts', {
        query: {
          organizationId,
          type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
          inactive: { $or: [null, false] },
        },
      });

      if (loanAccounts.total === 0) {
        setHasLoans(false);
        setIsLoading(false);
        return;
      }

      setLoanAccountOptions(loanAccounts.data);

      const journalObject = getManualBasicJournal();
      const { id, account, amount, date } = transaction;
      journalObject.amount = amount;
      journalObject.organizationId = organizationId;
      journalObject.creditLines[0].accountIdSelect = account;
      journalObject.creditLines[0].credit = amount;
      journalObject.debitLines.push(getDebitJournalLine());
      journalObject.date = date;
      journalObject.description = 'Loan Payment';

      if (loanTemplateMatch.length === 1) {
        journalObject.debitLines[0].accountIdSelect = {
          id: loanTemplateMatch[0].id,
          name: loanTemplateMatch[0].name,
        };
        handleLoanAccountChange(loanTemplateMatch[0], journalObject);
      }

      if (account.type === 'Liability') {
        journalObject.debitLines[0].yodleeTransactionId = id;
        journalObject.debitLines[0].accountIdSelect = {
          id: transaction.accountId,
          name: account.name,
        };
      } else {
        const newCreditLine = { ...journalObject.creditLines[0] };
        newCreditLine.accountId = transaction.accountId;
        newCreditLine.accountIdSelect = {
          id: transaction.accountId,
          name: account.name,
        };
        newCreditLine.yodleeTransactionId = id;
        journalObject.creditLines[0] = newCreditLine;
      }

      await setInitialJournalScope(journalObject, organizationName);

      setJournal(journalObject);
      setIsLoading(false);
      onTemplateLoad();
    };
    getData();
  }, [
    handleLoanAccountChange,
    isLoading,
    loanTemplateMatch,
    onTemplateLoad,
    organizationId,
    organizationName,
    transaction,
  ]);

  useEffect(() => {
    if (!journal) {
      return;
    }
    let total = 0;
    journal.debitLines.forEach((line) => {
      total += line.debit;
    });
    if (total !== debitLinesTotal) {
      setDebitLinesTotal(total);
    }
    if (debitLinesTotal === journal.amount && error?.message === AMOUNT_MISMATCH_ERROR) {
      setError(null);
    }
  }, [journal, debitLinesTotal, error]);

  const addLoanPayment = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    if (debitLinesTotal !== journal.amount) {
      setError({ message: AMOUNT_MISMATCH_ERROR });
      return;
    }

    setIsSubmitting(true);

    const journalSubmit = { ...journal };
    setJournalScope(journalSubmit);
    journalSubmit.type = 'Loan Payment';
    journalSubmit.debitLines[0].accountId = journalSubmit.debitLines[0].accountIdSelect.id;
    journalSubmit.journalLines = journal.debitLines.concat(journal.creditLines);

    try {
      create('journals', journalSubmit);
    } catch (err) {
      setError(err);
    }

    setIsSubmitting(false);
    onTransactionAction();
  };

  if (isLoading) {
    return null;
  }

  if (!hasLoans) {
    return (
      <Box className={classes.rootContainer}>
        <Alert severity="info" className={classes.infoAlert}>
          You must add a loan account before booking payments.{' '}
          <Box component="span" className={classes.link} onClick={() => history.push('/accounts/loans?add=1')}>
            Click here to add a loan.
          </Box>
        </Alert>
      </Box>
    );
  }

  return (
    <form onSubmit={addLoanPayment}>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          <QuickBookRowAutocomplete
            options={loanAccountOptions}
            onChange={(value) => {
              handleLoanAccountChange(value, journal);
              setJournal({
                ...journal,
                debitLines: [{ ...journal.debitLines[0], accountIdSelect: value }, ...journal.debitLines.slice(1)],
              });
            }}
            label="Loan Account"
            value={loanAccount}
            required
          />
        </Box>
        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
        {loanAccount && loanAccount.templateIncomplete ? (
          <Alert severity="info" className={classes.infoAlert}>
            Make it easy to track loan payments!{' '}
            <Box
              component="span"
              className={classes.link}
              onClick={() => history.push(`/accounts/loans?edit=${loanAccount.id}`)}
            >
              Update your loan template
            </Box>{' '}
            to automatically split principal, interest, and escrow.
          </Alert>
        ) : null}
        {loanAccount &&
        !loanAccount.templateIncomplete &&
        journal.creditLines[0].credit !== loanAccount.mortgagePaymentAmount ? (
          <Alert severity="info" className={classes.infoAlert}>
            The payment amount does not match your template.{' '}
            <Box
              component="span"
              className={classes.link}
              onClick={() => history.push(`/accounts/loans?edit=${loanAccount.id}`)}
            >
              Click here to update
            </Box>
          </Alert>
        ) : null}
        {loanAccount ? (
          <Box className={classes.loanBox}>
            <Box className={classes.loanAmountsContainer}>
              <Box className={classes.loanAmountsRow}>
                <CurrencyField
                  label="Principal Amount"
                  required
                  variant="outlined"
                  size="small"
                  onChange={(event) => {
                    setJournal({
                      ...journal,
                      debitLines: [
                        { ...journal.debitLines[0], debit: Number(event.target.value) },
                        ...journal.debitLines.slice(1),
                      ],
                    });
                  }}
                  value={journal.debitLines[0].debit}
                  className={classes.loanAmountField}
                />
                <CurrencyField
                  label="Interest Amount"
                  required
                  variant="outlined"
                  size="small"
                  onChange={(event) => {
                    setJournal({
                      ...journal,
                      debitLines: [
                        journal.debitLines[0],
                        { ...journal.debitLines[1], debit: Number(event.target.value) },
                        ...journal.debitLines.slice(2),
                      ],
                    });
                  }}
                  value={journal.debitLines[1].debit}
                  className={classes.loanAmountField}
                />
                {loanAccount && loanAccount.mortgageEscrowAccountId && journal.debitLines[2] ? (
                  <CurrencyField
                    label="Escrow Amount"
                    required
                    variant="outlined"
                    size="small"
                    onChange={(event) => {
                      setJournal({
                        ...journal,
                        debitLines: [
                          journal.debitLines[0],
                          journal.debitLines[1],
                          { ...journal.debitLines[2], debit: Number(event.target.value) },
                        ],
                      });
                    }}
                    value={journal.debitLines[2].debit}
                    className={classes.loanAmountField}
                  />
                ) : null}
              </Box>
              <Box className={classes.loanAmountsTextContainer}>
                {debitLinesTotal && debitLinesTotal !== journal.amount ? (
                  <>
                    <Typography variant="body2" className={classes.expectedLoanAmountText}>
                      {`Expected $${Number(journal.amount).toFixed(2)}`}
                    </Typography>
                    <Typography variant="body2">{' | '}</Typography>
                  </>
                ) : null}
                <Typography variant="body2">{`Total $${Number(debitLinesTotal).toFixed(2)}`}</Typography>
              </Box>
            </Box>
          </Box>
        ) : null}
        <QuickBookRowNotesAndAttachment
          journal={journal}
          onChange={(newJournal) => {
            setJournal(newJournal);
          }}
        />
        {error && error.message ? (
          <Alert severity="error" className={classes.errorAlert}>
            {error.message}
          </Alert>
        ) : null}
        <Box className={classes.actionsContainer}>
          <QuickBookRowActions
            journal={journal}
            setJournal={setJournal}
            transaction={transaction}
            onTransactionAction={onTransactionAction}
          />
          <Box className={classes.rowActionsSecondaryContainer}>
            <Button
              variant="contained"
              className={classes.saveButton}
              size="small"
              type="submit"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

QuickBookRowLoanPaymentTemplate.defaultProps = {
  loanTemplateMatch: [],
};

QuickBookRowLoanPaymentTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func,
  onTemplateLoad: PropTypes.func,
  loanTemplateMatch: PropTypes.array,
};
