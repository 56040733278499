import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Chip, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Check } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { create, find } from '~/feathersFunctionalWrapper';
import { getDiscount, getPlans } from '~/functions/BuyNowFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import history from '~/history';

import RegisterForm from '../Register/RegisterForm';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardsContainer: {
    width: '100%',
    maxWidth: '900px',
    display: 'flex',
    justifyContent: 'center',
  },
  firstRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  cards: {
    width: '100%',
    display: 'flex',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      paddingTop: '24px',
    },
  },
  card: {
    width: '100%',
    height: 'fit-content',
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      maxWidth: '442px',
    },
  },
  titleContainer: {
    marginBottom: 16,
  },
  acknowledgementTextContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  signInLinkContainer: {
    width: '100%',
    marginTop: 16,
    color: theme.palette.primary.secondary,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 25,
    },
  },
  captionContainer: {
    marginTop: 3,
    marginLeft: '14px',
    color: theme.palette.text.secondary,
  },
  savingsCard: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginTop: 20,
  },
  savingsCardTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowTwoCaptionContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    marginTop: 15,
    gap: 10,
  },
  rowTwoCaptionContainerText: {
    display: 'flex',
    flexDirection: 'row',
  },
  billedMonthlyTextContainer: {
    display: 'flex',
    marginRight: 10,
  },
  fieldWrapper: {
    marginTop: '12px',
    width: '100%',
  },
  errorMessage: {
    marginTop: '20px',
    lineHeight: 1.2,
  },
  linkWrapper: {
    width: '100%',
  },
  boldText: {
    fontWeight: 'bold',
  },
  limitedTimeOfferChipContainer: {
    alignSelf: 'flex-start',
    marginTop: '-33px',
    position: 'absolute',
  },
  limitedTimeOfferChip: {
    'borderRadius': '4px',
    'backgroundColor': '#FFCD00',
    'fontWeight': 700,
    'fontSize': '12px',
    'marginBottom': '10px',
    'maxHeight': '24px',
    '& span': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
}));

export default function View({ isLimitedTimeOffer }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const discount = getDiscount(isLimitedTimeOffer);
  const plans = getPlans(discount);

  const [unitSelection, setUnitSelection] = useState(plans[0]);
  const [prefillData, setPrefillData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [isEmailAlreadyRegistered, setIsEmailAlreadyRegistered] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const tracking = useSegmentTrack();
  useEffect(() => {
    tracking('register_profile loaded', { location: 'Buy Now - Registration Form' });
  }, []);

  /**
   * Parses the query string to look for an existing registration or plan selection. If
   * registration is found, it will set the existing values state object and pass that to the
   * registration form. If a plan is found, it will set the unit selection state object.
   */
  useEffect(() => {
    const parseQueryString = async () => {
      const parsedQuery = queryString.parse(location.search);
      if (parsedQuery.registration) {
        const registrationResponse = await find('registrations', {
          query: {
            lookupHash: parsedQuery.registration,
          },
        });
        if (registrationResponse.total === 1) {
          const registration = registrationResponse.data[0];
          setPrefillData({
            firstName: registration.firstName,
            lastName: registration.lastName,
            email: registration.email,
            phone: registration.phone,
          });
          const plan = plans.find((option) => option.unitsIncluded === registration.unitsIncluded);
          if (plan) {
            setUnitSelection(plan);
          }
        }
      } else if (parsedQuery.unitsIncluded) {
        setUnitSelection(plans.find((option) => option.unitsIncluded === parsedQuery.unitsIncluded));
      }

      setLoading(false);
    };
    parseQueryString();
  }, []);

  const createRegistration = async (registerForm) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);

      const registrationResponse = await create('registrations', {
        firstName: registerForm.firstName,
        lastName: registerForm.lastName,
        phone: registerForm.phone,
        email: registerForm.email.trim().toLowerCase(),
        unitsIncluded: unitSelection.unitsIncluded,
        type: 'BUY_NOW',
      });
      tracking('continue clicked', { location: 'Buy Now - Registration Form' });
      history.push(
        isLimitedTimeOffer
          ? `/checkout-limited?registration=${registrationResponse.lookupHash}`
          : `/checkout?registration=${registrationResponse.lookupHash}`,
      );
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.cardsContainer}>
        <Box className={classes.cards}>
          <Box className={classes.firstRowContainer}>
            <Card className={classes.card}>
              <CardContent>
                <Box className={classes.titleContainer}>
                  <Typography className={classes.boldText} variant="h6" align="center">
                    Create your REI Hub Account
                  </Typography>
                  <Typography variant="body2" align="center" gutterBottom>
                    {`You'll get ${discount.discountPercent}% off your plan for ${discount.duration} months`}
                  </Typography>
                </Box>
                <RegisterForm
                  emailAlreadyExists={isEmailAlreadyRegistered}
                  error={error}
                  setEmailAlreadyExists={setIsEmailAlreadyRegistered}
                  buttonText="Continue"
                  setError={setError}
                  hasPasswordField={false}
                  alternateSubmitFunction={createRegistration}
                  prefillData={prefillData}
                />
              </CardContent>
            </Card>
            <Box className={classes.signInLinkContainer}>
              <Typography variant="body2" align="center">
                {'Already have an account? '}
                <RouterLink to="/sign-in">Sign In</RouterLink>
              </Typography>
            </Box>
          </Box>
          <Card className={classes.card}>
            <CardContent>
              <Typography className={classes.boldText} variant="h6" align="left" gutterBottom>
                Your Plan
              </Typography>
              <Autocomplete
                options={plans}
                getOptionLabel={(option) => option.units}
                value={unitSelection}
                onChange={(_event, value) => {
                  setUnitSelection(value);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Units Included"
                    fullWidth
                    required
                    variant="outlined"
                    onClick={() => {
                      tracking('units_included_dropdown clicked', { location: 'Buy Now - Registration Form' });
                    }}
                  />
                )}
              />
              <Box className={classes.captionContainer}>
                <Typography variant="caption">The number of units you'll add to your portfolio.</Typography>
              </Box>
              <Card className={classes.savingsCard}>
                {isLimitedTimeOffer ? (
                  <Box className={classes.limitedTimeOfferChipContainer}>
                    <Chip label="Discount Applied" className={classes.limitedTimeOfferChip} />
                  </Box>
                ) : null}
                <Box className={classes.savingsCardTextContainer}>
                  <Typography className={classes.boldText} variant="body1">
                    Plan
                  </Typography>
                  <Typography variant="body1">{`$${unitSelection.price.toFixed(2)}/mo`}</Typography>
                </Box>
                <Box className={classes.savingsCardTextContainer}>
                  <Typography className={classes.boldText} variant="body1">
                    {`${discount.discountPercent}% Discount (${discount.duration} months)`}
                  </Typography>
                  <Typography variant="body1">
                    {`-$${(unitSelection.price - unitSelection.discountPrice).toFixed(2)}`}
                  </Typography>
                </Box>
                <Box>
                  <Divider />
                </Box>
                <Box className={classes.savingsCardTextContainer}>
                  <Typography className={classes.boldText} variant="body1">
                    Your Total
                  </Typography>
                  <Typography color="primary" variant="body1" className={classes.boldText}>
                    {`$${unitSelection.discountPrice.toFixed(2)}/mo`}
                  </Typography>
                </Box>
                <Box className={classes.rowTwoCaptionContainer}>
                  <Box className={classes.rowTwoCaptionContainerText}>
                    <Box>
                      <Check fontSize="small" />
                    </Box>
                    <Box>
                      <Typography variant="body1">Billed monthly</Typography>
                    </Box>
                  </Box>
                  <Box className={classes.rowTwoCaptionContainerText}>
                    <Box>
                      <Check fontSize="small" />
                    </Box>
                    <Box>
                      <Typography variant="body1">Cancel anytime</Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

View.defaultProps = {
  isLimitedTimeOffer: false,
};

View.propTypes = {
  isLimitedTimeOffer: PropTypes.bool,
};
