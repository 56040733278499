import { useContext } from 'react';

import { PersonContext } from '../contexts/PersonContext';

/**
 * Sets up tracking events for Segment - For use in class components (sending required context
 * values via params)
 * @param {*} event - The event to be sent to Segment
 * @param {*} properties - An object containing the properties to be sent to Segment
 * @param {*} context - An object containing the context values required for tracking events
 */
export function segmentTracking(event, location, context) {
  if (!window.analytics) {
    return () => {};
  }

  // exclude info from all partners other than TurboTenant
  if (context.partnerName && context.partnerName !== 'TurboTenant') {
    return () => {};
  }

  // exclude admin activity
  if (context.adminLogin) {
    return () => {};
  }

  window.analytics.track(event, {
    groupId: context.organizationId,
    location,
    showWelcome: context.showWelcome,
    partnerName: context.partnerName,
  });
}

/**
 * Sets up tracking events hook for Segment
 * @param {*} event - The event to be sent to Segment
 * @param {*} properties - An object containing the properties to be sent to Segment
 * @param {*} additionalContext - An object containing additional context when tracking events are
 * kicking off when the user's context is not yet set (e.g. Register screen)
 */
export function useSegmentTrack() {
  const { organizationId, showWelcome, partnerName, adminLogin } = useContext(PersonContext);

  if (!window.analytics) {
    return () => {};
  }

  // exclude info from all partners other than TurboTenant
  if (partnerName && partnerName !== 'TurboTenant') {
    return () => {};
  }

  // exclude admin activity
  if (adminLogin) {
    return () => {};
  }

  let defaultProperties = {
    groupId: organizationId,
    showWelcome,
    partnerName,
  };

  return (event, properties, additionalContext) => {
    if (additionalContext) {
      defaultProperties = { ...defaultProperties, ...additionalContext };
    }
    window.analytics.track(event, { ...defaultProperties, ...properties });
  };
}
