import generateHash from 'random-hash';

export const bankDebitTransactionTypes = [
  { label: 'Expense', value: 'Expense' },
  { label: 'Transfer Between Accounts', value: 'Transfer' },
  { label: 'Loan Payment', value: 'Loan Payment' },
  { label: 'Fixed Asset Purchase', value: 'Fixed Asset Purchase' },
  { label: 'Credit Card Payment', value: 'Credit Card Payment' },
  { label: 'Owner Distribution', value: 'Distribution' },
  { label: 'Security Deposit Refund', value: 'securityDepositRefunded' },
  { label: 'Refund Issued', value: 'Refund Issued' },
];

export const bankCreditTransactionTypes = [
  { label: 'Revenue', value: 'Revenue' },
  { label: 'Net Income', value: 'Net Income' },
  { label: 'Owner Contribution', value: 'Contribution' },
  { label: 'Security Deposit', value: 'securityDepositReceived' },
  { label: 'Transfer Between Accounts', value: 'Transfer' },
  { label: 'Refund Received', value: 'Refund' },
];

export const loanCreditTransactionTypes = [
  { label: 'Loan Payment', value: 'Loan Payment' },
  { label: 'Escrow Expense', value: 'Escrow Expense' },
];

export const loanDebitTransactionTypes = [
  { label: 'Escrow Expense', value: 'Escrow Expense' },
  { label: 'Loan Payment', value: 'Loan Payment' },
];

export const cardCreditTransactionTypes = [
  { label: 'Credit Card Payment', value: 'Credit Card Payment' },
  { label: 'Refund Received', value: 'Refund' },
  { label: 'Revenue', value: 'Revenue' },
];

export const cardDebitTransactionTypes = [
  { label: 'Expense', value: 'Expense' },
  { label: 'Fixed Asset Purchase', value: 'Fixed Asset Purchase' },
  { label: 'Transfer Between Accounts', value: 'Transfer' },
];

export const manualTransactionTypes = [
  { label: 'Revenue', value: 'Revenue' },
  { label: 'Expense', value: 'Expense' },
  { label: 'Net Income', value: 'Net Income' },
  { label: 'Loan Payment', value: 'Loan Payment' }, // not editable
  { label: 'Owner Distribution', value: 'Distribution' },
  { label: 'Owner Contribution', value: 'Contribution' },
  { label: 'Fixed Asset Purchase', value: 'Fixed Asset Purchase' },
  { label: 'Transfer Between Accounts', value: 'Transfer' },
  { label: 'Security Deposit', value: 'securityDepositReceived' }, // not editable
  { label: 'Security Deposit Refund', value: 'securityDepositRefunded' }, // not editable
  { label: 'Security Deposit Retained', value: 'securityDepositRetained' }, // not editable
  { label: 'Credit Card Payment', value: 'Credit Card Payment' },
  { label: 'Refund Received', value: 'Refund' },
  { label: 'Refund Issued', value: 'Refund Issued' },
  { label: 'Depreciation', value: 'Depreciation' },
  { label: 'Escrow Expense', value: 'Escrow Expense' },
  { label: 'Manual Journal', value: 'Manual Journal' }, // not editable
];

export const getRefundJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Refund',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'credit',
      accountId: null,
      accountIdSelect: null,
      credit: yodleeTransaction.amount,
    },
    {
      type: 'debit',
      accountId: yodleeTransaction.accountId,
      debit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
  ],
});

export const getCreditJournalLine = () => ({
  key: generateHash(),
  type: 'credit',
  accountId: null,
  accountIdSelect: null,
  credit: null,
});

export const getDebitJournalLine = () => ({
  key: generateHash(),
  type: 'debit',
  accountId: null,
  accountIdSelect: null,
  debit: null,
});

export const getManualBasicJournal = () => ({
  // organizationId
  propertyId: null,
  propertyIdSelect: null,
  unitId: null,
  unitIdSelect: null,
  cash: true,
  accrual: true,
  amount: null,
  date: null,
  description: '',
  debitLines: [getDebitJournalLine()],
  creditLines: [getCreditJournalLine()],
  attachmentURL: '',
  attachmentURL_info: {},
  vendorId: null,
  vendorIdSelect: null,
  fixedAssetId: null,
  fixedAssetIdSelect: null,
  journalScopeSelect: null,
});

export const getEscrowExpenseJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Escrow Expense',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'debit',
      accountId: null,
      accountIdSelect: null,
      debit: yodleeTransaction.amount,
    },
    {
      type: 'credit',
      accountId: null,
      credit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
  ],
});

export const getDistributionJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Distribution',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'debit',
      accountId: null,
      debit: yodleeTransaction.amount,
    },
    {
      type: 'credit',
      accountId: yodleeTransaction.accountId,
      credit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
  ],
});

export const getContributionJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Contribution',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'debit',
      accountId: yodleeTransaction.accountId,
      debit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
    {
      type: 'credit',
      accountId: null,
      credit: yodleeTransaction.amount,
    },
  ],
});

export const getSecurityDepositReceivedJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Security Deposit',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'debit',
      accountId: yodleeTransaction.accountId,
      debit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
    {
      type: 'credit',
      accountId: null,
      credit: yodleeTransaction.amount,
    },
  ],
});

export const getSecurityDepositRefundedJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Security Deposit Out',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'debit',
      accountId: null,
      debit: yodleeTransaction.amount,
    },
    {
      type: 'credit',
      accountId: yodleeTransaction.accountId,
      credit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
  ],
});

export const getRefundIssuedJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Refund Issued',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'debit',
      accountId: null,
      debit: yodleeTransaction.amount,
    },
    {
      type: 'credit',
      accountId: yodleeTransaction.accountId,
      credit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
  ],
});

export const getCardPaymentCreditJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Credit Card Payment',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      // credit card balance reduction
      type: 'debit',
      accountId: yodleeTransaction.accountId,
      debit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
    {
      // bank payment
      type: 'credit',
      accountId: null,
      accountIdSelect: null,
      credit: yodleeTransaction.amount,
    },
  ],
});

export const getCardPaymentBankJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Credit Card Payment',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      // credit card balance reduction
      type: 'debit',
      accountId: null,
      accountIdSelect: null,
      debit: yodleeTransaction.amount,
    },
    {
      // bank payment
      type: 'credit',
      accountId: yodleeTransaction.accountId,
      credit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
  ],
});

export const getTransferDepositedJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Transfer',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'debit',
      accountId: yodleeTransaction.accountId,
      debit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
    {
      type: 'credit',
      accountId: null,
      credit: yodleeTransaction.amount,
    },
  ],
});

export const getTransferWithdrawnJournal = (yodleeTransaction) => ({
  organizationId: yodleeTransaction.organizationId,
  propertyId: yodleeTransaction.account.propertyId,
  type: 'Transfer',
  cash: true,
  accrual: true,
  amount: yodleeTransaction.amount,
  date: yodleeTransaction.date,
  description: yodleeTransaction.description,
  journalLines: [
    {
      type: 'debit',
      accountId: null,
      debit: yodleeTransaction.amount,
    },
    {
      type: 'credit',
      accountId: yodleeTransaction.accountId,
      credit: yodleeTransaction.amount,
      yodleeTransactionId: yodleeTransaction.id,
    },
  ],
});
