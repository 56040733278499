import React from 'react';
import { TableBody, TableCell, TableHead, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

import AccountantPrintHeader from '../components/AccountantPrintHeader';
import AddBookedTransactionDialog from '../components/AddBookedTransactionDialog';
import BookedTransactionsList from '../components/BookedTransactionsList';
import CardBase from '../components/CardBase';
import DownloadDialog from '../components/DownloadDialog';
import FilterDialog from '../components/FilterDialog';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import ViewBookedTransactionDialog from '../components/ViewBookedTransactionDialog';
import { PersonContext } from '../contexts/PersonContext';
import { find } from '../feathersWrapper';
import { appliedFilter, buildQuery, parseQuery } from '../functions/FilterFunctions';
import { asyncHandleChange } from '../functions/InputHandlers';
import { TablePaginationButtons } from '../functions/TableFunctions';

import FilterCard from './cards/FilterCard';

const styles = (theme) => ({
  attachmentButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
});

class BookedTransactions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterJournalsDialog: false,
      addBookedTransactionDialog: false,
      selectedJournal: null,
      downloadDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        propertyId: null,
        unitId: null,
        accountId: null,
        vendorId: null,
        transactionType: null,
        description: '',
        startDateSelect: null,
        endDateSelect: null,
        propertyIdSelect: { address1: 'All Properties', id: 'All' },
        unitIdSelect: null,
        accountIdSelect: null,
        vendorIdSelect: null,
        transactionTypeSelect: { name: 'All Transactions', id: 'All' },
        receiptStatusSelect: { name: 'All Transactions', id: 'All' },
        page: 0,
        pageSize: 50,
      },
      transactionListFields: [
        {
          header: 'Date',
          cell: {
            body: 'date',
            caption: null,
          },
          button: null,
        },
        {
          header: 'Type',
          cell: {
            body: 'type',
            caption: null,
          },
          button: null,
        },
        {
          header: 'Scope',
          cell: {
            body: 'scope',
            caption: null,
          },
          button: null,
        },
        {
          header: 'Description',
          cell: {
            body: 'description',
            caption: 'vendor',
          },
          button: null,
        },
        {
          header: 'Attachment',
          cell: null,
          button: {
            label: null,
            action: 'viewAttachment',
          },
        },
        {
          header: 'Amount',
          cell: {
            body: 'amount',
            caption: null,
          },
          button: null,
        },
        {
          header: 'Action',
          cell: null,
          button: {
            label: 'View',
            action: 'viewBookedTransaction',
          },
        },
      ],
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    await this.getJournals();

    this.setState({
      loading: false,
    });
  }

  getJournals = async () => {
    const { basis } = this.context;
    const query = Object.assign(buildQuery(this), {
      basis,
      $sort: {
        date: -1,
        amount: -1,
        id: -1,
      },
    });

    if (query.description) {
      query.description = { $iLike: `%${query.description}%` };
    }

    const journalsResponse = await find(this, 'journals', { query });
    const journals = journalsResponse.data;
    const totalJournals = journalsResponse.total;
    this.setState({
      journals,
      totalJournals,
    });
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateJournalsFilter(newFilter);
  };

  handleChangePage = async (_event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getJournals();
  };

  openAddTransactionDialog = () => {
    this.setState({
      addBookedTransactionDialog: true,
    });
  };

  closeTransactionDialogs = () => {
    this.setState({
      addBookedTransactionDialog: false,
    });
  };

  updateJournalsFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getJournals();
  };

  closeFilterJournalsDialog = () => {
    this.setState({
      filterJournalsDialog: false,
    });
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, exportOptions } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        exportOptions,
        reportName: 'Booked Transactions',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'booked-transactions.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  getTableHeaders = (field) => (
    <TableCell align={field.header === 'Amount' || field.header === 'Action' ? 'right' : 'left'}>
      <Typography variant="subtitle2">{field.header === 'Attachment' ? '' : field.header}</Typography>
    </TableCell>
  );

  actionButtons = () => [
    { text: 'Add Transaction', action: this.openAddTransactionDialog, class: 'add' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      loading,
      journals,
      totalJournals,
      filter,
      addBookedTransactionDialog,
      filterJournalsDialog,
      downloadDialog,
      selectedJournal,
      transactionListFields,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Booked Transactions"
          appliedFilter={appliedFilter(this.updateJournalsFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <AddBookedTransactionDialog
          isOpen={addBookedTransactionDialog}
          closeDialog={this.closeTransactionDialogs}
          onAddTransaction={this.getJournals}
        />
        {selectedJournal && (
          <ViewBookedTransactionDialog
            closeDialog={() => this.setState({ selectedJournal: null })}
            onTransactionModified={this.getJournals}
            journalId={selectedJournal.id}
          />
        )}
        <FilterDialog
          filter={filter}
          isOpen={filterJournalsDialog}
          closeDialog={this.closeFilterJournalsDialog}
          updateFilter={this.updateJournalsFilter}
          dateRange
          transactionType
          property
          unit
          amount
          account
          vendor
          description
          receiptStatus
        />
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <FilterCard
          openFilter={() => this.setState({ filterJournalsDialog: true })}
          updateFilter={this.updateJournalsFilter}
          filter={filter}
          fields={['transactionType', 'property']}
        />
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    {transactionListFields.map((field) => (
                      <TableCell
                        key={field.header}
                        align={field.header === 'Amount' || field.header === 'Action' ? 'right' : 'left'}
                      >
                        <Typography variant="subtitle2">{field.header === 'Attachment' ? '' : field.header}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {journals.map((journal) => (
                    <TableRow key={journal.id}>
                      <BookedTransactionsList
                        fields={transactionListFields}
                        journal={journal}
                        onClickViewButton={() => this.setState({ selectedJournal: journal })}
                      />
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter className="noprint">
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalJournals}
                      page={filter.page}
                      rowsPerPage={50}
                      onPageChange={this.handleChangePage}
                      rowsPerPageOptions={[50]}
                      ActionsComponent={TablePaginationButtons}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

BookedTransactions.contextType = PersonContext;

BookedTransactions.propTypes = {
  // classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(BookedTransactions);
