import React from 'react';
import { Helmet } from 'react-helmet';
import { Snackbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Alert } from '@material-ui/lab';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import ErrorBoundary from './contexts/ErrorBoundary';
import PersonProvider from './contexts/PersonProvider';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function App() {
  const [snackbarInfo, setSnackbarInfo] = React.useState({
    open: false,
    message: '',
    type: 'success',
    vertical: 'top',
    horizontal: 'right',
    autoHideDuration: 6000,
  });

  const { open, message, type, vertical, horizontal, autoHideDuration } = snackbarInfo;

  const handleSnackbarTrigger = (data) => {
    setSnackbarInfo({
      ...snackbarInfo,
      ...data,
      open: true,
    });
  };

  const handleCloseSnackBar = () => {
    setSnackbarInfo({ ...snackbarInfo, open: false });
  };

  return (
    <>
      <CssBaseline />
      <ErrorBoundary>
        <Helmet>
          <title>REI Hub</title>
          <meta name="description" content="Accounting Software for Rental Property Owners" />
        </Helmet>

        <Elements stripe={stripePromise}>
          <PersonProvider triggerSnackbar={handleSnackbarTrigger} />
        </Elements>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleCloseSnackBar}
          key={vertical + horizontal}
          autoHideDuration={autoHideDuration}
        >
          <Alert onClose={handleCloseSnackBar} severity={type}>
            {message}
          </Alert>
        </Snackbar>
      </ErrorBoundary>
    </>
  );
}

export default App;
