// import React, { useState } from 'react';
import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { CallSplit } from '@material-ui/icons';

import Button from '~/components/Button';
import ContentPasteOff from '~/icons/ContentPasteOff';
// import PropTypes from 'prop-types';

// import QuickBookRowIgnoreDialog from './QuickBookRowIgnoreDialog';
// import QuickBookRowSplitDialog from './QuickBookRowSplitDialog';

const useStyles = makeStyles((theme) => ({
  rowActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  rowActionsPrimaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingBottom: theme.spacing(2),
    },
    justifyContent: 'flex-start',
  },
  rowActionsPrimaryButton: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    fontSize: '24px',
  },
}));

export default function QuickBookRowActions(
  // NOTE: Commenting out split and ignore functionality for now. Will add back in different branch / PR for individual feature(s).
  // eslint-disable-next-line no-empty-pattern
  {
    // journal,
    // transaction,
    // onTransactionAction
  },
) {
  const classes = useStyles();
  // const [isIgnoreDialogOpen, setIsIgnoreDialogOpen] = useState(false);
  // const [isSplitDialogOpen, setIsSplitDialogOpen] = useState(false);

  return (
    <>
      {/* {isSplitDialogOpen ? (
        <QuickBookRowSplitDialog
          journal={journal}
          transaction={transaction}
          closeDialog={() => setIsSplitDialogOpen(false)}
          onSplit={onTransactionAction}
        />
      ) : null}
      {isIgnoreDialogOpen ? (
        <QuickBookRowIgnoreDialog
          journal={journal}
          transaction={transaction}
          closeDialog={() => setIsIgnoreDialogOpen(false)}
          onIgnore={onTransactionAction}
        />
      ) : null} */}
      <Box className={classes.rowActionsContainer}>
        <Box className={classes.rowActionsPrimaryContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.rowActionsPrimaryButton}
            onClick={() => {
              // setIsIgnoreDialogOpen(true);
            }}
          >
            <ContentPasteOff className={classes.buttonIcon} />
            Ignore
          </Button>
          <Button
            variant="outlined"
            className={classes.rowActionsPrimaryButton}
            onClick={() => {
              // setIsSplitDialogOpen(true);
            }}
          >
            <CallSplit className={classes.buttonIcon} />
            Split
          </Button>
        </Box>
      </Box>
    </>
  );
}

// QuickBookRowActions.propTypes = {
//   transaction: PropTypes.object.isRequired,
//   journal: PropTypes.object.isRequired,
//   onTransactionAction: PropTypes.func.isRequired,
// };
