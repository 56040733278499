import { generateHash } from 'random-hash';

import { getJournalScopeProperties } from '~/functions/JournalFunctions';

import { RULE_TYPES, TRANSACTION_TYPES } from './constants';

export function generateSplitLine(label) {
  return { amount: 0, key: generateHash(), label };
}

function getRuleType(selectedRuleType, selectedTransactionType) {
  if (selectedRuleType === RULE_TYPES.BOOK) {
    return selectedTransactionType.value;
  }
  return selectedRuleType;
}

export function getRuleBody({ isAmountEnabled, journal, organizationId, ruleData }) {
  const ruleBody = {
    analysisAccountId: ruleData.selectedAccount.id === 'none' ? null : ruleData.selectedAccount.id,
    autobookMatchingTransactions: true,
    descriptionContains: ruleData.descriptionContains,
    organizationId,
    transactionAmount: isAmountEnabled ? ruleData.transactionAmount : null,
    type: getRuleType(ruleData.selectedRuleType, ruleData.selectedTransactionType),
  };

  if ([RULE_TYPES.SPLIT, RULE_TYPES.IGNORE].includes(ruleBody.type)) {
    ruleBody.entityId = null;
    ruleBody.propertyId = null;
    ruleBody.unitId = null;
    ruleBody.scopeSelect = null;
  }

  if (ruleBody.type === RULE_TYPES.SPLIT) {
    ruleBody.splitAmounts = ruleData.splitLines.map((splitLine) => splitLine.amount);
  } else if (ruleBody.type !== RULE_TYPES.IGNORE) {
    ruleBody.destinationAccountId = ruleData.selectedDestinationAccount.id;
    ruleBody.vendorId = ruleData.selectedVendor?.id;

    if (ruleBody.type === TRANSACTION_TYPES.FIXED_ASSET) {
      ruleBody.fixedAssetId = ruleData.selectedFixedAsset.id;
    }

    const journalScopeProperties = getJournalScopeProperties(journal);
    Object.assign(ruleBody, journalScopeProperties);
  }

  return ruleBody;
}

export function getInitialRuleTransactionType(transactionJournal) {
  if (transactionJournal) {
    if (transactionJournal.type === 'Credit Card Payment' || transactionJournal.type === 'Transfer') {
      return transactionJournal.debitLines[0].yodleeTransactionId
        ? { label: 'Transfer From', value: 'Transfer From' }
        : { label: 'Transfer To', value: 'Transfer To' };
    }
    return { label: transactionJournal.type, value: transactionJournal.type };
  }
  return null;
}
