import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  alert: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.alert.info.backgroundColor,
    color: theme.palette.alert.info.color,
  },
}));

export default function Ignore() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Alert className={classes.alert} severity="info">
        All transactions that match will still exist in REI Hub but be ignored from any calculations in your books.
      </Alert>
    </div>
  );
}
