import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import QuickBookRowActions from '~/components/QuickBookRow/QuickBookRowActions';
import QuickBookRowAutocomplete from '~/components/QuickBookRow/QuickBookRowAutocomplete';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import { setUpJournalForSubmit } from '~/components/QuickBookRow/transactionTemplates/helpers/journalFunctions';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create, find } from '~/feathersFunctionalWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { getBalanceSheetAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

export default function QuickBookRowTransferTemplate({ transaction, onTransactionAction, onTemplateLoad }) {
  const isMobile = checkIsSmallScreen();
  const tracking = useSegmentTrack();
  const { organizationId, organizationName } = useContext(PersonContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [balanceSheetAccountOptions, setBalanceSheetAccountOptions] = useState([]);
  const [shouldBookMatchingTransaction, setShouldBookMatchingTransaction] = useState(false);
  const [matchingTransaction, setMatchingTransaction] = useState(null);
  const [journal, setJournal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [shouldAddRule, setShouldAddRule] = useState(false);

  const findInitialMatchingTransaction = async (journal) => {
    let transactionType;

    if (journal.debitLines[0].accountIdSelect) {
      transactionType = 'DEBIT';
    } else if (journal.creditLines[0].accountIdSelect) {
      transactionType = 'CREDIT';
    }

    const query = {
      organizationId: journal.organizationId,
      amount: journal.amount,
      yodleeType: transactionType,
      date: {
        $lte: moment(journal.date).add(3, 'days').format('YYYY-MM-DD'),
        $gte: moment(journal.date).subtract(3, 'days').format('YYYY-MM-DD'),
      },
      journalId: null,
      split: { $or: [false, null] },
      ignored: { $or: [false, null] },
      pending: false,
      deleted: false,
    };

    const result = await find('yodlee-transactions', { query });

    if (result.data.length === 1) {
      setShouldBookMatchingTransaction(true);
      setMatchingTransaction(result.data[0]);
      if (transactionType === 'CREDIT') {
        journal.debitLines[0].accountIdSelect = result.data[0].account;
      } else {
        journal.creditLines[0].accountIdSelect = result.data[0].account;
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    const getData = async () => {
      const balanceSheetAccounts = await getBalanceSheetAccountOptions(organizationId, {
        id: {
          $ne: transaction.account.id,
        },
      });

      setBalanceSheetAccountOptions(balanceSheetAccounts);

      const journalObject = getManualBasicJournal();
      const { account, id, description, date, amount, yodleeType } = transaction;

      if (yodleeType === 'CREDIT') {
        journalObject.debitLines[0].accountIdSelect = account;
        journalObject.debitLines[0].yodleeTransactionId = id;
      } else if (yodleeType === 'DEBIT') {
        journalObject.creditLines[0].accountIdSelect = account;
        journalObject.creditLines[0].yodleeTransactionId = id;
      }
      journalObject.organizationId = organizationId;
      journalObject.description = description;
      journalObject.date = date;
      journalObject.amount = amount;
      journalObject.journalScopeSelect = { name: `${organizationName} Portfolio`, id: 'Portfolio' };

      await findInitialMatchingTransaction(journalObject);
      setJournal(journalObject);
      setIsLoading(false);
      onTemplateLoad();
    };
    getData();
  }, [isLoading, journal, onTemplateLoad, organizationId, organizationName, transaction]);

  const addTransfer = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const journalData = {
      journal,
      organizationId,
      direction: transaction.yodleeType === 'CREDIT' ? 'cash in' : 'cash out',
      transactionId: transaction.id,
      type: 'Transfer',
      bookAsRefund: false,
    };
    const journalSubmit = setUpJournalForSubmit(journalData);

    // Find matching transaction if applicable
    if (matchingTransaction && shouldBookMatchingTransaction) {
      if (matchingTransaction.yodleeType === 'CREDIT') {
        journalSubmit.debitLines[0].yodleeTransactionId = matchingTransaction.id;
      } else if (matchingTransaction.yodleeType === 'DEBIT') {
        journalSubmit.creditLines[0].yodleeTransactionId = matchingTransaction.id;
      }
    }

    try {
      await create('journals', journalSubmit);
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    onTransactionAction(shouldAddRule, journalSubmit);
  };

  if (isLoading) {
    return null;
  }

  return (
    <form onSubmit={addTransfer}>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          {journal.debitLines[0].yodleeTransactionId ? (
            <QuickBookRowAutocomplete
              options={balanceSheetAccountOptions}
              onChange={(value) => {
                setJournal({ ...journal, creditLines: [{ ...journal.creditLines[0], accountIdSelect: value }] });
              }}
              label="Transfer From Account"
              value={journal.creditLines[0].accountIdSelect}
              required
            />
          ) : (
            <QuickBookRowAutocomplete
              options={balanceSheetAccountOptions}
              onChange={(value) => {
                setJournal({ ...journal, debitLines: [{ ...journal.debitLines[0], accountIdSelect: value }] });
              }}
              label="Transfer To Account"
              value={journal.debitLines[0].accountIdSelect}
              required
            />
          )}
          <TextField
            className={isMobile ? classes.mobileDescriptionField : classes.descriptionField}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={(event) => {
              setJournal({ ...journal, description: event.target.value });
            }}
          />
        </Box>
        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
        {matchingTransaction && (
          <Box>
            <Alert severity="info" className={classes.infoAlert}>
              <Box display="flex" flexDirection="column">
                We found a matching transaction. Leave the box checked to book it as well.
                <Box marginRight="auto" display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    color="primary"
                    checked={shouldBookMatchingTransaction}
                    onClick={() => {
                      setShouldBookMatchingTransaction(!shouldBookMatchingTransaction);
                    }}
                  />
                  {`${matchingTransaction.date} - ${matchingTransaction.description}`}
                </Box>
              </Box>
            </Alert>
          </Box>
        )}
        <QuickBookRowNotesAndAttachment
          journal={journal}
          onChange={(newJournal) => {
            setJournal(newJournal);
          }}
        />
        {error && error.message ? (
          <Alert severity="error" className={classes.errorAlert}>
            {error.message}
          </Alert>
        ) : null}
        <Box className={classes.actionsContainer}>
          <QuickBookRowActions
            journal={journal}
            setJournal={setJournal}
            transaction={transaction}
            onTransactionAction={onTransactionAction}
          />
          <Box className={classes.rowActionsSecondaryContainer}>
            <Box className={classes.checkboxContainer}>
              <Checkbox
                checked={shouldAddRule}
                color="primary"
                onClick={() => {
                  tracking('create_rule clicked', { location: 'Inline Booking - Import Feed' });
                  setShouldAddRule(!shouldAddRule);
                }}
              />
              <Typography variant="body1">Create rule after saving</Typography>
            </Box>
            <Button
              variant="contained"
              className={classes.saveButton}
              size="small"
              type="submit"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

QuickBookRowTransferTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func,
  onTemplateLoad: PropTypes.func,
};
