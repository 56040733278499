import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import QuickBookRowTransactionTypeSelect from '~/components/QuickBookRow/QuickBookRowTransactionTypeSelect';
import QuickBookRowCreditCardPaymentTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowCreditCardPaymentTemplate';
import QuickBookRowExpenseTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowExpenseTemplate';
import QuickBookRowFixedAssetPurchaseTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowFixedAssetPurchaseTemplate';
import QuickBookRowLoanPaymentTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowLoanPaymentTemplate';
import QuickBookRowNetIncomeTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowNetIncomeTemplate';
import QuickBookRowOwnerContributionTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowOwnerContributionTemplate';
import QuickBookRowOwnerDistributionTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowOwnerDistributionTemplate';
import QuickBookRowPartnerDepositTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowPartnerDepositTemplate';
import QuickBookRowRefundIssuedTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowRefundIssuedTemplate';
import QuickBookRowRefundReceivedTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowRefundReceivedTemplate';
import QuickBookRowRevenueTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowRevenueTemplate';
import QuickBookRowSecurityDepositReceiveTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowSecurityDepositReceiveTemplate';
import QuickBookRowSecurityDepositReturnTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowSecurityDepositReturnTemplate';
import QuickBookRowTransferTemplate from '~/components/QuickBookRow/transactionTemplates/QuickBookRowTransferTemplate';
import { PersonContext } from '~/contexts/PersonContext';
import { create, find, get, patch } from '~/feathersFunctionalWrapper';
import history from '~/history';

const useStyles = makeStyles((theme) => ({
  root: {
    'backgroundColor': theme.palette.grey[200],
    '&.MuiTableRow-root': {
      border: 0,
    },
  },
  hideRow: {
    display: 'none',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '85vw',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '85vw',
    },
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
  infoAlert: {
    border: `1px solid ${theme.palette.info._states.outlinedBorder}`,
  },
}));

export default function QuickBookRow({ transaction, removeQuickSelectLine, onAddTransaction, colCount }) {
  const classes = useStyles();
  const { organizationId, setContextState, airbnbAccountId, vrboAccountId } = useContext(PersonContext);
  const [isTemplateLoaded, setIsTemplateLoaded] = useState(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState(null);
  const [isAlreadyBooked, setIsAlreadyBooked] = useState(false);

  const onTemplateLoad = useCallback(() => {
    setIsTemplateLoaded(true);
  }, []);

  // NOTE: Matching is not wired up yet. Will remove lint disable once it is.
  // eslint-disable-next-line no-unused-vars
  const [hasMatchOptions, setHasMatchOptions] = useState(false);
  const [showAirbnbMessage, setShowAirbnbMessage] = useState(false);
  const [showVRBOMessage, setShowVRBOMessage] = useState(false);
  // NOTE: Matching is not wired up yet. Will remove lint disable once it is.
  const [matchState, setMatchState] = useState({
    journalMatch: null,
    ownerFundsMatch: null,
    loanTemplateMatch: null,
    importedTransactionRuleMatch: null,
    incomeTemplateMatch: null,
  });

  useEffect(() => {
    const getData = async () => {
      const yodleeTransaction = await get('yodlee-transactions', transaction.id);
      const ownerFundsResult = await find('accounts', { query: { organizationId, default: 'ownerFunds' } });
      if (yodleeTransaction.journalLineId) {
        setIsAlreadyBooked(true);
        return;
      }

      let loanTemplateMatch = [];
      let incomeTemplateMatch = [];
      let importedTransactionRuleMatch = [];

      const amount = {};
      if (yodleeTransaction.yodleeType === 'DEBIT') {
        amount.credit = yodleeTransaction.amount;
      } else {
        amount.debit = yodleeTransaction.amount;
      }

      // find potential matching transactions
      const journalLineMatchQuery = {
        organizationId,
        accountId: yodleeTransaction.accountId,
        yodleeTransactionId: null,
        ...amount,
        startDate: moment(yodleeTransaction.date).subtract(21, 'days').format('YYYY-MM-DD'),
        endDate: moment(yodleeTransaction.date).add(5, 'days').format('YYYY-MM-DD'),
      };
      const journalLineMatchPromise = find('journal-lines', { query: journalLineMatchQuery });

      // find potential owner fund transactions
      const ownerFundsMatchQuery = {
        organizationId,
        accountId: ownerFundsResult.data[0].id,
        yodleeTransactionId: null,
        ...amount,
        startDate: moment(yodleeTransaction.date).subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment(yodleeTransaction.date).add(7, 'days').format('YYYY-MM-DD'),
      };
      const ownerFundsMatchPromise = find('journal-lines', { query: ownerFundsMatchQuery });

      if (['Bank', 'Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'].includes(yodleeTransaction.account.type2)) {
        // find potential matching loan templates
        const loanTemplateMatchQuery = {
          organizationId,
          mortgagePaymentAmount: yodleeTransaction.amount,
          type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
        };

        if (yodleeTransaction.account.type2 === 'Bank') {
          loanTemplateMatchQuery.mortgagePaymentAccountId = yodleeTransaction.accountId;
        } else {
          loanTemplateMatchQuery.id = yodleeTransaction.accountId;
        }

        // search if not positive bank account flow
        if (!(yodleeTransaction.account.type2 === 'Bank' && yodleeTransaction.yodleeType === 'CREDIT')) {
          const loanTemplateSearch = await find('accounts', { query: loanTemplateMatchQuery });
          loanTemplateMatch = loanTemplateSearch.data;
        }
      }

      if (
        ['Bank', 'Credit Card'].includes(yodleeTransaction.account.type2) &&
        yodleeTransaction.yodleeType === 'DEBIT'
      ) {
        // find potential matching templates
        const importedTransactionRuleMatchCriteria = {
          reportName: 'importedTransactionRuleMatch',
          organizationId,
          description: yodleeTransaction.description,
          type: ['Expense', 'Fixed Asset Purchase', 'Transfer To', 'Split'],
          transactionAmount: yodleeTransaction.amount,
          analysisAccountId: yodleeTransaction.account.id,
        };

        // const ruleMatch = await find('reports', importedTransactionRuleMatchCriteria);
        importedTransactionRuleMatch = await create('reports', importedTransactionRuleMatchCriteria);
      }

      if (yodleeTransaction.account.type2 === 'Bank' && yodleeTransaction.yodleeType === 'CREDIT') {
        // check for Airbnb
        if (yodleeTransaction.description && yodleeTransaction.description.toLowerCase().includes('airbnb')) {
          incomeTemplateMatch.push({
            name: 'Airbnb',
            type: 'Net Income',
            matched: false,
          });
          const airbnbAccountResult = await find('accounts', {
            query: {
              organizationId,
              default: 'airbnb',
            },
          });
          setShowAirbnbMessage(airbnbAccountResult.data[0].inactive);
        }
        // check for VRBO
        if (yodleeTransaction.description && yodleeTransaction.description.toLowerCase().includes('vrbo')) {
          incomeTemplateMatch.push({
            name: 'VRBO',
            type: 'Net Income',
            matched: false,
          });
          const vrboAccountResult = await find('accounts', {
            query: {
              organizationId,
              default: 'vrbo',
            },
          });
          setShowVRBOMessage(vrboAccountResult.data[0].inactive);
        }
        // find potential matching templates
        const importedTransactionRuleMatchCriteria = {
          reportName: 'importedTransactionRuleMatch',
          organizationId,
          description: yodleeTransaction.description,
          type: ['Revenue', 'Transfer From', 'Split'],
          transactionAmount: yodleeTransaction.amount,
          analysisAccountId: yodleeTransaction.account.id,
        };

        // const ruleMatch = await create('reports', importedTransactionRuleMatchCriteria);
        importedTransactionRuleMatch = await create('reports', importedTransactionRuleMatchCriteria);
      }

      const journalSearch = await journalLineMatchPromise;
      const ownerFundsSearch = await ownerFundsMatchPromise;

      journalSearch.data.forEach((match) => {
        match.matched = false;
      });
      ownerFundsSearch.data.forEach((match) => {
        match.matched = false;
      });
      loanTemplateMatch.forEach((match) => {
        match.matched = false;
      });
      importedTransactionRuleMatch.forEach((match) => {
        match.matched = false;
      });
      incomeTemplateMatch.forEach((match) => {
        match.matched = false;
      });

      let hasMatch = false;
      if (journalSearch.data.length) {
        if (journalSearch.data.length === 1) {
          journalSearch.data[0].matched = true;
        }
        hasMatch = true;
      } else if (ownerFundsSearch.data.length) {
        if (ownerFundsSearch.data.length === 1) {
          ownerFundsSearch.data[0].matched = true;
        }
        hasMatch = true;
      } else if (loanTemplateMatch.length) {
        if (loanTemplateMatch.length === 1) {
          loanTemplateMatch[0].matched = true;
        }
        hasMatch = true;
      } else if (importedTransactionRuleMatch.length) {
        if (importedTransactionRuleMatch.length === 1) {
          importedTransactionRuleMatch[0].matched = true;
        }
        hasMatch = true;
      } else if (incomeTemplateMatch.length) {
        if (incomeTemplateMatch.length === 1) {
          incomeTemplateMatch[0].matched = true;
        }
        hasMatch = true;
      }
      setHasMatchOptions(hasMatch);
      setMatchState({
        journalMatch: journalSearch.data,
        ownerFundsMatch: ownerFundsSearch.data,
        loanTemplateMatch,
        importedTransactionRuleMatch,
        incomeTemplateMatch,
      });
    };
    getData();
  }, [transaction, organizationId]);

  const onTransactionAction = (shouldAddRule, journal) => {
    removeQuickSelectLine(transaction);
    onAddTransaction(shouldAddRule, journal);
  };

  const onTransactionTypeChange = (transactionTypeSelect) => {
    setSelectedTransactionType(transactionTypeSelect);
  };

  /**
   * @returns appropriate template based on the selected transaction type
   */
  const getTemplate = () => {
    const commonProps = {
      transaction,
      onTransactionAction,
      onTemplateLoad,
    };

    if (!selectedTransactionType) {
      return;
    }
    switch (selectedTransactionType.value) {
      case 'Expense':
        return <QuickBookRowExpenseTemplate {...commonProps} onTransactionTypeChange={onTransactionTypeChange} />;
      case 'Transfer':
        return <QuickBookRowTransferTemplate {...commonProps} />;
      case 'Revenue':
        return <QuickBookRowRevenueTemplate {...commonProps} onTransactionTypeChange={onTransactionTypeChange} />;
      case 'securityDepositReceived':
        return <QuickBookRowSecurityDepositReceiveTemplate {...commonProps} />;
      case 'securityDepositRefunded':
        return <QuickBookRowSecurityDepositReturnTemplate {...commonProps} />;
      case 'Refund Issued':
        return <QuickBookRowRefundIssuedTemplate {...commonProps} />;
      case 'Fixed Asset Purchase':
        return (
          <QuickBookRowFixedAssetPurchaseTemplate {...commonProps} onTransactionTypeChange={onTransactionTypeChange} />
        );
      case 'Distribution':
        return <QuickBookRowOwnerDistributionTemplate {...commonProps} />;
      case 'Credit Card Payment':
        return (
          <QuickBookRowCreditCardPaymentTemplate {...commonProps} onTransactionTypeChange={onTransactionTypeChange} />
        );
      case 'Loan Payment':
        return <QuickBookRowLoanPaymentTemplate {...commonProps} loanTemplateMatch={matchState.loanTemplateMatch} />;
      case 'Refund':
        return <QuickBookRowRefundReceivedTemplate {...commonProps} />;
      case 'Partner Deposit':
        return (
          <QuickBookRowPartnerDepositTemplate
            transaction={transaction}
            onTransactionAction={onTransactionAction}
            onTransactionTypeChange={onTransactionTypeChange}
            onTemplateLoad={() => onTemplateLoad()}
          />
        );
      case 'Net Income':
        return <QuickBookRowNetIncomeTemplate {...commonProps} />;
      case 'Contribution':
        return <QuickBookRowOwnerContributionTemplate {...commonProps} />;
      default:
        return null;
    }
  };

  const activateAirbnb = async () => {
    event.preventDefault();

    await patch('accounts', airbnbAccountId, {
      inactive: false,
    });
    setContextState({ airbnbAccountActive: true });
    const airbnbUrl = `/accounts/airbnb/${airbnbAccountId}`;
    history.push(airbnbUrl);
  };

  const activateVRBO = async (event) => {
    event.preventDefault();

    await patch('accounts', vrboAccountId, {
      inactive: false,
    });
    setContextState({ vrboAccountActive: true });
    const vrboUrl = `/accounts/vrbo/${vrboAccountId}`;
    history.push(vrboUrl);
  };

  if (isAlreadyBooked) {
    return (
      <Dialog open maxWidth="sm" fullWidth disableBackdropClick disableEnforceFocus>
        <DialogTitle id="alert-dialog-title">Transaction Booked</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">This transaction has already been booked</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onTransactionAction} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      {!isTemplateLoaded && (
        <TableRow className={classes.root}>
          <TableCell colSpan={colCount}>
            <Box className={classes.loadingContainer}>
              <CircularProgress />
            </Box>
          </TableCell>
        </TableRow>
      )}
      <TableRow className={isTemplateLoaded ? classes.root : classes.hideRow}>
        <TableCell colSpan={colCount}>
          <Box className={classes.mainContainer}>
            <QuickBookRowTransactionTypeSelect
              transaction={transaction}
              selectedTransactionType={selectedTransactionType}
              onTransactionTypeChange={onTransactionTypeChange}
            />
            {showAirbnbMessage && (
              <Alert severity="info" className={classes.infoAlert}>
                {`You can upload your Airbnb transactions to REI Hub. `}
                <Box
                  component="span"
                  className={classes.link}
                  onClick={() => {
                    activateAirbnb();
                  }}
                >
                  Get Started
                </Box>
              </Alert>
            )}
            {showVRBOMessage && (
              <Alert severity="info" className={classes.infoAlert}>
                {`You can upload your VRBO transactions to REI Hub. `}
                <Box
                  component="span"
                  className={classes.link}
                  onClick={() => {
                    activateVRBO();
                  }}
                >
                  Get Started
                </Box>
              </Alert>
            )}
            {getTemplate()}
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

QuickBookRow.propTypes = {
  transaction: PropTypes.object.isRequired,
  removeQuickSelectLine: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  colCount: PropTypes.number.isRequired,
};
