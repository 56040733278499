import React from 'react';
import NumberFormat from 'react-number-format';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ControlPointOutlined from '@material-ui/icons/ControlPointOutlined';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import { sumProperty } from '~/functions/SumFunctions';

import { generateSplitLine } from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '0',
    padding: '0',
    listStyle: 'none',
  },
  item: {
    'display': 'flex',
    'alignItems': 'center',
    'gap': theme.spacing(2),
    'justifyContent': 'space-between',
    'borderBottom': `1px solid ${theme.palette.divider}`,
    'paddingBlock': theme.spacing(2),

    '&:last-of-type': {
      borderBottom: 'none',
      paddingBlock: theme.spacing(1),
    },
  },
  label: {
    flex: 1,
  },
  amountField: {
    width: '180px',
  },
}));

export default function Split({ splitLines, setSplitLines }) {
  const classes = useStyles();

  function addSplitLine() {
    setSplitLines([...splitLines, generateSplitLine(`Transaction ${splitLines.length + 1}`)]);
  }

  function updateSplitLine(key, amount) {
    setSplitLines(splitLines.map((line) => (line.key === key ? { ...line, amount } : line)));
  }

  return (
    <ul className={classes.container}>
      {splitLines.map((splitLine) => (
        <li className={classes.item} key={splitLine.key}>
          <Typography variant="body2">{splitLine.label}</Typography>
          <CurrencyField
            align="right"
            className={classes.amountField}
            label="Amount"
            size="small"
            required
            value={splitLine.amount}
            variant="outlined"
            onChange={(event) => updateSplitLine(splitLine.key, Number(event.target.value || 0))}
          />
        </li>
      ))}
      <li className={classes.item}>
        <Button color="primary" startIcon={<ControlPointOutlined />} variant="text" onClick={addSplitLine}>
          Add Line
        </Button>
        <NumberFormat
          displayType="text"
          value={sumProperty(splitLines, 'amount')}
          thousandSeparator
          prefix="Total Assigned: $"
          decimalScale={2}
          fixedDecimalScale
        />
      </li>
    </ul>
  );
}

Split.propTypes = {
  splitLines: PropTypes.array.isRequired,
  setSplitLines: PropTypes.func.isRequired,
};
