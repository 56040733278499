import React, { useContext, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import TransactionTypeSelect from '~/components/TransactionTypeSelect';
import { PersonContext } from '~/contexts/PersonContext';

import {
  bankCreditTransactionTypes,
  bankDebitTransactionTypes,
  cardCreditTransactionTypes,
  cardDebitTransactionTypes,
  loanCreditTransactionTypes,
  loanDebitTransactionTypes,
} from '../SearchSelect/TransactionTypeOptions';

const useStyles = makeStyles((theme) => ({
  transactionTypeSelect: {
    marginBottom: theme.spacing(1),
  },
}));

export default function QuickBookRowTransactionTypeSelect({
  transaction,
  selectedTransactionType,
  onTransactionTypeChange,
}) {
  const { partnerName } = useContext(PersonContext);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [loading, setLoading] = useState(true);

  const changeLabel = (types, label, newLabel) => {
    const target = types.find((transactionType) => transactionType.label === label);
    if (target) {
      target.label = newLabel;
    }
  };

  useEffect(() => {
    const getTransactionTypes = (yodleeTransaction) => {
      if (yodleeTransaction.account.type === 'Asset') {
        if (yodleeTransaction.yodleeType === 'DEBIT') {
          const types = bankDebitTransactionTypes;
          changeLabel(types, 'Owner Distribution', 'Distribution');
          changeLabel(types, 'Refund Issued', 'Refund');
          changeLabel(types, 'Security Deposit Refund', 'Security Deposit Return');
          changeLabel(types, 'Transfer Between Accounts', 'Transfer');
          return types;
        }
        const types = bankCreditTransactionTypes;
        changeLabel(types, 'Transfer Between Accounts', 'Transfer');
        if (partnerName) {
          return [{ label: `${partnerName} Deposit`, value: 'Partner Deposit' }, ...bankCreditTransactionTypes];
        }
        return types;
      }
      if (
        yodleeTransaction.account.type === 'Liability' &&
        ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'].includes(yodleeTransaction.account.type2)
      ) {
        if (yodleeTransaction.yodleeType === 'CREDIT') {
          return loanCreditTransactionTypes;
        }
        return loanDebitTransactionTypes;
      }
      if (yodleeTransaction.account.type === 'Liability' && yodleeTransaction.account.type2 === 'Credit Card') {
        if (yodleeTransaction.yodleeType === 'CREDIT') {
          return cardCreditTransactionTypes;
        }
        const types = cardDebitTransactionTypes;
        changeLabel(types, 'Transfer Between Accounts', 'Transfer');
        return cardDebitTransactionTypes;
      }
      return [];
    };

    const types = getTransactionTypes(transaction);
    const expense = types.find((transactionType) => transactionType.label === 'Expense');
    const revenue = types.find((transactionType) => transactionType.label === 'Revenue');

    if (expense && !selectedTransactionType) {
      onTransactionTypeChange(expense);
    } else if (revenue && !selectedTransactionType) {
      onTransactionTypeChange(revenue);
    }
    setTransactionTypes(types);
    setLoading(false);
  }, [transaction, onTransactionTypeChange, selectedTransactionType, partnerName]);

  const classes = useStyles();

  if (loading) {
    return null;
  }

  return (
    <TransactionTypeSelect
      className={classes.transactionTypeSelect}
      selectedTransactionType={selectedTransactionType}
      transactionTypes={transactionTypes}
      onTransactionTypeChange={onTransactionTypeChange}
    />
  );
}

QuickBookRowTransactionTypeSelect.propTypes = {
  transaction: PropTypes.object.isRequired,
  selectedTransactionType: PropTypes.object,
  onTransactionTypeChange: PropTypes.func.isRequired,
};
