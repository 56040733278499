import React from 'react';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';

import AccountantPrintHeader from '../components/AccountantPrintHeader';
import CardBase from '../components/CardBase';
import DownloadDialog from '../components/DownloadDialog';
import FilterDialog from '../components/FilterDialog';
import FinancialAccountLine2 from '../components/FinancialAccountLine2';
import LinkBase from '../components/LinkBase';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import { PersonContext } from '../contexts/PersonContext';
import { create, find, get } from '../feathersWrapper';
import { appliedFilter, buildQuery, parseQuery } from '../functions/FilterFunctions';
import { asyncHandleChange } from '../functions/InputHandlers';
import history from '../history';

class BalanceSheetByProperty extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      downloadDialog: false,
      filter: {
        endDate: null,
        entityId: null,
        endDateSelect: null,
        entityIdSelect: null,
      },
      additionalCriteria: [],
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    const result = await this.updateReport();

    if (!result.success) {
      const { filter } = this.state;
      const newFilter = { ...filter };
      newFilter.endDateSelect = moment();

      this.setState({ filter: newFilter, filterDialog: true });
    }
  }

  updateReport = async () => {
    const { basis, organizationId } = this.context;
    const { filter } = this.state;

    if (!filter.endDate) {
      return { success: false, message: 'Please complete all required fields' };
    }

    const propertiesQuery = {
      organizationId,
      $limit: 200,
      $sort: {
        address1: 1,
        id: 1,
      },
    };

    if (filter.entityId) {
      propertiesQuery.entityId = filter.entityId;
    }

    const properties = await find(this, 'properties', { query: propertiesQuery });

    const headers = properties.data.map((property) => property.address1);
    headers.unshift('Total');
    headers.push('None Assigned');

    const accountsReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
    });

    const additionalCriteriaObject = [{}];

    const accountsObject = {
      assets: {
        accounts: [],
        bankAccounts: {
          accounts: [],
        },
        propertyManagement: {
          accounts: [],
        },
        escrowAccounts: {
          accounts: [],
        },
        fixedAssets: {
          accounts: [],
          fixedAssetAccounts: {
            accounts: [],
          },
          depreciationAccounts: {
            accounts: [],
          },
        },
        otherAssets: {
          accounts: [],
        },
      },
      liabilities: {
        accounts: [],
        creditCards: {
          accounts: [],
        },
        loans: {
          accounts: [],
        },
        otherLiabilities: {
          accounts: [],
        },
      },
      income: {
        accounts: [],
      },
      equity: {
        accounts: [],
      },
      incomeAndEquity: {
        accounts: [],
      },
      liabilitiesIncomeAndEquity: {
        accounts: [],
      },
      byId: {},
    };

    const accountsPromises = [
      create(this, 'reports', accountsReportFilter).then((result) => {
        result.forEach((account) => {
          account.display = account.netCredits !== 0;
          account.subaccounts = [];
          account.values = [];
          if (account.type === 'Asset') {
            if (account.type2 === 'Bank') {
              accountsObject.assets.bankAccounts.accounts.push(account);
            } else if (account.type2 === 'Property Management') {
              accountsObject.assets.propertyManagement.accounts.push(account);
            } else if (account.type2 === 'Escrow') {
              accountsObject.assets.escrowAccounts.accounts.push(account);
            } else if (account.type2 === 'Fixed Asset') {
              accountsObject.assets.fixedAssets.fixedAssetAccounts.accounts.push(account);
              accountsObject.assets.fixedAssets.accounts.push(account);
            } else if (account.type2 === 'Accumulated Depreciation') {
              accountsObject.assets.fixedAssets.depreciationAccounts.accounts.push(account);
              accountsObject.assets.fixedAssets.accounts.push(account);
            } else {
              accountsObject.assets.otherAssets.accounts.push(account);
            }
            accountsObject.assets.accounts.push(account);
          } else if (account.type === 'Liability') {
            if (account.type2 === 'Credit Card') {
              accountsObject.liabilities.creditCards.accounts.push(account);
            } else if (account.type2 === 'Mortgage') {
              accountsObject.liabilities.loans.accounts.push(account);
            } else if (['HELOC', 'Hard Money Loan', 'Loan'].includes(account.type2)) {
              accountsObject.liabilities.loans.accounts.push(account);
            } else {
              accountsObject.liabilities.otherLiabilities.accounts.push(account);
            }
            accountsObject.liabilities.accounts.push(account);
            accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
          } else if (['Revenue', 'Expense'].includes(account.type)) {
            accountsObject.income.accounts.push(account);
            accountsObject.incomeAndEquity.accounts.push(account);
            accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
          } else {
            accountsObject.equity.accounts.push(account);
            accountsObject.incomeAndEquity.accounts.push(account);
            accountsObject.liabilitiesIncomeAndEquity.accounts.push(account);
          }

          accountsObject.byId[account.id] = account;
        });
        return result;
      }),
    ];

    properties.data.forEach((property) => {
      accountsPromises.push(
        create(this, 'reports', {
          ...accountsReportFilter,
          propertyId: property.id,
        }),
      );

      // get specific info for each call in forEach loop and push it into additional criteria
      // object array
      const criteria = {
        propertyId: property.id,
      };
      additionalCriteriaObject.push(criteria);
    });

    accountsPromises.push(
      create(this, 'reports', {
        ...accountsReportFilter,
        propertyId: null,
      }),
    );

    // Pushes final object into additional criteria object
    // for final column in report (none assigned)
    additionalCriteriaObject.push({
      propertyId: 'None',
    });

    const accountsResults = await Promise.all(accountsPromises);

    accountsResults[0].forEach((account) => {
      if (account.parentAccountId) {
        accountsObject.byId[account.parentAccountId].subaccounts.push(account);
      }
    });

    for (let i = 0; i < accountsResults.length; i += 1) {
      accountsResults[i].forEach((account, index) => {
        if (account.type === 'Asset') {
          accountsResults[0][index].values.push(-account.netCredits);
        } else {
          accountsResults[0][index].values.push(account.netCredits);
        }
        if (account.netCredits !== 0) {
          accountsResults[0][index].display = true;
          if (account.parentAccountId) {
            accountsObject.byId[account.parentAccountId].display = true;
          }
        }
      });
    }

    const incomeAccountsObject = {
      netIncome: {
        accounts: [],
      },
      retainedEarnings: {
        accounts: [],
      },
    };

    const netIncomeReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });

    netIncomeReportFilter.date.$gte = moment(filter.endDate).startOf('year').format('YYYY-MM-DD');
    netIncomeReportFilter.date.$lte = moment(filter.endDate).format('YYYY-MM-DD');

    const netIncomeArrayPromises = [
      create(this, 'reports', netIncomeReportFilter).then((result) => {
        result.forEach((account) => {
          account.values = [];
          incomeAccountsObject.netIncome.accounts.push(account);
        });
        return result;
      }),
    ];

    const retainedEarningsReportFilter = Object.assign(buildQuery(this), {
      basis,
      reportName: 'accountJournalTotals',
      type: ['Revenue', 'Expense'],
    });
    retainedEarningsReportFilter.date.$lte = moment(filter.endDate)
      .startOf('year')
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
    delete retainedEarningsReportFilter.date.$gte;
    const retainedEarningsArrayPromises = [
      create(this, 'reports', retainedEarningsReportFilter).then((result) => {
        result.forEach((account) => {
          account.values = [];
          incomeAccountsObject.retainedEarnings.accounts.push(account);
        });
        return result;
      }),
    ];

    properties.data.forEach((property) => {
      netIncomeArrayPromises.push(
        create(this, 'reports', {
          ...netIncomeReportFilter,
          propertyId: property.id,
        }),
      );
      retainedEarningsArrayPromises.push(
        create(this, 'reports', {
          ...retainedEarningsReportFilter,
          propertyId: property.id,
        }),
      );
    });

    netIncomeArrayPromises.push(
      create(this, 'reports', {
        ...netIncomeReportFilter,
        propertyId: null,
      }),
    );

    retainedEarningsArrayPromises.push(
      create(this, 'reports', {
        ...retainedEarningsReportFilter,
        propertyId: null,
      }),
    );

    const netIncomeArrays = await Promise.all(netIncomeArrayPromises);

    for (let i = 0; i < netIncomeArrays.length; i += 1) {
      netIncomeArrays[i].forEach((account, index) => {
        incomeAccountsObject.netIncome.accounts[index].values.push(account.netCredits);
      });
    }

    const retainedEarningsArrays = await Promise.all(retainedEarningsArrayPromises);

    for (let i = 0; i < retainedEarningsArrays.length; i += 1) {
      retainedEarningsArrays[i].forEach((account, index) => {
        incomeAccountsObject.retainedEarnings.accounts[index].values.push(account.netCredits);
      });
    }

    const organization = await get(this, 'organizations', organizationId);

    let displayRetainedEarnings = true;
    if (organization.retainedEarningsAccountId) {
      const retainedEarningsAccount = accountsObject.byId[organization.retainedEarningsAccountId];
      incomeAccountsObject.retainedEarnings.accounts.forEach((account) => {
        const incomeAccount = accountsObject.byId[account.id];
        for (let i = 0; i < account.values.length; i += 1) {
          retainedEarningsAccount.values[i] += account.values[i];
          incomeAccount.values[i] -= account.values[i];
        }
      });
      retainedEarningsAccount.name = `${retainedEarningsAccount.name}*`;
      retainedEarningsAccount.display = true;
      displayRetainedEarnings = false;
    }

    this.setState({
      loading: false,
      accountsObject,
      headers,
      additionalCriteria: additionalCriteriaObject,
      displayRetainedEarnings,
      incomeAccountsObject,
    });
    return { success: true };
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    const result = await this.updateReport();
    return result;
  };

  closeFilter = () => {
    const { loading } = this.state;
    if (loading) {
      this.goToReports();
    } else {
      this.setState({ filterDialog: false });
    }
  };

  goToReports = () => {
    history.replace('/reports');
  };

  sumColumnValues = (accounts) => {
    const { headers } = this.state;
    const sumArray = [];
    headers.forEach(() => {
      sumArray.push(0);
    });
    accounts.forEach((account) => {
      account.values.forEach((value, index) => {
        sumArray[index] = (parseFloat(sumArray[index]) + parseFloat(value)).toFixed(2);
      });
    });
    return sumArray;
  };

  showSection = (accounts) => {
    let showSection = false;
    accounts.forEach((account) => {
      if (account.display) {
        showSection = true;
      }
    });
    return showSection;
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, accountsObject, incomeAccountsObject, displayRetainedEarnings, headers, exportOptions } =
      this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        accountsObject,
        incomeAccountsObject,
        displayRetainedEarnings,
        headers,
        exportOptions,
        reportName: 'Balance Sheet By Property',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(
              () => {
                throw err;
              },
              () => resolve(),
            );
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'balance-sheet-by-property.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  };

  setExportOptions = async (options) => {
    await asyncHandleChange('exportOptions', options, this);
  };

  actionButtons = () => [
    { text: 'Filter', action: () => this.setState({ filterDialog: true }), class: 'filter' },
    { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
  ];

  render() {
    const { match } = this.props;
    const { accountingFirmId } = this.context;
    const {
      accountsObject,
      headers,
      loading,
      filter,
      filterDialog,
      downloadDialog,
      additionalCriteria,
      displayRetainedEarnings,
      incomeAccountsObject,
    } = this.state;

    return (
      <PageGrid>
        {accountingFirmId && <AccountantPrintHeader />}
        <PageHeader
          match={match}
          title="Balance Sheet By Property"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={this.closeFilter}
          updateFilter={this.updateFilter}
          date
          scope={['Full Portfolio', 'Sub-Portfolio']}
          required={['date', 'scope']}
        />
        <DownloadDialog
          isOpen={downloadDialog}
          exportXlsx={this.exportXlsx}
          closeDialog={() =>
            this.setState({
              downloadDialog: false,
            })
          }
          setExportOptions={this.setExportOptions}
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              {!loading && (
                <>
                  <Box mx="auto" mt="50px" width="fit-content" paddingX={2}>
                    <FinancialAccountLine2 values={headers} bold />
                    <FinancialAccountLine2 label="Assets" overline bold />
                    {this.showSection(accountsObject.assets.bankAccounts.accounts) && (
                      <>
                        <FinancialAccountLine2 label="Bank Accounts" bold indent={1} />
                        {accountsObject.assets.bankAccounts.accounts.map((account) => (
                          <FinancialAccountLine2
                            key={account.id}
                            accountId={account.id}
                            label={account.name}
                            values={account.values}
                            display={account.display && !account.parentAccountId}
                            subaccounts={account.subaccounts}
                            indent={2}
                            filter={filter}
                            additionalCriteria={additionalCriteria}
                          />
                        ))}
                        <FinancialAccountLine2
                          label="Total Bank Accounts"
                          values={this.sumColumnValues(accountsObject.assets.bankAccounts.accounts)}
                          sumline
                          bold
                          indent={1}
                        />
                      </>
                    )}
                    {this.showSection(accountsObject.assets.propertyManagement.accounts) && (
                      <>
                        <FinancialAccountLine2 label="Property Management" bold indent={1} />
                        {accountsObject.assets.propertyManagement.accounts.map((account) => (
                          <FinancialAccountLine2
                            key={account.id}
                            accountId={account.id}
                            label={account.name}
                            values={account.values}
                            display={account.display && !account.parentAccountId}
                            subaccounts={account.subaccounts}
                            indent={2}
                            filter={filter}
                            additionalCriteria={additionalCriteria}
                          />
                        ))}
                        <FinancialAccountLine2
                          label="Total Property Management"
                          values={this.sumColumnValues(accountsObject.assets.propertyManagement.accounts)}
                          sumline
                          bold
                          indent={1}
                        />
                      </>
                    )}
                    {this.showSection(accountsObject.assets.escrowAccounts.accounts) && (
                      <>
                        <FinancialAccountLine2 label="Escrow Accounts" bold indent={1} />
                        {accountsObject.assets.escrowAccounts.accounts.map((account) => (
                          <FinancialAccountLine2
                            key={account.id}
                            accountId={account.id}
                            label={account.name}
                            values={account.values}
                            display={account.display && !account.parentAccountId}
                            subaccounts={account.subaccounts}
                            indent={2}
                            filter={filter}
                            additionalCriteria={additionalCriteria}
                          />
                        ))}
                        <FinancialAccountLine2
                          label="Total Escrow Accounts"
                          values={this.sumColumnValues(accountsObject.assets.escrowAccounts.accounts)}
                          sumline
                          bold
                          indent={1}
                        />
                      </>
                    )}
                    {this.showSection(accountsObject.assets.fixedAssets.accounts) && (
                      <>
                        <FinancialAccountLine2 label="Fixed Assets" bold indent={1} />
                        {accountsObject.assets.fixedAssets.fixedAssetAccounts.accounts.map((account) => (
                          <FinancialAccountLine2
                            key={account.id}
                            accountId={account.id}
                            label={account.name}
                            values={account.values}
                            display={account.display && !account.parentAccountId}
                            subaccounts={account.subaccounts}
                            indent={2}
                            filter={filter}
                            additionalCriteria={additionalCriteria}
                          />
                        ))}
                        {accountsObject.assets.fixedAssets.depreciationAccounts.accounts.map((account) => (
                          <FinancialAccountLine2
                            key={account.id}
                            accountId={account.id}
                            label={account.name}
                            values={account.values}
                            display={account.display && !account.parentAccountId}
                            subaccounts={account.subaccounts}
                            indent={2}
                            filter={filter}
                            additionalCriteria={additionalCriteria}
                          />
                        ))}
                        <FinancialAccountLine2
                          label="Total Fixed Assets"
                          values={this.sumColumnValues(accountsObject.assets.fixedAssets.accounts)}
                          sumline
                          bold
                          indent={1}
                        />
                      </>
                    )}
                    {accountsObject.assets.otherAssets.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Assets"
                      values={this.sumColumnValues(accountsObject.assets.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2 label="Liabilities" overline bold />
                    {this.showSection(accountsObject.liabilities.creditCards.accounts) && (
                      <>
                        <FinancialAccountLine2 label="Credit Cards" bold indent={1} />
                        {accountsObject.liabilities.creditCards.accounts.map((account) => (
                          <FinancialAccountLine2
                            key={account.id}
                            accountId={account.id}
                            label={account.name}
                            values={account.values}
                            display={account.display && !account.parentAccountId}
                            subaccounts={account.subaccounts}
                            indent={2}
                            filter={filter}
                            additionalCriteria={additionalCriteria}
                          />
                        ))}
                        <FinancialAccountLine2
                          label="Total Credit Cards"
                          values={this.sumColumnValues(accountsObject.liabilities.creditCards.accounts)}
                          sumline
                          bold
                          indent={1}
                        />
                      </>
                    )}
                    {this.showSection(accountsObject.liabilities.loans.accounts) && (
                      <>
                        <FinancialAccountLine2 label="Loans" bold indent={1} />
                        {accountsObject.liabilities.loans.accounts.map((account) => (
                          <FinancialAccountLine2
                            key={account.id}
                            accountId={account.id}
                            label={account.name}
                            values={account.values}
                            display={account.display && !account.parentAccountId}
                            subaccounts={account.subaccounts}
                            indent={2}
                            filter={filter}
                            additionalCriteria={additionalCriteria}
                          />
                        ))}
                        <FinancialAccountLine2
                          label="Total Loans"
                          values={this.sumColumnValues(accountsObject.liabilities.loans.accounts)}
                          sumline
                          bold
                          indent={1}
                        />
                      </>
                    )}
                    {accountsObject.liabilities.otherLiabilities.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Liabilities"
                      values={this.sumColumnValues(accountsObject.liabilities.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2 label="Equity" overline bold />
                    <FinancialAccountLine2
                      label="Net Income"
                      values={this.sumColumnValues(incomeAccountsObject.netIncome.accounts)}
                      indent={1}
                    />
                    {displayRetainedEarnings && (
                      <FinancialAccountLine2
                        label="Retained Earnings*"
                        values={this.sumColumnValues(incomeAccountsObject.retainedEarnings.accounts)}
                        indent={1}
                      />
                    )}
                    {accountsObject.equity.accounts.map((account) => (
                      <FinancialAccountLine2
                        key={account.id}
                        accountId={account.id}
                        label={account.name}
                        values={account.values}
                        display={account.display && !account.parentAccountId}
                        subaccounts={account.subaccounts}
                        indent={1}
                        filter={filter}
                        additionalCriteria={additionalCriteria}
                      />
                    ))}
                    <FinancialAccountLine2
                      label="Total Equity"
                      values={this.sumColumnValues(accountsObject.incomeAndEquity.accounts)}
                      underline
                      overline
                      bold
                      marginBottom
                    />
                    <FinancialAccountLine2
                      label="Total Liabilities and Equity"
                      values={this.sumColumnValues(accountsObject.liabilitiesIncomeAndEquity.accounts)}
                      underline
                      overline
                      bold
                    />
                  </Box>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    // bgcolor="common.white"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="body2">
                      {`*This account includes retained earnings, which are net income (or losses) accumulated from prior years.
                    You can view included retained earnings by updating the Date Range filter on the `}
                      <LinkBase to="/reports/net-income">Net Income Report</LinkBase>.
                    </Typography>
                  </Box>
                </>
              )}
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

BalanceSheetByProperty.contextType = PersonContext;

BalanceSheetByProperty.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BalanceSheetByProperty;
