import React, { useContext, useState } from 'react';
import { Box, Dialog, makeStyles, Typography } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import success from '~/lottie/success.json';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    gap: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
  },
  successBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    gap: theme.spacing(2.5),
    backgroundColor: '#E5F6FD',
    alignItems: 'center',
  },
  successAnimationBox: {
    alignContent: 'center',
  },
  successAnimationSmall: {
    width: '80px',
    height: '80px',
  },
  successAnimationLarge: {
    width: '120px',
    height: '120px',
  },
  subscribedTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  upgradeTextBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  captionText: {
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
  },
  bulletPointsBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  bulletPointRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    gap: theme.spacing(2),
  },
  bullet: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    height: '10px',
    width: '10px',
  },
  billingTextBox: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  billingPurpleTextBox: {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignContent: 'center',
    alignItems: 'center',
  },
  checkMarkBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.5),
  },
  checkMarkItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.75),
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
  },
  disclaimerText: {
    color: theme.palette.grey[600],
    textAlign: 'center',
  },
  successRoot: {
    padding: theme.spacing(4),
    gap: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  },
  successTextBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    alignContent: 'center',
    textAlign: 'center',
  },
}));

const SEGMENT_LOCATIONS = {
  SUBSCRIBE: 'Subscription Success Modal - Expert Support Offer',
  SETTINGS: 'Subscription Settings - Expert Support Offer Modal',
};

export default function ExpertSupportSubscribeDialog({ isNewSubscription, location, closeDialog }) {
  const classes = useStyles();
  const tracking = useSegmentTrack();
  const { organizationId, setOrganizationId } = useContext(PersonContext);
  const [step, setStep] = useState('upgrade');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const subscribeToExpertSupport = async () => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      await create('stripe-subscriptions', {
        organizationId,
        plan: 'expert_bookkeeping_monthly',
      });
      setIsSubmitting(false);
      setStep('success');
    } catch (error) {
      setIsSubmitting(false);
      setError(error);
    }
  };

  const getDialogContent = () => {
    switch (step) {
      case 'upgrade':
        return (
          <Box className={classes.root}>
            {isNewSubscription ? (
              <Box className={classes.successBox}>
                <Box className={classes.successAnimationBox}>
                  <Lottie animationData={success} loop={false} className={classes.successAnimationSmall} />
                </Box>
                <Box className={classes.subscribedTextContainer}>
                  <Typography variant="h6">{`You're Subscribed!`}</Typography>
                  <Typography variant="body1">
                    Get ready to have the books you need for tax season, reporting, and more.
                  </Typography>
                </Box>
              </Box>
            ) : null}
            <Box className={classes.upgradeTextBox}>
              <Typography variant="caption" className={classes.captionText}>
                UPGRADE TO EXPERT BOOKKEEPING SUPPORT
              </Typography>
              <Typography variant="h5">On-Demand Rental Bookkeeping Guidance</Typography>
              <Typography variant="body1">
                Get more than standard tech support. Our Virginia-based experts provide bookkeeping advice via email or
                phone.
              </Typography>
            </Box>
            <Box className={classes.bulletPointsBox}>
              <Box className={classes.bulletPointRow}>
                <Box className={classes.bullet} />
                <Typography variant="body1">1-on-1 calls for personalized support</Typography>
              </Box>
              <Box className={classes.bulletPointRow}>
                <Box className={classes.bullet} />
                <Typography variant="body1">Guidance on how to set up and maintain your books</Typography>
              </Box>
              <Box className={classes.bulletPointRow}>
                <Box className={classes.bullet} />
                <Typography variant="body1">Ask about best practices for real estate bookkeeping</Typography>
              </Box>
              <Box className={classes.bulletPointRow}>
                <Box className={classes.bullet} />
                <Typography variant="body1">Get help understanding reports and account balances</Typography>
              </Box>
            </Box>
            <Box className={classes.billingTextBox}>
              <Box className={classes.billingPurpleTextBox}>
                <Typography variant="h4">+$25</Typography>
                <Typography variant="h6">/monthly</Typography>
              </Box>
              <Box className={classes.checkMarkBox}>
                <Box className={classes.checkMarkItem}>
                  <Check fontSize="small" />
                  <Typography variant="body2">Billed Monthly</Typography>
                </Box>
                <Box className={classes.checkMarkItem}>
                  <Check fontSize="small" />
                  <Typography variant="body2">Cancel anytime</Typography>
                </Box>
              </Box>
            </Box>
            {error && (
              <Alert severity="error" onClose={() => setError(null)}>
                {error.message}
              </Alert>
            )}
            <Box className={classes.buttonsContainer}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={() => {
                  tracking('upgrade_my_plan clicked', {
                    location: SEGMENT_LOCATIONS[location],
                  });
                  subscribeToExpertSupport();
                }}
              >
                Upgrade My Plan
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={isSubmitting}
                onClick={() => {
                  tracking('not_right_now clicked', {
                    location: SEGMENT_LOCATIONS[location],
                  });
                  closeDialog();
                }}
              >
                Not Right Now
              </Button>
            </Box>
            <Box className={classes.disclaimerText}>
              <Typography variant="body2">
                Expert Bookkeeping Support is NOT an outsourced bookkeeping service or a replacement for a financial
                professional, including tax filing.
              </Typography>
            </Box>
          </Box>
        );
      case 'success':
        return (
          <Box className={classes.successRoot}>
            <Box className={classes.successAnimationBox}>
              <Lottie animationData={success} loop={false} className={classes.successAnimationLarge} />
            </Box>
            <Box className={classes.successTextBox}>
              <Typography variant="h5">{`You're upgraded!`}</Typography>
              <Typography variant="body1">
                You now have access to expert bookkeeping advice and support from our Virginia-based team.
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                tracking('sounds_good clicked', {
                  location: 'Expert Support Upgrade Success Modal',
                });
                setOrganizationId(organizationId);
                closeDialog();
              }}
            >
              Sounds Good
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open onClose={closeDialog} maxWidth={step === 'upgrade' ? 'sm' : 'xs'} scroll="body">
      {getDialogContent()}
    </Dialog>
  );
}

ExpertSupportSubscribeDialog.defaultProps = {
  isNewSubscription: false,
};

ExpertSupportSubscribeDialog.propTypes = {
  isNewSubscription: PropTypes.bool,
  location: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
};
