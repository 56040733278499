import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '80%',

    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    width: '100%',
  },
  main: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  label: {
    marginBottom: theme.spacing(1.5),
  },
  textField: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  autocompleteClearIndicator: ({ selectedAccount }) => ({
    display: selectedAccount?.id === 'none' ? 'none' : 'inline-flex',
  }),
  autocompleteEndAdornment: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  autocompleteOption: {
    fontSize: theme.typography.body2.fontSize,
  },
  currencyField: {
    width: '175px',
  },
  ruleTypeContainer: {
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
  },
}));

export default useStyles;
