import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box, Dialog, Divider, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import { Close, Event } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { CurrencyField } from '~/components/CurrencyField';
import { patch } from '~/feathersFunctionalWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
  },
  exitIcon: {
    marginLeft: 'auto',
  },
  transactionInformationContainer: {
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  dateAndAccountText: {
    color: theme.palette.text.secondary,
  },
  dateAndAmountFieldsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  datePickerField: {
    width: '50%',
  },
  eventIcon: {
    color: theme.palette.action.active,
  },
  dialogActionsContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  dialogActionsButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.5),
    marginLeft: 'auto',
  },
  red: {
    color: theme.palette.error.main,
  },
  errorAlertContainer: {
    padding: theme.spacing(2),
    color: theme.palette.error.main,
  },
}));

export default function EditImportedTransactionDialog({ closeDialog, onEdit, transaction }) {
  const classes = useStyles();
  const tracking = useSegmentTrack();

  const [editFields, setEditFields] = useState({
    description: transaction.description,
    date: moment(transaction.date).format('YYYY-MM-DD'),
    amount: transaction.amount,
    yodleeType: transaction.yodleeType,
  });
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkIsNegativeAmount = () => {
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return false;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return true;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return false;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return true;
      }
    }
    return true;
  };

  const editTransaction = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    const fields = { ...editFields };
    fields.date = moment(fields.date).format('MM-DD-YYYY');
    if (Number(fields.amount) < 0) {
      fields.amount = Math.abs(Number(fields.amount));
      if (fields.yodleeType === 'DEBIT') {
        fields.yodleeType = 'CREDIT';
      } else {
        fields.yodleeType = 'DEBIT';
      }
    }

    setIsSubmitting(true);

    try {
      await patch('yodlee-transactions', transaction.id, fields);
      onEdit();
      closeDialog();
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open
      scroll="body"
      maxWidth="sm"
      fullWidth
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}
    >
      <form onSubmit={editTransaction}>
        <Box className={classes.root}>
          <Box className={classes.dialogTitleContainer}>
            <Typography variant="subtitle1">Edit Transaction</Typography>
            <IconButton className={classes.exitIcon} onClick={closeDialog}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box className={classes.transactionInformationContainer}>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box style={{ display: 'flex', flexDirection: 'column' }}>
                <Box className={classes.dateAndAccountText}>
                  <Typography variant="caption">{`${moment(transaction.date).format('M/D/YYYY')} • ${transaction.account.name}`}</Typography>
                </Box>
                <Typography variant="h6">{transaction.description}</Typography>
              </Box>
              <Box style={{ marginLeft: 'auto', color: 'error' }}>
                <Typography variant="h6" className={checkIsNegativeAmount() ? classes.red : null}>
                  <NumberFormat
                    value={transaction.amount}
                    displayType="text"
                    thousandSeparator
                    prefix={checkIsNegativeAmount() ? '-$' : '$'}
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </Typography>
              </Box>
            </Box>
            <TextField
              label="Transaction Description"
              variant="outlined"
              fullWidth
              value={editFields.description}
              size="small"
              onChange={(event) => setEditFields({ ...editFields, description: event.target.value })}
            />
            <Box className={classes.dateAndAmountFieldsContainer}>
              <DatePicker
                inputVariant="outlined"
                size="small"
                label="Date"
                type="text"
                format="M/D/YYYY"
                InputProps={{
                  endAdornment: <Event className={classes.eventIcon} />,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                value={editFields.date}
                onChange={(date) => setEditFields({ ...editFields, date: moment(date).format('YYYY-MM-DD') })}
                className={classes.datePickerField}
              />
              <CurrencyField
                label="Amount"
                variant="outlined"
                size="small"
                value={editFields.amount}
                onChange={(event) => setEditFields({ ...editFields, amount: event.target.value })}
                required
                className={classes.datePickerField}
              />
            </Box>
            <Alert severity="error" variant="filled">
              <AlertTitle>Editing may cause issues with your reconciliation later.</AlertTitle>
              <Typography variant="body2">
                By editing the date and amount fields above, the imported data from your linked bank account or
                transaction upload will be overwritten. This action cannot be undone.
              </Typography>
            </Alert>
          </Box>
          <Divider />
          {error ? (
            <Box className={classes.errorAlertContainer}>
              <Alert severity="error">{error.message}</Alert>
            </Box>
          ) : null}
          <Box className={classes.dialogActionsContainer}>
            <Box className={classes.dialogActionsButtonsContainer}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  tracking('cancel clicked', { location: 'Edit Transaction Modal - Import Feed' });
                  closeDialog();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  tracking('save clicked', { location: 'Edit Transaction Modal - Import Feed' });
                  onEdit(editFields);
                }}
                type="submit"
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Dialog>
  );
}

EditImportedTransactionDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
};
