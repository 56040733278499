import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Widget } from '@uploadcare/react-widget';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { create, find } from '../feathersWrapper';
import {
  asyncHandleChange,
  handleAutocompleteChange,
  handleCheckboxChange,
  handleUploaderChange,
} from '../functions/InputHandlers';
import { sumProperty } from '../functions/SumFunctions';

import {
  addressLabel,
  getFilteredUnitOptions,
  getPropertyOptions,
  getUnitOptions,
  nameLabel,
} from './Autocomplete/Library';
import {
  getCreditJournalLine,
  getDebitJournalLine,
  getManualBasicJournal,
} from './SearchSelect/TransactionTypeOptions';

const styles = {
  hideWidget: {
    display: 'none',
  },
  loadingButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
};

class UploadAirbnbStatementDialog extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      key: null,
      data: {},
    };
  }

  setInitialState = async () => {
    const { organizationId } = this.context;
    const { propertyManagerId } = this.props;
    const propertyOptions = await getPropertyOptions(this);
    const unitOptions = await getUnitOptions(this);

    const bankAccountOptions = await find(this, 'accounts', {
      query: {
        organizationId,
        inactive: { $or: [null, false] },
        type2: 'Bank',
        $limit: 500,
        $sort: {
          name: 1,
          id: 1,
        },
      },
    });

    const ownerFundsAccount = await find(this, 'accounts', {
      query: {
        organizationId,
        type2: 'Owner Funds',
        $limit: 1,
      },
    });

    const transactionAccountsQuery = {
      organizationId,
      default: [
        'rentRevenue',
        'cleaningFeesRevenue',
        'managementFeesExpense',
        'airbnbResolutionRevenue',
        'occupancyTaxesPayable',
      ],
    };
    const transactionAccounts = await find(this, 'accounts', { query: transactionAccountsQuery });

    const initialState = {
      key: 'uploadStatement',
      propertyOptions,
      unitOptions,
      bankAccountOptions: bankAccountOptions.data,
      transactionAccounts: transactionAccounts.data,
      ownerFundsAccount: ownerFundsAccount.data[0],
      submitting: false,
      error: null,
      data: {
        organizationId,
        propertyManagerAccountId: propertyManagerId,
        filePath: '',
        filePath_info: {},
      },
      ignoreOwnerPayouts: true,
      createTransferRule: true,
    };

    this.setState(initialState);
  };

  uploadFile = async (event) => {
    event.preventDefault();
    const { data, submitting } = this.state;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    if (!data.filePath || data.filePath_info.mimeType !== 'text/csv') {
      this.setState({ error: { message: 'Please add a .CSV file.' } });
      return;
    }

    Object.assign(data, { organizationId });

    this.setState({ submitting: true });

    create(this, 'upload-airbnb-statement', data, true)
      .then(async (result) => {
        await asyncHandleChange('statementData', result, this);
        this.matchSavedProperties();
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  matchSavedProperties = async () => {
    const { organizationId } = this.context;
    const { propertyManagerId } = this.props;
    const { statementData } = this.state;
    let skipAddressSelect = true;

    const unmappedProperties = [];

    const propertyMappingRulesResult = await find(this, 'property-manager-pdf-mapping-rules', {
      query: {
        organizationId,
        propertyManagerAccountId: propertyManagerId,
        mappingType: 'Property',
        $limit: 500,
      },
    });

    const propertyMappingRules = propertyMappingRulesResult.data;

    statementData.transactionInformation.forEach((transaction) => {
      // ignore previously mapped on successive runs
      if (transaction.transactionData.propertyRule) {
        return;
      }

      // don't try to map empty listing info - these will go to the portfolio level
      if (!transaction.transactionData.property) {
        return;
      }

      const propertyRule = propertyMappingRules.find(
        (rule) => rule.propertyName === transaction.transactionData.property,
      );

      if (!propertyRule) {
        skipAddressSelect = false;
        const unmappedProperty = {
          organizationId,
          mappingType: 'Property',
          propertyManagerAccountId: propertyManagerId,
          propertyIdSelect: null,
          unitIdSelect: null,
          propertyName: transaction.transactionData.property,
          ignored: false,
        };

        // Only push the unmapped property in if it doesn't already exist in the array.
        if (!unmappedProperties.find((property) => property.propertyName === unmappedProperty.propertyName)) {
          unmappedProperties.push(unmappedProperty);
        }
      } else {
        transaction.propertyRule = propertyRule;
      }
    });

    if (!skipAddressSelect) {
      return this.setState({
        submitting: false,
        key: 'addressSelect',
        unmappedProperties,
      });
    }
    return this.matchSavedAccounts();
  };

  savePropertyMapping = async (event) => {
    event.preventDefault();
    const { submitting, unmappedProperties } = this.state;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    const createPromises = [];
    unmappedProperties.forEach((property) => {
      if (!property.ignored) {
        property.propertyId = property.propertyIdSelect.id;

        if (property.unitIdSelect && property.unitIdSelect.propertyId === property.propertyId) {
          property.unitId = property.unitIdSelect.id;
        }
      }
      createPromises.push(create(this, 'property-manager-pdf-mapping-rules', property, true));
    });

    await Promise.all(createPromises)
      .then(() => {
        this.matchSavedProperties();
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  matchSavedAccounts = async () => {
    const { organizationId } = this.context;
    const { propertyManagerId } = this.props;

    const accountMappingRulesResult = await find(this, 'property-manager-pdf-mapping-rules', {
      query: {
        organizationId,
        propertyManagerAccountId: propertyManagerId,
        mappingType: 'Account',
        $limit: 500,
      },
    });

    const accountMappingRules = accountMappingRulesResult.data;

    if (!accountMappingRules.length) {
      return this.setState({
        submitting: false,
        key: 'accountSelect',
        ownerPayoutMapping: {
          organizationId,
          mappingType: 'Account',
          propertyManagerAccountId: propertyManagerId,
          ignored: false,
          accountName: 'Owner Payout',
          accountIdSelect: null,
          accountId: null,
        },
      });
    }

    const { statementData } = this.state;
    statementData.ignorePayouts = accountMappingRules[0].ignored;
    statementData.payoutAccountId = accountMappingRules[0].accountId;
    return this.bookTransactions();
  };

  saveAccountMapping = async (event) => {
    event.preventDefault();
    const { submitting, ignoreOwnerPayouts, ownerPayoutMapping, createTransferRule } = this.state;

    const { propertyManagerId } = this.props;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    if (ignoreOwnerPayouts) {
      ownerPayoutMapping.ignored = true;
    } else {
      ownerPayoutMapping.accountId = ownerPayoutMapping.accountIdSelect.id;
    }

    await create(this, 'property-manager-pdf-mapping-rules', ownerPayoutMapping, true)
      .then(async () => {
        if (ignoreOwnerPayouts) {
          const ruleSubmit = {
            organizationId: ownerPayoutMapping.organizationId,
            descriptionContains: 'Airbnb',
            transactionAmount: null,
            analysisAccountId: null,
            type: 'Transfer From',
            destinationAccountId: propertyManagerId,
            autobookMatchingTransactions: createTransferRule,
          };
          await create(this, 'imported-transaction-rules', ruleSubmit, true).then(async (result) => {
            await create(this, 'automations', {
              organizationId: ruleSubmit.organizationId,
              automationName: 'bookRuleMatching',
              ruleId: result.id,
            });
          });
        }
        this.matchSavedAccounts();
      })
      .catch((error) => {
        this.setState({ error, submitting: false });
      });
  };

  bookTransactions = async () => {
    const { data, transactionAccounts, statementData, ownerFundsAccount } = this.state;
    const { organizationId } = this.context;
    const { propertyManagerId, onBookUploadedTransactions } = this.props;

    const rentAccount = transactionAccounts.find((account) => account.default === 'rentRevenue');
    const cleaningFeesAccount = transactionAccounts.find((account) => account.default === 'cleaningFeesRevenue');
    const hostFeesAccount = transactionAccounts.find((account) => account.default === 'managementFeesExpense');
    const resolutionAccount = transactionAccounts.find((account) => account.default === 'airbnbResolutionRevenue');
    const totAccount = transactionAccounts.find((account) => account.default === 'occupancyTaxesPayable');
    const managementFeesAccount = transactionAccounts.find((account) => account.default === 'managementFeesExpense');

    let createPromises = [];
    let delayCount = 0;

    // If payouts are not ignored, build up the payout journal entry for each payout line and
    // push them into the createPromises array.
    if (!statementData.ignorePayouts) {
      for (let payout of statementData.payouts) {
        const journalTemplate = getManualBasicJournal();

        journalTemplate.organizationId = organizationId;
        journalTemplate.attachmentURL = data.filePath;
        journalTemplate.date = payout.date;
        journalTemplate.description = payout.description;
        journalTemplate.type = 'Transfer';

        journalTemplate.amount = payout.amount;
        journalTemplate.creditLines[0].credit = payout.amount;
        journalTemplate.debitLines[0].debit = payout.amount;
        journalTemplate.creditLines[0].accountId = propertyManagerId;
        journalTemplate.debitLines[0].accountId = statementData.payoutAccountId;

        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);

        createPromises.push(create(this, 'journals', journalTemplate, true));

        // await requests for large files
        delayCount++;
        if (delayCount % 100 === 0) {
          try {
            await Promise.allSettled(createPromises);
            createPromises = [];
          } catch (error) {
            this.setState({ error, submitting: false });
            return;
          }
        }
      }
    }

    for (let transaction of statementData.transactionInformation) {
      if (transaction.propertyRule && transaction.propertyRule.ignored) {
        continue;
      }

      const journalTemplate = getManualBasicJournal();

      journalTemplate.organizationId = organizationId;
      journalTemplate.attachmentURL = data.filePath;
      journalTemplate.date = transaction.transactionData.date;
      journalTemplate.description = transaction.transactionData.description;

      journalTemplate.propertyId = transaction.propertyRule ? transaction.propertyRule.propertyId : null;
      journalTemplate.unitId = transaction.propertyRule ? transaction.propertyRule.unitId : null;

      // Set up the journal entry for resolutions.
      if (
        transaction.transactionData.type === 'Resolution Payout' ||
        transaction.transactionData.type === 'Resolution Adjustment'
      ) {
        // Set transaction type to refund or net income depending on the amount.
        if (transaction.transactionData.amount < 0) {
          journalTemplate.type = 'Refund';
        } else {
          journalTemplate.type = 'Net Income';
        }
        journalTemplate.amount = transaction.transactionData.amount;
        journalTemplate.creditLines[0].credit = transaction.transactionData.amount;
        journalTemplate.debitLines[0].debit = transaction.transactionData.amount;
        journalTemplate.creditLines[0].accountId = resolutionAccount.id;
        journalTemplate.debitLines[0].accountId = propertyManagerId;

        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);
      } else if (
        transaction.transactionData.type === 'Pass Through Tot' ||
        transaction.transactionData.type === 'Pass Through Tot Adjustment'
      ) {
        // Set transaction type to refund or net income depending on the amount.
        if (transaction.transactionData.amount < 0) {
          journalTemplate.type = 'Refund';
        } else {
          journalTemplate.type = 'Payment';
        }
        journalTemplate.amount = transaction.transactionData.amount;
        journalTemplate.creditLines[0].credit = transaction.transactionData.amount;
        journalTemplate.debitLines[0].debit = transaction.transactionData.amount;
        journalTemplate.creditLines[0].accountId = totAccount.id;
        journalTemplate.debitLines[0].accountId = propertyManagerId;

        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);
      } else if (
        transaction.transactionData.type === 'Co-Host payout' ||
        transaction.transactionData.type === 'Landlord Commission'
      ) {
        if (transaction.transactionData.amount < 0) {
          journalTemplate.type = 'Expense';
          const formattedAmount = Math.abs(transaction.transactionData.amount);
          journalTemplate.amount = formattedAmount;
          journalTemplate.creditLines[0].credit = formattedAmount;
          journalTemplate.creditLines[0].accountId = propertyManagerId;
          journalTemplate.debitLines[0].debit = formattedAmount;
          journalTemplate.debitLines[0].accountId = managementFeesAccount.id;
        } else {
          journalTemplate.type = 'Revenue';
          journalTemplate.amount = transaction.transactionData.amount;
          journalTemplate.creditLines[0].credit = transaction.transactionData.amount;
          journalTemplate.creditLines[0].accountId = rentAccount.id;
          journalTemplate.debitLines[0].debit = transaction.transactionData.amount;
          journalTemplate.debitLines[0].accountId = propertyManagerId;
        }
        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);
      } else if (
        transaction.transactionData.type === 'Misc Credit' ||
        transaction.transactionData.type === 'Paid Photography Adjustment'
      ) {
        if (transaction.transactionData.amount < 0) {
          journalTemplate.type = 'Expense';
        } else {
          journalTemplate.type = 'Refund';
        }
        journalTemplate.amount = transaction.transactionData.amount;
        journalTemplate.creditLines[0].credit = transaction.transactionData.amount;
        journalTemplate.debitLines[0].debit = transaction.transactionData.amount;
        journalTemplate.creditLines[0].accountId = managementFeesAccount.id;
        journalTemplate.debitLines[0].accountId = propertyManagerId;

        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);
      } else if (transaction.transactionData.type === 'Building revenue share adjustment') {
        journalTemplate.type = 'Expense';
        journalTemplate.amount = transaction.transactionData.amount;
        journalTemplate.creditLines[0].credit = transaction.transactionData.amount;
        journalTemplate.creditLines[0].accountId = managementFeesAccount.id;
        journalTemplate.debitLines[0].debit = transaction.transactionData.amount;
        journalTemplate.debitLines[0].accountId = propertyManagerId;
        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);
      } else if (transaction.transactionData.type === 'HOST_OFFER_MANUAL') {
        journalTemplate.type = 'Revenue';
        journalTemplate.amount = transaction.transactionData.amount;
        journalTemplate.creditLines[0].credit = transaction.transactionData.amount;
        journalTemplate.creditLines[0].accountId = rentAccount.id;
        journalTemplate.debitLines[0].debit = transaction.transactionData.amount;
        journalTemplate.debitLines[0].accountId = propertyManagerId;
        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);
      } else if (
        transaction.transactionData.type === 'Tax Withholding for US Income' ||
        transaction.transactionData.type === 'Tax Withholding Reversal for US Income'
      ) {
        journalTemplate.amount = transaction.transactionData.amount;
        if (transaction.transactionData.type === 'Tax Withholding for US Income') {
          journalTemplate.type = 'Distribution';
          journalTemplate.creditLines[0].credit = transaction.transactionData.amount;
          journalTemplate.creditLines[0].accountId = propertyManagerId;
          journalTemplate.debitLines[0].debit = transaction.transactionData.amount;
          journalTemplate.debitLines[0].accountId = ownerFundsAccount.id;
        }
        if (transaction.transactionData.type === 'Tax Withholding Reversal for US Income') {
          journalTemplate.type = 'Contribution';
          journalTemplate.creditLines[0].credit = transaction.transactionData.amount;
          journalTemplate.creditLines[0].accountId = ownerFundsAccount.id;
          journalTemplate.debitLines[0].debit = transaction.transactionData.amount;
          journalTemplate.debitLines[0].accountId = propertyManagerId;
        }
        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);
      } else if (
        transaction.transactionData.type === 'Reservation' ||
        transaction.transactionData.type === 'Adjustment' ||
        transaction.transactionData.type === 'Cancellation Fee' ||
        transaction.transactionData.type === 'Cancellation Fee Refund'
      ) {
        // Set up the journal entry for all other transactions
        journalTemplate.creditLines = journalTemplate.creditLines.concat([getCreditJournalLine()]);
        journalTemplate.debitLines = journalTemplate.debitLines.concat([getDebitJournalLine()]);
        const { credits } = transaction.lines;
        const { debits } = transaction.lines;

        // set up credit lines
        journalTemplate.creditLines[0].accountId = rentAccount.id;
        journalTemplate.creditLines[0].credit = credits.rentRevenue;
        journalTemplate.creditLines[1].accountId = cleaningFeesAccount.id;
        journalTemplate.creditLines[1].credit = credits.cleaningFees;

        // set up debit lines
        journalTemplate.debitLines[0].accountId = hostFeesAccount.id;
        journalTemplate.debitLines[0].debit = debits.hostFees;
        journalTemplate.debitLines[1].accountId = propertyManagerId;
        journalTemplate.debitLines[1].debit = debits.airbnb;

        journalTemplate.creditLines.forEach((line, index) => {
          if (line.credit === 0) {
            journalTemplate.creditLines.splice(index, 1);
          }
        });

        journalTemplate.debitLines.forEach((line, index) => {
          if (line.debit === 0) {
            journalTemplate.debitLines.splice(index, 1);
          }
        });

        const debitAmount = sumProperty(journalTemplate.debitLines, 'debit');

        journalTemplate.amount = debitAmount;
        journalTemplate.journalLines = journalTemplate.creditLines.concat(journalTemplate.debitLines);

        // Set transaction type to refund or net income depending on the amount.
        if (debitAmount < 0) {
          journalTemplate.type = 'Refund';
        } else {
          journalTemplate.type = 'Net Income';
        }
      }

      createPromises.push(create(this, 'journals', journalTemplate, true));

      // await requests for large files
      delayCount++;
      if (delayCount % 100 === 0) {
        try {
          await Promise.allSettled(createPromises);
          createPromises = [];
        } catch (error) {
          this.setState({ error, submitting: false });
          return;
        }
      }
    }
    try {
      await Promise.allSettled(createPromises);
      this.setState({ key: 'confirmation', submitting: false });
      onBookUploadedTransactions();
    } catch (error) {
      this.setState({ error, submitting: false });
    }
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    closeDialog();
  };

  removeUploadedFile = async () => {
    await asyncHandleChange('nested_data_filePath', '', this);
  };

  getDialogContent = () => {
    const { classes } = this.props;
    const {
      key,
      data,
      error,
      unmappedProperties,
      propertyOptions,
      unitOptions,
      ignoreOwnerPayouts,
      createTransferRule,
      bankAccountOptions,
      ownerPayoutMapping,
      submitting,
    } = this.state;

    switch (key) {
      case 'uploadStatement':
        return (
          <form onSubmit={this.uploadFile}>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  {`Please select the Airbnb completed payouts report
                      that you would like to upload from your computer.`}
                </Typography>
                <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                  <Typography variant="body1" gutterBottom>
                    {`You can find instructions on downloading your Airbnb Paid Earnings report for the
                      desired date range here: `}
                    <a href="https://www.airbnb.com/help/article/304" target="_blank" rel="noopener noreferrer">
                      https://www.airbnb.com/help/article/304
                    </a>
                  </Typography>
                </Box>
              </Box>
              <FormControl margin="dense">
                <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                  File
                </FormLabel>
                <div className={data.filePath ? null : classes.hideWidget}>
                  <Widget
                    ref={this.uploadcareWidget}
                    tabs="file"
                    value={data.filePath}
                    onChange={handleUploaderChange('nested_data_filePath', this)}
                  />
                </div>
                {!data.filePath && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      this.uploadcareWidget.current.openDialog();
                    }}
                  >
                    Click to add your CSV
                  </Button>
                )}
                {data.filePath && (
                  <Button color="primary" onClick={() => this.removeUploadedFile()}>
                    Remove statement
                  </Button>
                )}
              </FormControl>
              {error && (
                <Typography variant="body1" gutterBottom color="error">
                  {error.message}
                </Typography>
              )}
              <Box mx="auto" textAlign="center" mt={4}>
                <Button type="submit" color="secondary" variant="outlined" size="large" fullWidth disabled={submitting}>
                  {submitting ? 'In Progress' : 'Upload CSV'}
                </Button>
                <Button color="primary" onClick={this.closeDialog}>
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'addressSelect':
        return (
          <form onSubmit={this.savePropertyMapping}>
            <DialogContent>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Properties
                </Typography>
                <Typography variant="body1" gutterBottom>
                  We found the following unknown property names in your upload. Please select the corresponding property
                  from your account to book these transactions to.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {`Note: These choices will be saved to automatically assign transactions to the
                    chosen properties on future Airbnb statement uploads.`}
                </Typography>
                {unmappedProperties.map((property, index) => (
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <Typography variant="body1" gutterBottom>
                      {property.propertyName}
                    </Typography>
                    {!property.ignored && (
                      <>
                        <Autocomplete
                          options={propertyOptions}
                          getOptionLabel={addressLabel}
                          getOptionSelected={(option, value) => option.id === value.id}
                          value={property.propertyIdSelect}
                          onChange={handleAutocompleteChange(
                            `nested_unmappedProperties_${index}_propertyIdSelect`,
                            this,
                          )}
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="dense"
                              label="Property"
                              placeholder="Type to Search"
                              fullWidth
                              required
                            />
                          )}
                        />
                        {property.propertyIdSelect && property.propertyIdSelect.unitSelection === 'multi' && (
                          <Autocomplete
                            options={getFilteredUnitOptions(unitOptions, property.propertyIdSelect.id, {
                              addAll: true,
                            })}
                            getOptionLabel={nameLabel}
                            getOptionSelected={(option, value) => option.id === value.id}
                            value={property.unitIdSelect}
                            onChange={handleAutocompleteChange(`nested_unmappedProperties_${index}_unitIdSelect`, this)}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                margin="dense"
                                label="Unit"
                                placeholder="Type to Search"
                                fullWidth
                                required
                              />
                            )}
                          />
                        )}
                      </>
                    )}
                    <FormControl margin="none">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={property.ignored}
                            name={`nested_unmappedProperties_${index}_ignored`}
                            onChange={handleCheckboxChange(this)}
                          />
                        }
                        label="Ignore"
                      />
                    </FormControl>
                  </Box>
                ))}
              </Box>
              <Box mx="auto" textAlign="center" mt={4}>
                <Button type="submit" color="secondary" variant="outlined" size="large" fullWidth disabled={submitting}>
                  {submitting ? (
                    <Box className={classes.loadingButton}>
                      <CircularProgress size={24} />
                      In Progress
                    </Box>
                  ) : (
                    'Save and Continue'
                  )}
                </Button>
                <Button color="primary" onClick={this.closeDialog}>
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'accountSelect':
        return (
          <form onSubmit={this.saveAccountMapping}>
            <DialogContent>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Would you like to book owner payout transactions?
                </Typography>
                <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                  <Typography variant="body2" gutterBottom>
                    In most cases owner payment lines in your Airbnb transaction file should be ignored during this
                    upload process. This is to prevent duplication with the deposits from Airbnb imported into your
                    linked bank account.
                  </Typography>
                  {!ignoreOwnerPayouts && (
                    <Autocomplete
                      groupBy={(option) => option.group}
                      options={bankAccountOptions}
                      getOptionLabel={nameLabel}
                      getOptionSelected={(option, value) => option.id === value.id}
                      value={ownerPayoutMapping.accountIdSelect}
                      onChange={handleAutocompleteChange('nested_ownerPayoutMapping_accountIdSelect', this)}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          label="Payout Account"
                          placeholder="Type to search"
                          fullWidth
                          required
                        />
                      )}
                    />
                  )}
                  <FormControl margin="none" fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={ignoreOwnerPayouts}
                          name="nested_ignoreOwnerPayouts"
                          onChange={handleCheckboxChange(this)}
                        />
                      }
                      label="Ignore Owner Payouts; Only Upload Revenues and Expenses"
                    />
                  </FormControl>
                </Box>
                {ignoreOwnerPayouts && (
                  <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                    <Typography variant="body2" gutterBottom>
                      Instead, book incoming funds as transfers from Airbnb using your bank import feed. This ensures
                      that your bank account balance is updated by Airbnb deposits without duplicating revenues. This
                      can be automated using a matching rule.
                    </Typography>
                    <FormControl margin="none" fullWidth>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={createTransferRule}
                            name="nested_createTransferRule"
                            onChange={handleCheckboxChange(this)}
                          />
                        }
                        label="Automatically book Airbnb deposits"
                      />
                    </FormControl>
                  </Box>
                )}
              </Box>
              <Box mx="auto" textAlign="center" mt={4}>
                <Button type="submit" color="secondary" variant="outlined" size="large" fullWidth disabled={submitting}>
                  {submitting ? 'In Progress' : 'Complete Upload'}
                </Button>
                <Button color="primary" onClick={this.closeDialog}>
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </form>
        );
      case 'confirmation':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Success
              </Typography>
              <Typography variant="body1" gutterBottom>
                {`We have booked the transactions included in your statement. Be sure to book
                    any payouts included in this statement as transfers from your import feed.
                    Please double check the imported transactions and email us at
                    support@reihub.net if you require assistance.`}
              </Typography>
            </Box>
            <Box mx="auto" textAlign="center" mt={4} mb={2}>
              <Button color="secondary" variant="outlined" size="large" fullWidth onClick={this.closeDialog}>
                Close
              </Button>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.setInitialState}
      >
        {this.getDialogContent()}
      </Dialog>
    );
  }
}

UploadAirbnbStatementDialog.contextType = PersonContext;

UploadAirbnbStatementDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  propertyManagerId: PropTypes.node.isRequired,
  onBookUploadedTransactions: PropTypes.func.isRequired,
};

export default withStyles(styles)(UploadAirbnbStatementDialog);
