import makeStyles from '@material-ui/core/styles/makeStyles';

export const COLORS = {
  DEFAULT: 'default',
  ERROR: 'error',
  INFO: 'info',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

export const VARIANTS = {
  FILLED: 'filled',
  OUTLINED: 'outlined',
};

const useStyles = makeStyles((theme) => {
  const themeColorsMap = {
    [VARIANTS.FILLED]: {
      [COLORS.DEFAULT]: {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.text.primary,
        boxShadow: 'none',
        onFocus: {
          backgroundColor: '#00000033',
        },
        onHover: {
          backgroundColor: '#0000001F',
        },
        thumbnail: {
          backgroundColor: theme.palette.avatar.fill,
          color: theme.palette.background.default,
        },
        deleteIcon: {
          opacity: 0.25,
          color: '#000',
        },
      },
      [COLORS.ERROR]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        boxShadow: 'none',
        onFocus: {
          backgroundColor: theme.palette.error.dark,
        },
        onHover: {
          backgroundColor: theme.palette.error.dark,
        },
        thumbnail: {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.error.contrastText,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.error.contrastText,
        },
      },
      [COLORS.INFO]: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        boxShadow: 'none',
        onFocus: {
          backgroundColor: theme.palette.info.dark,
        },
        onHover: {
          backgroundColor: theme.palette.info.dark,
        },
        thumbnail: {
          backgroundColor: theme.palette.info.dark,
          color: theme.palette.info.contrastText,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.info.contrastText,
        },
      },
      [COLORS.PRIMARY]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: 'none',
        onFocus: {
          backgroundColor: theme.palette.primary.dark,
        },
        onHover: {
          backgroundColor: theme.palette.primary.dark,
        },
        thumbnail: {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.primary.contrastText,
        },
      },
      [COLORS.SECONDARY]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: 'none',
        onFocus: {
          backgroundColor: theme.palette.secondary.dark,
        },
        onHover: {
          backgroundColor: theme.palette.secondary.dark,
        },
        thumbnail: {
          backgroundColor: theme.palette.secondary.dark,
          color: theme.palette.secondary.contrastText,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.secondary.contrastText,
        },
      },
      [COLORS.SUCCESS]: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
        boxShadow: 'none',
        onFocus: {
          backgroundColor: theme.palette.success.dark,
        },
        onHover: {
          backgroundColor: theme.palette.success.dark,
        },
        thumbnail: {
          backgroundColor: theme.palette.success.dark,
          color: theme.palette.success.contrastText,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.success.contrastText,
        },
      },
      [COLORS.WARNING]: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        boxShadow: 'none',
        onFocus: {
          backgroundColor: theme.palette.warning.dark,
        },
        onHover: {
          backgroundColor: theme.palette.warning.dark,
        },
        thumbnail: {
          backgroundColor: theme.palette.warning.dark,
          color: theme.palette.warning.contrastText,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.warning.contrastText,
        },
      },
    },
    [VARIANTS.OUTLINED]: {
      [COLORS.DEFAULT]: {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        boxShadow: 'inset 0 0 0 1px #BDBDBD',
        onFocus: {
          backgroundColor: theme.palette.action.focus,
        },
        onHover: {
          backgroundColor: theme.palette.action.hover,
        },
        thumbnail: {
          backgroundColor: theme.palette.avatar.fill,
          color: theme.palette.background.default,
        },
        deleteIcon: {
          opacity: 0.25,
          color: '#000',
        },
      },
      [COLORS.ERROR]: {
        backgroundColor: 'transparent',
        color: theme.palette.error.main,
        boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
        onFocus: {
          backgroundColor: theme.palette.error._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.error._states.hover,
        },
        thumbnail: {
          backgroundColor: theme.palette.error.dark,
          color: theme.palette.background.default,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.error.main,
        },
      },
      [COLORS.INFO]: {
        backgroundColor: 'transparent',
        color: theme.palette.info.main,
        boxShadow: `inset 0 0 0 1px ${theme.palette.info.main}`,
        onFocus: {
          backgroundColor: theme.palette.info._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.info._states.hover,
        },
        thumbnail: {
          backgroundColor: theme.palette.info.dark,
          color: theme.palette.background.default,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.info.main,
        },
      },
      [COLORS.PRIMARY]: {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
        onFocus: {
          backgroundColor: theme.palette.primary._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.primary._states.hover,
        },
        thumbnail: {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.background.default,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.primary.main,
        },
      },
      [COLORS.SECONDARY]: {
        backgroundColor: 'transparent',
        color: theme.palette.secondary.main,
        boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.main}`,
        onFocus: {
          backgroundColor: theme.palette.secondary._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.secondary._states.hover,
        },
        thumbnail: {
          backgroundColor: theme.palette.secondary.dark,
          color: theme.palette.background.default,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.secondary.main,
        },
      },
      [COLORS.SUCCESS]: {
        backgroundColor: 'transparent',
        color: theme.palette.success.main,
        boxShadow: `inset 0 0 0 1px ${theme.palette.success.main}`,
        onFocus: {
          backgroundColor: theme.palette.success._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.success._states.hover,
        },
        thumbnail: {
          backgroundColor: theme.palette.success.dark,
          color: theme.palette.background.default,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.success.main,
        },
      },
      [COLORS.WARNING]: {
        backgroundColor: 'transparent',
        color: theme.palette.warning.main,
        boxShadow: `inset 0 0 0 1px ${theme.palette.warning.main}`,
        onFocus: {
          backgroundColor: theme.palette.warning._states.focusVisible,
        },
        onHover: {
          backgroundColor: theme.palette.warning._states.hover,
        },
        thumbnail: {
          backgroundColor: theme.palette.warning.dark,
          color: theme.palette.background.default,
        },
        deleteIcon: {
          opacity: 0.7,
          color: theme.palette.warning.main,
        },
      },
    },
  };

  return {
    chip: (props) => ({
      'backgroundColor': themeColorsMap[props.variant][props.color].backgroundColor,
      'border': 'none',
      'boxShadow': themeColorsMap[props.variant][props.color].boxShadow,
      'color': themeColorsMap[props.variant][props.color].color,
      'fontSize': props.size === SIZES.SMALL ? '13px' : theme.typography.caption.fontSize,

      '&:focus, &:focus-visible':
        props.onClick || props.onDelete ? themeColorsMap[props.variant][props.color].onFocus : {},
      '&:hover': props.onClick || props.onDelete ? themeColorsMap[props.variant][props.color].onHover : {},

      '& .MuiChip-avatar': themeColorsMap[props.variant][props.color].thumbnail,

      '& .MuiChip-deleteIcon': themeColorsMap[props.variant][props.color].deleteIcon,
    }),
  };
});

export default useStyles;
