export const ADD_RULE_SEGMENT_LOCATION = 'Generic Create Rule Drawer';

export const RULE_TYPES = {
  BOOK: 'Book',
  IGNORE: 'Ignore',
  SPLIT: 'Split',
};

export const TRANSACTION_TYPES = {
  EXPENSE: 'Expense',
  FIXED_ASSET_PURCHASE: 'Fixed Asset Purchase',
  REVENUE: 'Revenue',
  TRANSFER_FROM: 'Transfer From',
  TRANSFER_TO: 'Transfer To',
};

export const AUTOMATION_NAMES_MAP = {
  [TRANSACTION_TYPES.EXPENSE]: 'bookRuleMatching',
  [TRANSACTION_TYPES.FIXED_ASSET_PURCHASE]: 'bookRuleMatching',
  [RULE_TYPES.IGNORE]: 'ignoreRuleMatching',
  [TRANSACTION_TYPES.REVENUE]: 'bookRuleMatching',
  [RULE_TYPES.SPLIT]: 'splitRuleMatching',
  [TRANSACTION_TYPES.TRANSFER_FROM]: 'bookRuleMatching',
  [TRANSACTION_TYPES.TRANSFER_TO]: 'bookRuleMatching',
};
