import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

import QuickBookRowActions from '../QuickBookRowActions';
import QuickBookRowNotesAndAttachment from '../QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '../QuickBookRowScopeSelect';

import { setUpJournalForSubmit } from './helpers/journalFunctions';
import { useStyles } from './styles';

export default function QuickBookRowSecurityDepositReceiveTemplate({
  transaction,
  onTemplateLoad,
  onTransactionAction,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [securityDepositAccount, setSecurityDepositAccount] = useState(null);
  const [journal, setJournal] = useState(getManualBasicJournal());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { organizationId, triggerSnackbar } = useContext(PersonContext);
  const tracking = useSegmentTrack();
  const classes = useStyles();

  const fetchSecurityDepositAccount = useCallback(
    async (organizationId) => {
      setIsLoading(true);

      const securityDepositAccounts = await getAccountOptions(organizationId, {
        type2: 'Security Deposits',
        inactive: { $or: [null, false] },
      });
      setSecurityDepositAccount(securityDepositAccounts[0]);

      setIsLoading(false);
      onTemplateLoad();
    },
    [onTemplateLoad],
  );

  async function addSecurityDeposit(event) {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      const journalBody = setUpJournalForSubmit({
        transactionId: transaction.id,
        journal,
        organizationId,
        type: 'Security Deposit In',
        direction: 'cash in',
        bookAsRefund: false,
      });
      await create('journals', journalBody);

      setIsSubmitting(false);
      onTransactionAction();
    } catch {
      triggerSnackbar({
        horizontal: 'center',
        message: 'Something went wrong. Please try again.',
        type: 'error',
        vertical: 'top',
      });
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    fetchSecurityDepositAccount(organizationId);
  }, [fetchSecurityDepositAccount, organizationId]);

  useEffect(() => {
    setJournal((prevJournal) => {
      const newJournal = { ...prevJournal };
      newJournal.debitLines[0].accountIdSelect = transaction.account;
      newJournal.debitLines[0].transactionId = transaction.id;
      newJournal.creditLines[0].accountIdSelect = securityDepositAccount;
      newJournal.description = transaction.description;
      newJournal.date = transaction.date;
      newJournal.amount = transaction.amount;
      newJournal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
      return newJournal;
    });
  }, [transaction, securityDepositAccount]);

  if (isLoading) {
    return null;
  }

  return (
    <form onSubmit={addSecurityDeposit}>
      <div className={classes.rootContainer}>
        <div className={classes.container}>
          <TextField
            className={classes.mobileDescriptionField}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={(event) => {
              setJournal({ ...journal, description: event.target.value });
            }}
          />
        </div>
        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
        <QuickBookRowNotesAndAttachment journal={journal} onChange={setJournal} />
        <div className={classes.actionsContainer}>
          <QuickBookRowActions
            journal={journal}
            setJournal={setJournal}
            transaction={transaction}
            onTransactionAction={onTransactionAction}
          />
          <div className={classes.rowActionsSecondaryContainer}>
            <Button
              className={classes.saveButton}
              size="small"
              type="submit"
              variant="contained"
              onClick={() => {
                tracking('save clicked', { location: 'Inline Booking - Import Feed' });
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

QuickBookRowSecurityDepositReceiveTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTemplateLoad: PropTypes.func.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
};
