import React from 'react';
import PropTypes from 'prop-types';

export default function ContentPasteOffOutlined({ color, ...rest }) {
  return (
    <svg fill={color} viewBox="0 0 24 24" {...rest}>
      <path d="M21.19 21.19 2.81 2.81 1.39 4.22 3 5.83V19c0 1.1.9 2 2 2h13.17l1.61 1.61zM5 19V7.83L16.17 19zM17 8V5h2v11.17l2 2V5c0-1.1-.9-2-2-2h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5.83l5 5zm-5-5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1" />
    </svg>
  );
}

ContentPasteOffOutlined.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

ContentPasteOffOutlined.defaultProps = {
  color: 'currentColor',
  height: 24,
  width: 24,
};
