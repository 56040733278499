import React, { useContext, useEffect, useState } from 'react';
import { Card, Fab, makeStyles, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  CheckCircle as CheckCircleIcon,
  Clear as ClearIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
} from '@material-ui/icons';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import { PersonContext } from '../contexts/PersonContext';
import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { find, patch } from '../feathersWrapper';
import { setJournalScope } from '../functions/JournalFunctions';
import { useSegmentTrack } from '../functions/SegmentFunctions';
import history from '../history';

import PlaidLinkAdd from './Plaid/PlaidLinkAdd';
import YodleeLinkAdd from './Yodlee/YodleeLinkAdd';
import AddBankingAccountDialog from './AddBankingAccountDialog';
import AddLoanDialog from './AddLoanDialog';
import Button from './Button';
import SkipForNowAccountsDialog from './SkipForNowAccountsDialog';
import TransactionScope from './TransactionScope';
import VideoPlayerDialog from './VideoPlayerDialog';

const useStyles = makeStyles((theme) => ({
  root: { paddingTop: '55px' },
  closeIcon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    color: 'white',
  },
  successIcon: {
    height: '64px',
    width: '64px',
    color: 'green',
  },
  heroImage: {
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '248px',
    },
  },
  onboardingCard: {
    maxWidth: '600px',
    width: '100%',
  },
  setupCompleteImage: {
    height: 'auto',
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '300px',
  },
  addAnotherLoanAccountCard: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  addAnotherLoanAccountCardIcon: {
    marginBottom: theme.spacing(1),
    color: theme.palette.success.main,
    fontSize: '64px',
  },
  addAnotherLoanAccountCardButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  addAnotherLoanAccountCardButton: {
    maxWidth: '400px',
    width: '100%',
  },
  addLoansCard: {
    padding: theme.spacing(3),
  },
  addLoansButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: '100%',
  },
  addLoansButton: {
    maxWidth: '400px',
    width: '100%',
  },
}));

export default function ViewWelcomeAccountsDialog({ isOpen, onAddAccount, allAccounts }) {
  const classes = useStyles();
  const context = useContext(PersonContext);
  const {
    organizationId,
    organizationName,
    bookkeepingStartDate,
    setOrganizationId,
    setIsTourVisible,
    partnerName,
    setIsScheduleCallDialogVisible,
  } = context;
  const [loading, setLoading] = useState(true);
  const [addAccountDialog, setAddAccountDialog] = useState(false);

  const [plaidAddNew, setPlaidAddNew] = useState(false);
  const [yodleeAddLink, setYodleeAddLink] = useState(false);

  const [addLoanAccountDialog, setAddLoanAccountDialog] = useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [skipForNowDialogVisible, setSkipForNowDialogVisible] = useState(false);
  const [plaidExitDialogVisible, setPlaidExitDialogVisible] = useState(false);
  const [state, setState] = useState({
    key: 'accountsOverview',
  });
  const tracking = useSegmentTrack();
  const { userOnboarding } = useOnboardingContext();

  useEffect(() => {
    async function getData() {
      const bankingQuery = {
        organizationId,
        type2: ['Bank', 'Credit Card'],
        default: null,
      };
      const bankingAccounts = await find(this, 'accounts', { query: bankingQuery });
      const accountCount = bankingAccounts.total;
      if (window.location.pathname === '/onboarding-complete') {
        setState({
          key: 'setupComplete',
        });
      } else if (accountCount === 0) {
        setState({
          key: 'accountsOverview',
          accountCount,
        });
      } else {
        setState({
          key: 'addAccountsSuccess',
          accountCount,
        });
      }
      setLoading(false);
    }
    getData();
  }, [organizationId]);

  const addAccount = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onboardingAddAccount',
      organizationId,
    });

    await onAddAccount();

    const query = {
      organizationId,
      type2: ['Bank', 'Credit Card'],
    };

    let key;
    if (state.plaidItem) {
      key = 'plaidSuccess';
    } else if (state.yodleeProvider) {
      key = 'yodleeSuccess';
    } else {
      key = 'addAccountsSuccess';
    }

    const accounts = await find(this, 'accounts', { query });
    setState({
      ...state,
      key,
      accountCount: accounts.total,
    });
  };

  const handleSaveAndClose = async (event) => {
    event.preventDefault();
    const patchPromises = [];

    let account;

    state.addedAccounts.forEach((accountItem) => {
      if (state.plaidItem) {
        account = allAccounts.find((item) => item.plaidAccountId === accountItem.plaidAccountId);
      }
      if (state.yodleeProvider) {
        account = allAccounts.find((item) => item.yodlee_account.accountData.id === accountItem.yodleeAccountId);
      }

      if (account) {
        setJournalScope(accountItem.journal);
        const data = {
          name: accountItem.name,
          propertyId: accountItem.journal.propertyId,
          entityId: accountItem.journal.entityId,
          unitId: accountItem.journal.unitId,
        };
        patchPromises.push(patch(this, 'accounts', account.id, data));
      }
    });

    const results = await Promise.allSettled(patchPromises);
    let error;
    results.forEach((result) => {
      if (result.status === 'rejected') {
        error = result.reason;
      }
    });

    if (error) {
      setState({ ...state, error });
    } else {
      onAddAccount();
      setState({
        ...state,
        error: null,
        plaidItem: null,
        yodleeProvider: null,
        key: 'addAccountsSuccess',
      });
    }
  };

  const addLoanAccount = async () => {
    const query = {
      organizationId,
      type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
    };
    const loanAccounts = await find(this, 'accounts', { query });

    setState({
      ...state,
      loanAccountCount: loanAccounts.total,
      key: 'addAnotherLoanAccount',
    });
  };

  const finishOnboarding = async () => {
    await patch(this, 'organizations', organizationId, {
      showWelcome: false,
    });
    await setOrganizationId(organizationId);

    // set the threshold for the number of units to offer an onboarding call
    // onboarding calls must also not be disabled by the boolean in the dashboard layout
    const hasEnoughUnitsForOnboardingCall = userOnboarding?.totalUnits >= 3;

    setIsTourVisible(true);
    if (hasEnoughUnitsForOnboardingCall) {
      setIsScheduleCallDialogVisible(true);
    }

    history.push('/properties');
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const getDialogContent = (key) => {
    switch (key) {
      case 'accountsOverview':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Box style={{ position: 'relative' }} mb={1.5}>
                <img className={classes.heroImage} src="/rei_hub_accounts_intro_hero.png" alt="Accounts" />
                <Fab
                  color="secondary"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                  }}
                  onClick={() => {
                    tracking('watch_accounts_overview clicked', { location: 'Onboarding - Accounts Intro' });
                    setVideoDialogOpen(true);
                  }}
                >
                  <PlayCircleFilledIcon color="white" fontSize="24px" />
                </Fab>
              </Box>
              <Box mb={3}>
                <Box mb={2} textAlign="center">
                  <Typography variant="h6">Link the accounts used for your rentals</Typography>
                </Box>
                <Box mb={1} textAlign="center">
                  <Typography variant="body1">
                    {`Transactions will securely and automatically import from your financial institution for easy
                    and accurate categorization. `}
                    {partnerName && (
                      <Box component="span" fontWeight="bold">
                        Over 80% of REI Hub customers link at least one account.
                      </Box>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {partnerName && (
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginTop={3}
                textAlign="left"
              >
                <Typography variant="body2">
                  <li>
                    {`Linked accounts in REI Hub require separate authorization from deposit accounts
                  connected through ${partnerName}.`}
                  </li>
                  <li>
                    {`We recommend creating all accounts receiving deposits from ${partnerName} as
                  linked accounts in REI Hub.`}
                  </li>
                </Typography>
              </Box>
            )}
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={3} mb={2}>
              <Button
                hasFullWidth
                variant="contained"
                onClick={() => {
                  tracking('link_account clicked', { location: 'Onboarding - Accounts: Intro' });
                  setPlaidAddNew(true);
                }}
              >
                Link Account
              </Button>
              {partnerName ? (
                <Box mt={1}>
                  <Button
                    hasFullWidth
                    variant="text"
                    onClick={() => {
                      tracking('skip_for_now clicked', { location: 'Onboarding - Accounts Intro' });
                      setSkipForNowDialogVisible(true);
                    }}
                  >
                    Skip For Now
                  </Button>
                </Box>
              ) : null}
            </Box>
          </DialogContent>
        );
      case 'addManualAccounts':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Would you like to add any manual bank or credit card accounts?
              </Typography>
            </Box>
            <Box mb={1}>
              <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                <Typography variant="body2">
                  {`Use the import feed with manual accounts by uploading a transaction file from
                  your financial institution. Transactions can also be created individually.`}
                </Typography>
              </Box>
            </Box>
            <Box maxWidth="400px" marginX="auto" mt={3} marginBottom={2}>
              <Button
                hasFullWidth
                variant="contained"
                onClick={() => {
                  tracking('add_manual_account clicked', { location: 'Onboarding - Manual Accounts: Intro' });
                  setAddAccountDialog(true);
                }}
              >
                Add A Manual Account
              </Button>
              <Box mt={1}>
                <MuiButton
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    tracking('no_manual_accounts clicked', { location: 'Onboarding - Manual Accounts: Intro' });
                    setState({ ...state, key: 'addLoanAccounts' });
                  }}
                >
                  No Manual Accounts
                </MuiButton>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'plaidSuccess':
        return (
          <DialogContent>
            <form onSubmit={handleSaveAndClose}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Success
                </Typography>
              </Box>
              <Typography variant="body1">
                {`Plaid successfully connected to ${state.plaidItem.institutionName} and is retrieving
                transactions from the following accounts. This may take several minutes. You can
                continue working while this process completes. When available, transactions will
                automatically populate to the import feed.`}
              </Typography>
              {state.addedAccounts.map((item, index) => (
                <Box key={item.plaidAccountId} mb={2}>
                  <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                    <Typography variant="h6" gutterBottom>
                      {`xxxx${item.mask}`}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Account Name"
                      InputProps={{
                        value: item.name,
                        name: `nested_addedAccounts_${index}_name`,
                        onChange: (_event, value) => {
                          const newAddedAccounts = state.addedAccounts;
                          newAddedAccounts[index].name = value;
                          setState({ ...state, addedAccounts: newAddedAccounts });
                        },
                      }}
                    />
                    <TransactionScope
                      journal={state.addedAccounts[index].journal}
                      transactionScopeChange={(values) => {
                        const newAddedAccounts = state.addedAccounts;
                        const newJournal = {
                          ...newAddedAccounts[index].journal,
                          ...values,
                        };
                        newAddedAccounts[index].journal = newJournal;
                        setState({ ...state, addedAccounts: newAddedAccounts });
                      }}
                      label="Account"
                    />
                  </Box>
                </Box>
              ))}
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                {!state.error && (
                  <Box marginBottom={2}>
                    <Typography variant="body1" color="error">
                      {state.error}
                    </Typography>
                  </Box>
                )}
                <Button hasFullWidth type="submit" variant="contained">
                  {state.error ? 'Retry' : 'Save and Close'}
                </Button>
              </Box>
            </form>
          </DialogContent>
        );
      case 'yodleeSuccess':
        return (
          <DialogContent>
            <form onSubmit={handleSaveAndClose}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Success
                </Typography>
              </Box>
              <Typography variant="body1">
                {`Yodlee successfully connected to ${state.yodleeProvider.providerName} and is retrieving
                transactions from the following accounts. This may take several minutes. You can
                continue working while this process completes. When available, transactions will
                automatically populate to the import feed.`}
              </Typography>
              {state.addedAccounts.map((item, index) => (
                <Box key={item.yodleeAccountId} mb={2}>
                  <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
                    <Typography variant="h6" gutterBottom>
                      {`xxxx${item.mask}`}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Account Name"
                      InputProps={{
                        value: item.name,
                        name: `nested_addedAccounts_${index}_name`,
                        onChange: (_event, value) => {
                          const newAddedAccounts = state.addedAccounts;
                          newAddedAccounts[index].name = value;
                          setState({ ...state, addedAccounts: newAddedAccounts });
                        },
                      }}
                    />
                    <TransactionScope
                      journal={state.addedAccounts[index].journal}
                      transactionScopeChange={(values) => {
                        const newAddedAccounts = state.addedAccounts;
                        newAddedAccounts[index].journal = values;
                        setState({ ...state, addedAccounts: newAddedAccounts });
                      }}
                      label="Account"
                    />
                  </Box>
                </Box>
              ))}
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                {!state.error && (
                  <Box marginBottom={2}>
                    <Typography variant="body1" color="error">
                      {state.error}
                    </Typography>
                  </Box>
                )}
                <Button hasFullWidth type="submit" variant="contained">
                  {state.error ? 'Retry' : 'Save and Close'}
                </Button>
              </Box>
            </form>
          </DialogContent>
        );
      case 'addAccountsSuccess':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" marginBottom={2} marginTop={2}>
              <CheckCircleIcon className={classes.successIcon} />
              <Box>
                <Typography variant="h6" gutterBottom>
                  {`You've added ${state.accountCount} ${pluralize('account', state.accountCount)}
                  to your portfolio.`}
                </Typography>
              </Box>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`You can now categorize your transactions by property as they are imported. If you
                linked an account, we pulled in everything possible since
                ${moment(bookkeepingStartDate).format('M/D/YYYY')}, but how far back we can go
                depends on the financial institution.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <Button
                hasFullWidth
                variant="contained"
                onClick={() => {
                  tracking('im_done clicked', { location: 'Onboarding - Accounts: Success' });
                  setState({ ...state, key: 'addLoanAccounts' });
                }}
              >
                I'm Done
              </Button>

              <Box mt={1}>
                <MuiButton
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={() => {
                    tracking('link_new_account clicked', { location: 'Onboarding - Accounts: Success' });
                    setState({ ...state, accountType: 'linked' });
                    // setAddAccountDialog(true);
                    setPlaidAddNew(true);
                  }}
                >
                  Link New Account
                </MuiButton>
              </Box>

              <Box mt={1}>
                <Button
                  hasFullWidth
                  variant="text"
                  onClick={() => {
                    tracking('import_manually clicked', { location: 'Onboarding - Accounts: Success' });
                    setState({ ...state, accountType: 'manual' });
                    setAddAccountDialog(true);
                  }}
                >
                  Add Manually
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'addLoanAccounts':
        return (
          <DialogContent className={classes.addLoansCard}>
            <Typography variant="h6" gutterBottom>
              Loan payments aren't expenses. Categorize them properly with REI Hub.
            </Typography>
            <Typography variant="body1">
              We automatically split your loan payments into principal, interest, and escrow (if applicable).
            </Typography>
            <Box className={classes.addLoansButtonsContainer}>
              <Button
                hasFullWidth
                variant="contained"
                onClick={() => {
                  tracking('add_loan clicked', { location: 'Onboarding - Loans: Intro' });
                  setAddLoanAccountDialog(true);
                }}
                className={classes.addLoansButton}
              >
                Add Loan
              </Button>
              <MuiButton
                className={classes.addLoansButton}
                color="primary"
                variant="text"
                size="large"
                onClick={() => {
                  tracking('i_dont_have_loans clicked', { location: 'Onboarding - Loans: Intro' });
                  history.replace('/onboarding-complete');
                  setState({ ...state, key: 'setupComplete' });
                }}
              >
                I Don't Have Loans
              </MuiButton>
            </Box>
          </DialogContent>
        );
      case 'addAnotherLoanAccount':
        return (
          <DialogContent className={classes.addAnotherLoanAccountCard}>
            <CheckCircleIcon className={classes.addAnotherLoanAccountCardIcon} color="secondary" />

            <Typography align="center" variant="h6">
              {`You've added ${pluralize('loan', state.loanAccountCount, true)} to your portfolio.`}
            </Typography>
            <Typography align="center" variant="body1">
              You can now automatically categorize your loan payments and accurately track your equity value.
            </Typography>

            <Box className={classes.addAnotherLoanAccountCardButtonsContainer}>
              <Button
                hasFullWidth
                variant="contained"
                onClick={() => {
                  tracking('finished clicked', { location: 'Onboarding - Loans: Success' });
                  setState({ ...state, key: 'setupComplete' });
                  history.replace('/onboarding-complete');
                }}
                className={classes.addAnotherLoanAccountCardButton}
              >
                Finished
              </Button>
              <MuiButton
                className={classes.addAnotherLoanAccountCardButton}
                color="primary"
                variant="outlined"
                size="large"
                onClick={() => {
                  tracking('add_loan_account clicked', { location: 'Onboarding - Loans: Intro' });
                  setAddLoanAccountDialog(true);
                }}
              >
                Add Another Loan
              </MuiButton>
            </Box>
          </DialogContent>
        );
      case 'setupComplete':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <img
                alt="A happy user on a laptop"
                src="/onboarding-success.webp"
                className={classes.setupCompleteImage}
              />
              <Typography variant={isSmallScreen ? 'h6' : 'h5'}>Your portfolio setup is complete!</Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`Now we'll guide you around REI Hub, the tool that'll make tracking your rental property finances
                easier than ever before.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" mt={3} marginBottom={2}>
              <Button
                hasFullWidth
                variant="contained"
                onClick={() => {
                  tracking('sounds_good clicked', { location: 'Onboarding - Complete' });
                  finishOnboarding();
                }}
              >
                Sounds good
              </Button>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  const plaidExitDialog = () => (
    <Dialog
      open={plaidExitDialogVisible}
      onClose={(_event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setPlaidExitDialogVisible(false);
        }
      }}
      onLoad={() => {
        tracking('not_finding_account_modal loaded', {
          location: 'Onboarding - Linked Account: Not Finding Account Modal',
        });
      }}
      maxWidth="sm"
      disableEscapeKeyDown
      id="plaid-exit-dialog"
      aria-describedby="plaid-exit-dialog"
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ClearIcon
            className={classes.closeIcon}
            onClick={() => {
              tracking('close clicked', { location: 'Onboarding - Linked Account: Not Finding Account Modal' });
              setPlaidExitDialogVisible(false);
              setState({
                ...state,
                key: state.accountCount === 0 ? 'accountsOverview' : 'addAccountsSuccess',
              });
            }}
          />
        </Box>
        <Box
          style={{
            backgroundColor: 'white',
            marginRight: 25,
            marginLeft: 25,
          }}
        >
          <DialogContent>
            <Box marginX="auto" marginBottom={2} textAlign="left">
              <Typography variant="h6" gutterBottom>
                Not finding the account you're looking for?
              </Typography>
              <Box marginBottom={1} textAlign="left">
                <Typography variant="body1">
                  We can try with our alternative data provider Yodlee to link to your account. Or you could import the
                  account information manually.
                </Typography>
              </Box>
              <Box maxWidth="400px" marginX="auto" textAlign="center" marginBottom={1} marginTop={5}>
                <Button
                  hasFullWidth
                  variant="contained"
                  onClick={() => {
                    tracking('try_linking_via_yodlee clicked', {
                      location: 'Onboarding - Linked Account: Not Finding Account Modal',
                    });
                    setPlaidExitDialogVisible(false);
                    setYodleeAddLink(true);
                  }}
                >
                  Try Linking Via Yodlee
                </Button>
                <Box marginTop={1}>
                  <MuiButton
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => {
                      tracking('import_manually clicked', {
                        location: 'Onboarding - Linked Account: Not Finding Account Modal',
                      });
                      setState({ ...state, accountType: 'manual' });
                      setPlaidExitDialogVisible(false);
                      setAddAccountDialog(true);
                    }}
                  >
                    Add Manually
                  </MuiButton>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </>
    </Dialog>
  );

  const onPlaidSuccess = (plaidItem) => {
    const addedAccounts = [];
    plaidItem.accounts.forEach((account) => {
      addedAccounts.push({
        plaidAccountId: account.id,
        mask: account.mask,
        name: account.name,
        journal: {
          entityId: null,
          propertyId: null,
          unitId: null,
          journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
        },
      });
    });
    setPlaidAddNew(false);
    setState({
      plaidItem,
      key: 'plaidSuccess',
      addedAccounts,
      accountCount: state.accountCount + addedAccounts.length,
    });
  };

  const onYodleeSuccess = async (yodleeProvider) => {
    const { providerAccountId } = yodleeProvider;
    const query = {
      organizationId,
      providerAccountId,
    };
    const yodleeAccounts = await find(this, 'yodlee-accounts', { query });

    const addedAccounts = [];
    yodleeAccounts.data.forEach((account) => {
      addedAccounts.push({
        yodleeAccountId: account.accountId,
        mask: account.mask,
        name: account.name,
        journal: {
          entityId: null,
          propertyId: null,
          unitId: null,
          journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
        },
      });
    });
    setState({
      yodleeProvider,
      key: 'yodleeSuccess',
      addedAccounts,
      accountCount: state.accountCount + addedAccounts.length,
    });
  };

  if (loading) {
    return null;
  }

  if (addAccountDialog) {
    return (
      <AddBankingAccountDialog
        isOpen
        closeDialog={() => {
          setState({ ...state, accountType: null });
          setAddAccountDialog(false);
        }}
        onAddAccount={addAccount}
        accountType={state.accountType}
        allAccounts={allAccounts}
      />
    );
  }

  if (plaidExitDialogVisible) {
    return plaidExitDialog();
  }

  if (plaidAddNew) {
    return (
      <PlaidLinkAdd
        onSuccess={onPlaidSuccess}
        closeDialog={() => {
          setPlaidAddNew(false);
          setPlaidExitDialogVisible(true);
        }}
        importStartDate={bookkeepingStartDate}
      />
    );
  }

  if (yodleeAddLink) {
    return (
      <YodleeLinkAdd
        onSuccess={onYodleeSuccess}
        closeDialog={() => {
          setYodleeAddLink(false);
        }}
        importStartDate={bookkeepingStartDate}
      />
    );
  }

  if (addLoanAccountDialog) {
    return <AddLoanDialog isOpen closeDialog={() => setAddLoanAccountDialog(false)} onAddAccount={addLoanAccount} />;
  }

  return (
    <>
      <SkipForNowAccountsDialog
        isOpen={skipForNowDialogVisible}
        closeDialog={() => setSkipForNowDialogVisible(false)}
        onConfirm={() => setState({ ...state, accountType: null, key: 'addLoanAccounts' })}
      />
      <VideoPlayerDialog
        open={videoDialogOpen}
        onClose={() => {
          setVideoDialogOpen(false);
        }}
        source="https://www.youtube.com/embed/914EJSM_w-g?rel=0&amp;wmode=opaque"
        allowFullScreen={false}
      />
      <Card open={isOpen} scroll="body" className={classes.onboardingCard}>
        {getDialogContent(state.key)}
      </Card>
    </>
  );
}

ViewWelcomeAccountsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAddAccount: PropTypes.func.isRequired,
  allAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
