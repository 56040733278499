import React from 'react';
import { Box, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import EmptyState from '~/components/EmptyState';
import PageGrid from '~/components/PageGrid';

import AddBankingAccountDialog from '../components/AddBankingAccountDialog';
import FilterAccountsDialog from '../components/FilterAccountsDialog';
import PageHeader from '../components/PageHeader';
import PlaidLinkUpdate from '../components/Plaid/PlaidLinkUpdate';
import ViewWelcomeAccountsDialog from '../components/ViewWelcomeAccountsDialog';
import YodleeLinkUpdate from '../components/Yodlee/YodleeLinkUpdate';
import { PersonContext } from '../contexts/PersonContext';
import { create, find } from '../feathersWrapper';
import { asyncHandleChange } from '../functions/InputHandlers';
import { filterForProperty } from '../functions/SumFunctions';

import BankAccountDisplayCard from './cards/BankAccountDisplayCard';

const BANKING_EMPTY_STATE_SEGMENT_LOCATION = 'Banking Empty State';

const styles = (theme) => ({
  headerContainer: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  grid: {
    width: '100%',
    margin: 0,
  },
});

class Banking extends React.PureComponent {
  timeout = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addAccount: false,
      filterAccountsDialog: false,
      yodleeUpdateAccount: null,
      plaidUpdateItem: null,
      filter: {
        showInactive: false,
        institutionName: null,
        accountMask: null,
      },
      accountType: '',
    };
  }

  async componentDidMount() {
    const { showWelcome } = this.context;
    this.setState({ showWelcome });

    await this.getAccounts();

    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.add) {
      this.setState({ addAccount: true, accountType: parsedQuery.add });
    }
  }

  getFilterValues = (key, accounts) => {
    const valueArray = [];
    accounts.forEach((account) => {
      if (account[key] && !valueArray.includes(account[key])) {
        valueArray.push(account[key]);
      }
    });
    return valueArray.sort();
  };

  // clear timeout if necessary
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  getAccounts = async () => {
    this.setState({ loading: true });

    const { organizationId } = this.context;
    const { filter } = this.state;

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    const unmatchedTransactionsPromise = create(this, 'reports', {
      organizationId,
      reportName: 'countUnmatchedTransactions',
    });

    const baseQuery = {
      organizationId,
      includePlaidAccounts: true,
      includeYodleeAccounts: true,
      includeProperty: true,
      includeUnit: true,
      includeEntity: true,
      inactive: filter.showInactive ? true : { $or: [null, false] },
      $limit: 100,
      $sort: { name: 1 },
    };

    const bankAccountsQuery = {
      ...baseQuery,
      type: 'Asset',
      type2: 'Bank',
    };

    const creditCardsQuery = {
      ...baseQuery,
      type: 'Liability',
      type2: 'Credit Card',
    };

    const bankAccountsPromise = find(this, 'accounts', { query: bankAccountsQuery });

    const creditCardsPromise = find(this, 'accounts', { query: creditCardsQuery });

    const allAccountsCountPromise = find(this, 'accounts', {
      query: {
        organizationId,
        type: { $in: ['Asset', 'Liability'] },
        type2: { $in: ['Bank', 'Credit Card'] },
        $limit: 0,
      },
    });

    const [unmatchedTransactions, bankAccounts, creditCards, allAccountsCount] = await Promise.all([
      unmatchedTransactionsPromise,
      bankAccountsPromise,
      creditCardsPromise,
      allAccountsCountPromise,
    ]);

    let awaitingData = false;
    bankAccounts.data.forEach((account) => {
      account.unmatchedTransactionCount = filterForProperty(
        unmatchedTransactions,
        'accountId',
        account.id,
        'unmatchedTransactionCount',
        0,
      );
      if (account.lastCurrentBalance === null && account.plaid_account) {
        awaitingData = true;
      }
    });
    creditCards.data.forEach((account) => {
      account.unmatchedTransactionCount = filterForProperty(
        unmatchedTransactions,
        'accountId',
        account.id,
        'unmatchedTransactionCount',
        0,
      );
      if (account.lastCurrentBalance === null && account.plaid_account) {
        awaitingData = true;
      }
    });

    // refresh in 30 seconds if plaid data is not yet received
    if (awaitingData) {
      this.timeout = setTimeout(this.getAccounts, 30000);
    }

    let displayConnectionsButton = false;
    const accounts = bankAccounts.data.concat(creditCards.data);
    if (accounts.length > 0) {
      if (accounts.find((account) => account.plaidItemId !== null)) {
        displayConnectionsButton = true;
      }
    }

    let bankAccountsOptions = bankAccounts.data;
    let creditCardsOptions = creditCards.data;

    if (filter.institutionName) {
      bankAccountsOptions = bankAccountsOptions.filter((account) => account.institutionName === filter.institutionName);
      creditCardsOptions = creditCardsOptions.filter((account) => account.institutionName === filter.institutionName);
    }
    if (filter.accountMask) {
      bankAccountsOptions = bankAccountsOptions.filter((account) => account.accountNumberMask === filter.accountMask);
      creditCardsOptions = creditCardsOptions.filter((account) => account.accountNumberMask === filter.accountMask);
    }

    let filteredToZero;
    if (filter.institutionName || filter.accountMask || filter.showInactive) {
      filteredToZero = bankAccountsOptions.length === 0 && creditCardsOptions.length === 0;
    }

    const institutionNames = this.getFilterValues('institutionName', bankAccounts.data.concat(creditCards.data));
    const accountMasks = this.getFilterValues('accountNumberMask', bankAccounts.data.concat(creditCards.data));

    this.setState({
      bankAccounts: bankAccountsOptions,
      creditCards: creditCardsOptions,
      displayConnectionsButton,
      hasInactiveAccounts: allAccountsCount.total > 0 && bankAccounts.total === 0 && creditCards.total === 0,
      loading: false,
      institutionNames,
      accountMasks,
      filteredToZero,
    });
  };

  updateYodleeAccount = async (providerAccountId) => {
    const { submitting } = this.state;
    if (submitting) {
      return;
    }

    await asyncHandleChange('yodleeUpdateAccount', providerAccountId, this);
  };

  updatePlaidItem = async (plaidItemId) => {
    const { submitting } = this.state;
    if (submitting) {
      return;
    }

    await asyncHandleChange('plaidUpdateItem', plaidItemId, this);
  };

  updateAccountsFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getAccounts();
  };

  closeAccountDialogs = () => {
    this.setState({
      addAccount: false,
    });
  };

  onYodleeClose = () => {
    this.setState({
      yodleeUpdateAccount: null,
    });
  };

  onYodleeUpdateSuccess = async () => {
    const { organizationId } = this.context;

    await create(this, 'yodlee-accounts', {
      organizationId,
    });
    await this.getAccounts();
    this.onYodleeClose();

    setTimeout(async () => {
      await this.getAccounts();
    }, 30000);
  };

  onPlaidClose = () => {
    this.setState({
      plaidUpdateItem: null,
    });
  };

  onPlaidUpdateSuccess = async () => {
    const { organizationId } = this.context;
    this.onPlaidClose();
    setTimeout(async () => {
      await create(this, 'plaid-item-status', { organizationId });
      await this.getAccounts();
    }, 4000);

    setTimeout(async () => {
      await this.getAccounts();
    }, 30000);
  };

  onEditAccount = async () => {
    await this.getAccounts();
  };

  onUpdatePlaidItem = async (plaidItemId) => {
    await this.updatePlaidItem(plaidItemId);
  };

  onUpdateYodleeAccount = async (yodleeItemId) => {
    await this.updateYodleeAccount(yodleeItemId);
  };

  actionButtons = () => {
    const { match } = this.props;
    const { bankAccounts, creditCards, displayConnectionsButton, filter, hasInactiveAccounts } = this.state;

    const actionButtons = [
      { text: 'Filter', action: () => this.setState({ filterAccountsDialog: true }), class: 'filter' },
      { text: 'Add Account', action: () => this.setState({ addAccount: true }), class: 'add' },
    ];

    if (bankAccounts?.length === 0 && creditCards?.length === 0 && !filter.showInactive) {
      if (hasInactiveAccounts) {
        return actionButtons;
      }
      return [];
    }

    if (displayConnectionsButton) {
      actionButtons.push({ text: 'Connections', link: `${match.url}/connections`, class: 'edit' });
    }

    return actionButtons;
  };

  render() {
    const { classes, match } = this.props;
    const {
      bankAccounts,
      creditCards,
      showWelcome,
      addAccount,
      yodleeUpdateAccount,
      plaidUpdateItem,
      filter,
      loading,
      filterAccountsDialog,
      accountType,
      institutionNames,
      accountMasks,
      filteredToZero,
    } = this.state;
    const { demo, adminLogin } = this.context;

    if (showWelcome) {
      return (
        <ViewWelcomeAccountsDialog
          isOpen
          onAddAccount={this.getAccounts}
          closeDialog={() => this.setState({ showWelcome: false })}
          allAccounts={bankAccounts?.concat(creditCards)}
        />
      );
    }

    const hasNoAccounts = bankAccounts?.length === 0 && creditCards?.length === 0 && !filteredToZero;

    return (
      <>
        {showWelcome ? null : (
          <Box className={classes.headerContainer}>
            <PageHeader match={match} title="Banking" actionButtons={this.actionButtons()} />
          </Box>
        )}
        <PageGrid isMultiCard>
          {!demo && yodleeUpdateAccount && (
            <YodleeLinkUpdate
              onSuccess={this.onYodleeUpdateSuccess}
              closeDialog={this.onYodleeClose}
              yodleeProviderAccountId={yodleeUpdateAccount}
            />
          )}
          {!demo && plaidUpdateItem && (
            <PlaidLinkUpdate
              onSuccess={this.onPlaidUpdateSuccess}
              closeDialog={this.onPlaidClose}
              plaidItemId={plaidUpdateItem}
            />
          )}
          {filterAccountsDialog && (
            <FilterAccountsDialog
              filter={filter}
              closeDialog={() => this.setState({ filterAccountsDialog: false })}
              updateFilter={this.updateAccountsFilter}
              institutionNames={institutionNames}
              accountMasks={accountMasks}
            />
          )}
          <AddBankingAccountDialog
            isOpen={addAccount}
            closeDialog={this.closeAccountDialogs}
            onAddAccount={this.getAccounts}
            allAccounts={bankAccounts?.concat(creditCards)}
            accountType={accountType}
          />

          {/* We should not render null with `loading` before `AddBankingAccountDialog` is rendered
          because its state should not unmount when we load accounts after creating an account.
        */}
          {!loading && hasNoAccounts && (
            <EmptyState
              image="/rei_hub_banking_empty_state.svg"
              title="Save time and energy by linking your accounts"
              body={
                <Typography variant="body1" align="center">
                  {`Financial transactions are securely and automatically added for easy and accurate
                  categorization, eliminating manual data entry. `}
                  <Box component="span" fontWeight="bold">
                    Over 80% of REI Hub customers link at least one account.
                  </Box>
                </Typography>
              }
              buttonProps={[
                {
                  color: 'purple',
                  variant: 'contained',
                  text: 'Link Account',
                  segmentProps: { event: 'link_account clicked', location: BANKING_EMPTY_STATE_SEGMENT_LOCATION },
                  onClickFunction: () => {
                    this.setState({ addAccount: true, accountType: 'linked' });
                  },
                },
                {
                  color: 'secondary',
                  variant: 'outlined',
                  text: 'Add Manually',
                  segmentProps: { event: 'add_manually clicked', location: BANKING_EMPTY_STATE_SEGMENT_LOCATION },
                  onClickFunction: () => {
                    this.setState({ addAccount: true, accountType: 'manual' });
                  },
                },
              ]}
              videoProps={{
                source: 'https://www.youtube.com/embed/914EJSM_w-g?si=Q5dpIRDKnk9xmmqo',
                videoSegmentProps: {
                  event: 'watch_overview_video clicked',
                  location: BANKING_EMPTY_STATE_SEGMENT_LOCATION,
                },
              }}
            />
          )}

          {!loading && filteredToZero && (
            <EmptyState
              icon="/icons/user_action_empty_state_icon.svg"
              title="No banking accounts were found"
              body={
                <Typography variant="body1" align="center">
                  Try adjusting your filter options to find what you are looking for or add a new account below.
                </Typography>
              }
              buttonProps={[
                {
                  color: 'purple',
                  variant: 'contained',
                  text: 'Clear Filters',
                  onClickFunction: () => this.updateAccountsFilter({ showInactive: false }),
                },
                {
                  color: 'purple',
                  variant: 'outlined',
                  text: 'Link New Account',
                  segmentProps: { event: 'link_account clicked', location: BANKING_EMPTY_STATE_SEGMENT_LOCATION },
                  onClickFunction: () => {
                    this.setState({ addAccount: true, accountType: 'linked' });
                  },
                },
                {
                  color: 'secondary',
                  text: 'Add Manually',
                  segmentProps: { event: 'add_manually clicked', location: BANKING_EMPTY_STATE_SEGMENT_LOCATION },
                  onClickFunction: () => {
                    this.setState({ addAccount: true, accountType: 'manual' });
                  },
                },
              ]}
            />
          )}

          {!loading && (bankAccounts?.length !== 0 || creditCards?.length !== 0) && (
            <>
              {bankAccounts.map((account) => (
                <BankAccountDisplayCard
                  key={account.id}
                  account={account}
                  onEditAccount={this.onEditAccount}
                  onUpdatePlaidItem={this.onUpdatePlaidItem}
                  onUpdateYodleeAccount={this.onUpdateYodleeAccount}
                  adminLogin={adminLogin}
                />
              ))}
              {creditCards.map((account) => (
                <BankAccountDisplayCard
                  key={account.id}
                  account={account}
                  onEditAccount={this.onEditAccount}
                  onUpdatePlaidItem={this.onUpdatePlaidItem}
                  onUpdateYodleeAccount={this.onUpdateYodleeAccount}
                  adminLogin={adminLogin}
                />
              ))}
            </>
          )}
        </PageGrid>
      </>
    );
  }
}

Banking.contextType = PersonContext;

Banking.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Banking);
