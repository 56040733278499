import React from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';

import AutocompleteField, { autocompleteFieldProps } from '~/components/TransactionFields/AutocompleteField';

const useStyles = makeStyles((theme) => ({
  autocompleteField: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(25% - 8px)',
    },
  },
}));

export default function QuickBookRowAutocomplete({ className, ...rest }) {
  const classes = useStyles();

  return <AutocompleteField className={cx(classes.autocompleteField, className)} {...rest} />;
}

QuickBookRowAutocomplete.propTypes = autocompleteFieldProps;
