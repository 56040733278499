import React, { forwardRef } from 'react';
import MuiChip from '@material-ui/core/Chip';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useStyles, { COLORS, SIZES, VARIANTS } from './styles';

const Chip = forwardRef(
  (
    {
      className,
      color = COLORS.ERROR,
      disabled,
      label,
      size = SIZES.MEDIUM,
      thumbnail,
      variant = VARIANTS.FILLED,
      onDelete,
      onClick,
    },
    ref,
  ) => {
    const classes = useStyles({ color, size, variant, onClick, onDelete });

    return (
      <MuiChip
        avatar={thumbnail}
        className={cx(classes.chip, className)}
        disabled={disabled}
        label={label}
        ref={ref}
        size={size}
        onDelete={onDelete}
        onClick={onClick}
      />
    );
  },
);

Chip.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(COLORS)),
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  thumbnail: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

export default Chip;
