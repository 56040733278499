import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { PersonContext } from '~/contexts/PersonContext';
import {
  getPropertyOptions,
  getSubportfolioOptions,
  getUnitOptions,
} from '~/helpers/utils/functionalAutocompleteLibrary';

export default function useScopeFields({ setJournal }) {
  const [isLoading, setIsLoading] = useState(true);
  const [subportfolioOptions, setSubportfolioOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);

  const { multiEntity, organizationId, organizationName } = useContext(PersonContext);

  useEffect(() => {
    async function loadData() {
      setIsLoading(true);

      const [subportfolioOptions, propertyOptions, unitOptions] = await Promise.allSettled([
        getSubportfolioOptions(organizationId),
        getPropertyOptions(organizationId),
        getUnitOptions(organizationId),
      ]);
      setSubportfolioOptions(subportfolioOptions.value);
      setPropertyOptions(propertyOptions.value);
      setUnitOptions(unitOptions.value);

      setIsLoading(false);
    }

    loadData();
  }, [organizationId]);

  useEffect(() => {
    if (propertyOptions.length === 1) {
      setJournal((prevJournal) => ({ ...prevJournal, propertyIdSelect: propertyOptions[0] }));
    }
  }, [propertyOptions, setJournal]);

  const scopeOptions = useMemo(() => {
    return [
      { name: `${organizationName} Portfolio`, id: 'Portfolio' },
      ...(multiEntity ? [{ name: 'Sub-Portfolio', id: 'Sub-Portfolio' }] : []),
      { name: 'Property/Unit', id: 'Property/Unit' },
    ];
  }, [multiEntity, organizationName]);

  const handleScopeChange = useCallback(
    (value) => {
      setJournal((prevJournal) => {
        if (value.id === prevJournal.journalScopeSelect?.id) {
          return prevJournal;
        }
        return { ...prevJournal, journalScopeSelect: value };
      });
    },
    [setJournal],
  );

  const handleSubportfolioChange = useCallback(
    (value) => {
      setJournal((prevJournal) => {
        if (value.id === prevJournal.entityIdSelect?.id) {
          return prevJournal;
        }
        return { ...prevJournal, entityIdSelect: value, propertyIdSelect: null, unitIdSelect: null };
      });
    },
    [setJournal],
  );

  const handlePropertyChange = useCallback(
    (value) => {
      setJournal((prevJournal) => {
        if (value.id === prevJournal.propertyIdSelect?.id) {
          return prevJournal;
        }
        return { ...prevJournal, propertyIdSelect: value, unitIdSelect: null, entityIdSelect: null };
      });
    },
    [setJournal],
  );

  const handleUnitChange = useCallback(
    (value) => {
      setJournal((prevJournal) => {
        if (value.id === prevJournal.unitIdSelect?.id) {
          return prevJournal;
        }
        return { ...prevJournal, unitIdSelect: value };
      });
    },
    [setJournal],
  );

  return {
    isLoading,
    subportfolioOptions,
    propertyOptions,
    unitOptions,
    scopeOptions,
    handleScopeChange,
    handleSubportfolioChange,
    handlePropertyChange,
    handleUnitChange,
  };
}
