import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import QuickBookRowActions from '~/components/QuickBookRow/QuickBookRowActions';
import QuickBookRowAutocomplete from '~/components/QuickBookRow/QuickBookRowAutocomplete';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import { setUpJournalForSubmit } from '~/components/QuickBookRow/transactionTemplates/helpers/journalFunctions';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { getAccountOptions } from '~/helpers/utils/functionalAutocompleteLibrary';

export default function QuickBookRowOwnerDistributionTemplate({ transaction, onTransactionAction, onTemplateLoad }) {
  const classes = useStyles();
  const isMobile = checkIsSmallScreen();
  const context = useContext(PersonContext);
  const { organizationId } = context;
  const [equityAccountOptions, setEquityAccountOptions] = useState([]);
  const [journal, setJournal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    const getData = async () => {
      const equityAccounts = await getAccountOptions(organizationId, {
        type: 'Equity',
        default: { $or: [null, { $ne: 'autoBalance' }] },
      });
      setEquityAccountOptions(equityAccounts);

      const journalObject = getManualBasicJournal();
      const { account, id, description, date, amount } = transaction;
      journalObject.creditLines[0].accountIdSelect = account;
      journalObject.creditLines[0].transactionId = id;
      journalObject.description = description;
      journalObject.date = date;
      journalObject.amount = amount;
      journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
      setJournal(journalObject);
      setIsLoading(false);
      onTemplateLoad();
    };
    getData();
  }, [onTemplateLoad, organizationId, transaction, transaction.id, isLoading]);

  const validateDistribution = () => {
    if (!journal.date) {
      setError({ message: 'Please enter a valid date for this transaction' });
      return false;
    }

    if (!journal.amount) {
      setError({ message: 'Please enter a non-zero amount for this transaction' });
      return false;
    }

    setError(null);
    return true;
  };

  const bookDistribution = async (event) => {
    event.preventDefault();

    const isValid = validateDistribution();

    if (isSubmitting || !isValid) {
      return;
    }

    setIsSubmitting(true);

    const journalData = {
      journal,
      organizationId,
      direction: 'cash out',
      transactionId: transaction.id,
      type: 'Distribution',
      bookAsRefund: false,
    };
    const journalSubmit = setUpJournalForSubmit(journalData);

    try {
      create('journals', journalSubmit);
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    onTransactionAction();
  };

  if (isLoading) {
    return null;
  }

  return (
    <form onSubmit={bookDistribution}>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          <QuickBookRowAutocomplete
            options={equityAccountOptions}
            onChange={(value) => {
              setJournal({ ...journal, debitLines: [{ ...journal.debitLines[0], accountIdSelect: value }] });
            }}
            label="Equity Account"
            value={journal.debitLines[0].accountIdSelect}
            required
          />
          <TextField
            className={isMobile ? classes.mobileDescriptionField : classes.descriptionField}
            label="Description"
            placeholder="Description"
            variant="outlined"
            size="small"
            value={journal.description}
            onChange={(event) => {
              setJournal({ ...journal, description: event.target.value });
            }}
          />
        </Box>
        <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
        <QuickBookRowNotesAndAttachment
          journal={journal}
          onChange={(newJournal) => {
            setJournal(newJournal);
          }}
        />
        {error && error.message ? (
          <Alert severity="error" className={classes.errorAlert}>
            {error.message}
          </Alert>
        ) : null}
        <Box className={classes.actionsContainer}>
          <QuickBookRowActions
            journal={journal}
            setJournal={setJournal}
            transaction={transaction}
            onTransactionAction={onTransactionAction}
          />
          <Box className={classes.rowActionsSecondaryContainer}>
            <Button variant="contained" className={classes.saveButton} size="small" fullWidth={false} type="submit">
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
}

QuickBookRowOwnerDistributionTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  onTemplateLoad: PropTypes.func.isRequired,
};
