import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Card,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { CheckCircle, RadioButtonUnchecked, Replay } from '@material-ui/icons';
import Lottie from 'lottie-react';

import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';

import AddBankingAccountDialog from '../components/AddBankingAccountDialog';
import AddLoanDialog from '../components/AddLoanDialog';
import AddPropertyDialog from '../components/AddPropertyDialog';
import Button from '../components/Button';
import SubscribeDialog from '../components/SubscribeDialog';
import { GET_STARTED_CHECKLIST_ITEM_STATUS, GET_STARTED_CHECKLIST_ITEMS } from '../constants/get-started';
import { PersonContext } from '../contexts/PersonContext';
import { find, patch } from '../feathersFunctionalWrapper';
import { useSegmentTrack } from '../functions/SegmentFunctions';
import success from '../lottie/success.json';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#B5A4BE',
  },
  bar: {
    borderStartRadius: '5px',
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
  headerContainer: {
    marginBottom: theme.spacing(2),
  },
  contentCard: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: '4px',
    paddingBottom: 0,
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  progressBar: {
    width: '100%',
  },
  progressPercentage: {
    marginLeft: '3px',
  },
  checklistRoot: {
    border: '1px solid #B3B6B9',
    borderRadius: '4px',
  },
  checklistItemContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
    borderBottom: '1px solid #B3B6B9',
  },
  checklistItemContainerLastRow: {
    padding: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
  },
  checklistItemComplete: {
    backgroundColor: '#EBEBEB',
  },
  incompleteChecklistItemIconContainer: {
    marginTop: '2px',
  },
  incompleteChecklistItemIcon: {
    color: '#707070',
  },
  completeChecklistItemIconContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  completeChecklistItemIcon: {
    color: '#2E7D32',
  },
  checklistItemTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(0.5),
    maxWidth: '943px',
    width: '100%',
  },
  checklistItemTitleAndTimeContainer: {
    display: 'flex',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  },
  timeTextContainer: {
    color: '#666666',
  },
  completedOrSkippedText: {
    color: '#5E5E5E',
    textDecoration: 'line-through',
    textDecorationThickness: '1px',
  },
  skippedChip: {
    backgroundColor: '#D9D9D9',
    height: '23px',
    color: theme.palette.text.primary,
  },
  checklistItemActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      alignSelf: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  checklistItemActionsButton: {
    justifyContent: 'center',
    width: '120px',
  },
  checklistItemSecondaryButtonContainer: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    color: '#707070',
  },
  buttonIcon: {
    width: '27.4px',
    height: '27.4px',
  },
  noIcon: {
    width: '51.4px',
    height: '51.4px',
  },
  completedDialogContentContainer: {
    maxWidth: '394px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    gap: theme.spacing(2.5),
    textAlign: 'center',
  },
  completedDialogAnimationContainer: {
    height: '120px',
    width: '120px',
  },
  completedDialogTextContainer: {
    gap: theme.spacing(0.5),
  },
  completedAnimationContainer: {
    alignSelf: 'center',
    width: '120px',
    height: '120px',
    margin: 'auto',
    alignContent: 'center',
  },
}));

export default function GetStarted() {
  const classes = useStyles();
  const context = useContext(PersonContext);
  const { organizationId, partnerSubscribed } = context;
  const history = useHistory();
  const tracking = useSegmentTrack();
  const isSmallScreen = checkIsSmallScreen();

  const SEGMENT_LOCATION = 'Get Started Checklist';

  const [loading, setLoading] = useState(true);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [checklistId, setChecklistId] = useState(null);
  const [checklistState, setChecklistState] = useState(GET_STARTED_CHECKLIST_ITEMS);

  const [isAddPropertyDialogOpen, setIsAddPropertyDialogOpen] = useState(false);
  const [isLinkAccountDialogOpen, setIsLinkAccountDialogOpen] = useState(false);
  const [isAddLoanDialogOpen, setIsAddLoanDialogOpen] = useState(false);
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const [hasLoanBeenAdded, setHasLoanBeenAdded] = useState(false);

  const getData = async () => {
    const checklistStatusesResponse = await find('get-started-checklist', { query: { organizationId } });
    const statuses = checklistStatusesResponse.data[0];
    const newChecklistState = [...checklistState];
    newChecklistState.forEach((item, index) => {
      const newItem = { ...item };
      switch (newItem.title) {
        case 'Add Your Property':
          if (statuses.addProperty === GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED;
          } else if (statuses.addProperty === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED;
          } else {
            newItem.status = null;
          }
          break;
        case 'Import Your Transactions':
          if (statuses.importTransaction === GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED;
          } else if (statuses.importTransaction === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED;
          } else {
            newItem.status = null;
          }
          break;
        case 'Track Your Loans':
          if (statuses.trackLoans === GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED;
          } else if (statuses.trackLoans === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED;
          } else {
            newItem.status = null;
          }
          break;
        case 'Book Your First Transaction':
          if (statuses.bookTransactions === GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED;
          } else if (statuses.bookTransactions === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED;
          } else {
            newItem.status = null;
          }
          break;
        case 'Subscribe to REI Hub':
          // If the user is subscribed through a partner, remove the subscribe step from the
          // checklist
          if (partnerSubscribed) {
            newChecklistState.splice(index, 1);
            break;
          }
          if (statuses.subscribe === GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED;
          } else if (statuses.subscribe === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED) {
            newItem.status = GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED;
          } else {
            newItem.status = null;
          }
          break;
        case 'Create Your Portfolio':
        default:
          break;
      }
      const itemIndex = newChecklistState.indexOf(item);
      newChecklistState[itemIndex] = newItem;
    });

    setChecklistState(newChecklistState);
    setChecklistId(checklistStatusesResponse.data[0].id);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const finishedItems = checklistState.filter((item) => item.status).length;
    const totalItems = checklistState.length;
    setProgressPercentage(Math.round((finishedItems / totalItems) * 100));

    if (finishedItems === totalItems) {
      setIsCompleted(true);
    }
  }, [checklistState]);

  const handleSkipAction = async (item, skipped) => {
    await patch('get-started-checklist', checklistId, {
      [item.column]: skipped ? GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED : null,
    });
    const newChecklistState = [...checklistState];
    const newItem = { ...item };
    newItem.status = skipped ? GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED : null;
    const itemIndex = newChecklistState.indexOf(item);
    newChecklistState[itemIndex] = newItem;
    setChecklistState(newChecklistState);
  };

  const getRowClasses = (item, index) => {
    const classNames = [];
    if (index === checklistState.length - 1) {
      classNames.push(classes.checklistItemContainerLastRow);
    } else {
      classNames.push(classes.checklistItemContainer);
    }

    if (item.status) {
      classNames.push(classes.checklistItemComplete);
    }
    return classNames.join(' ');
  };

  const getRowHeaderSecondaryText = (item) => {
    if (item.status === GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED || !item.completionTime) {
      return null;
    }
    if (item.status === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED) {
      return isSmallScreen ? null : <Chip label="Skipped" className={classes.skippedChip} />;
    }
    return (
      <Box className={classes.timeTextContainer}>
        <Typography variant="body2">{item.completionTime}</Typography>
      </Box>
    );
  };

  const getRowIcon = (item) => {
    if (item.status === GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED) {
      return <CheckCircle className={classes.completeChecklistItemIcon} />;
    }
    if (item.status === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED) {
      return <CheckCircle className={classes.incompleteChecklistItemIcon} />;
    }
    return <RadioButtonUnchecked className={classes.incompleteChecklistItemIcon} />;
  };

  const getSecondaryActionButton = (item) => {
    let segmentEventName = 'skip_';
    switch (item.title) {
      case 'Add Your Property':
        segmentEventName += 'add_property';
        break;
      case 'Import Your Transactions':
        segmentEventName += 'import_transactions';
        break;
      case 'Track Your Loans':
        segmentEventName += 'track_loans';
        break;
      case 'Book Your First Transaction':
        segmentEventName += 'book_transaction';
        break;
      case 'Subscribe to REI Hub':
        segmentEventName += 'subscribe';
        break;
      default:
        break;
    }
    segmentEventName += ' clicked';
    if (item.status === GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED) {
      return <Box className={classes.noIcon} />;
    }
    if (item.status === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED) {
      return (
        <Tooltip title="Undo Skip" placement="top" arrow>
          <IconButton
            onClick={() => {
              handleSkipAction(item, false);
            }}
          >
            <Replay className={classes.buttonIcon} />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Skip" placement="top" arrow>
        <IconButton
          onClick={() => {
            tracking(segmentEventName, { location: SEGMENT_LOCATION });
            handleSkipAction(item, true);
          }}
        >
          <img src="/icons/playlist_remove.svg" alt="skip" className={classes.buttonIcon} />
        </IconButton>
      </Tooltip>
    );
  };

  const getPrimaryButtonAction = (item) => {
    switch (item.title) {
      case 'Add Your Property':
        tracking('add(property) clicked', { location: SEGMENT_LOCATION });
        setIsAddPropertyDialogOpen(true);
        break;
      case 'Import Your Transactions':
        tracking('link clicked', { location: SEGMENT_LOCATION });
        setIsLinkAccountDialogOpen(true);
        break;
      case 'Track Your Loans':
        tracking('add(loan) clicked', { location: SEGMENT_LOCATION });
        setIsAddLoanDialogOpen(true);
        break;
      case 'Book Your First Transaction':
        tracking('book clicked', { location: SEGMENT_LOCATION });
        history.push('/transactions/imported');
        break;
      case 'Subscribe to REI Hub':
        tracking('subscribe clicked', { location: SEGMENT_LOCATION });
        setIsSubscribeDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const completeChecklist = async () => {
    await patch('get-started-checklist', checklistId, { completedAt: new Date() });
    tracking('go_to_dashboard clicked', { location: SEGMENT_LOCATION });
    history.push('/dashboard');
  };

  if (loading) {
    return null;
  }

  return (
    <>
      {isCompleted ? (
        <Dialog open scroll="body">
          <DialogContent className={classes.completedDialogContentContainer}>
            <Box className={classes.completedAnimationContainer}>
              <Lottie animationData={success} loop={false} />
            </Box>
            <Box className={classes.completedDialogTextContainer}>
              <Typography variant="h5">Great job! You&apos;re set up!</Typography>
              <Typography variant="body1">
                You&apos;ve completed the onboarding checklist and are now ready to dive in and get the most out of your
                experience.
              </Typography>
            </Box>
            <Button
              hasFullWidth
              variant="contained"
              onClick={() => {
                completeChecklist();
              }}
            >
              Go to Dashboard
            </Button>
          </DialogContent>
        </Dialog>
      ) : null}
      {isAddPropertyDialogOpen ? (
        <AddPropertyDialog
          isOpen
          closeDialog={() => {
            setIsAddPropertyDialogOpen(false);
          }}
          onAddProperty={() => {
            history.push('/properties');
          }}
        />
      ) : null}
      {isLinkAccountDialogOpen ? (
        <AddBankingAccountDialog
          isOpen
          closeDialog={() => {
            setIsLinkAccountDialogOpen(false);
          }}
          onAddAccount={() => {
            history.push('/accounts/banking');
          }}
          accountType="linked"
        />
      ) : null}
      {isAddLoanDialogOpen ? (
        <AddLoanDialog
          isOpen
          closeDialog={() => {
            setIsAddLoanDialogOpen(false);
            if (hasLoanBeenAdded) {
              history.push('/accounts/loans');
            }
          }}
          onAddAccount={() => {
            setHasLoanBeenAdded(true);
          }}
        />
      ) : null}
      {isSubscribeDialogOpen ? (
        <SubscribeDialog
          isOpen
          closeDialog={() => {
            setIsSubscribeDialogOpen(false);
            getData();
          }}
        />
      ) : null}
      <Box className={classes.root}>
        <Box className={classes.headerContainer}>
          <Typography variant="h5">Get Started</Typography>
        </Box>
        <Card className={classes.contentCard}>
          <Box className={classes.progressContainer}>
            <BorderLinearProgress variant="determinate" value={progressPercentage} className={classes.progressBar} />
            <Typography variant="body2" className={classes.progressPercentage}>{`${progressPercentage}%`}</Typography>
          </Box>
          <Box className={classes.checklistRoot}>
            {checklistState.map((item, index) => (
              <Box key={item.id} className={getRowClasses(item, index)}>
                <Box
                  className={
                    item.status
                      ? classes.completeChecklistItemIconContainer
                      : classes.incompleteChecklistItemIconContainer
                  }
                >
                  {getRowIcon(item)}
                </Box>
                <Box className={classes.checklistItemTextContainer}>
                  <Box className={classes.checklistItemTitleAndTimeContainer}>
                    <Typography variant="subtitle1" className={item.status ? classes.completedOrSkippedText : null}>
                      {`${item.id}. ${item.title}`}
                    </Typography>
                    {getRowHeaderSecondaryText(item)}
                  </Box>
                  {item.status ? null : <Typography variant="body2">{item.description}</Typography>}
                  {!item.status && isSmallScreen && (
                    <Box className={classes.checklistItemActionsContainer}>
                      {item.buttonText ? (
                        <Box className={classes.checklistItemActionsButton}>
                          <Button
                            hasFullWidth
                            variant="outlined"
                            onClick={() => {
                              getPrimaryButtonAction(item);
                            }}
                          >
                            {item.buttonText}
                          </Button>
                        </Box>
                      ) : null}
                      <Box className={classes.checklistItemSecondaryButtonContainer}>
                        {getSecondaryActionButton(item)}
                      </Box>
                    </Box>
                  )}
                </Box>
                {item.status === GET_STARTED_CHECKLIST_ITEM_STATUS.SKIPPED && isSmallScreen && (
                  <Box className={classes.checklistItemSecondaryButtonContainer}>{getSecondaryActionButton(item)}</Box>
                )}
                {item.status !== GET_STARTED_CHECKLIST_ITEM_STATUS.COMPLETED && !isSmallScreen && (
                  <Box className={classes.checklistItemActionsContainer}>
                    {item.buttonText && !item.status ? (
                      <Box className={classes.checklistItemActionsButton}>
                        <Button
                          hasFullWidth
                          variant="outlined"
                          onClick={() => {
                            getPrimaryButtonAction(item);
                          }}
                        >
                          {item.buttonText}
                        </Button>
                      </Box>
                    ) : null}
                    <Box className={classes.checklistItemSecondaryButtonContainer}>
                      {getSecondaryActionButton(item)}
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Card>
      </Box>
    </>
  );
}
