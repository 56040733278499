import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import AddFixedAssetDialog from '~/components/AddFixedAssetDialog';
import AddVendorDialog from '~/components/AddVendorDialog';
import Button from '~/components/Button';
import { getManualBasicJournal } from '~/components/QuickBookRow/../SearchSelect/TransactionTypeOptions';
import QuickBookRowActions from '~/components/QuickBookRow/QuickBookRowActions';
import QuickBookRowAutocomplete from '~/components/QuickBookRow/QuickBookRowAutocomplete';
import QuickBookRowNotesAndAttachment from '~/components/QuickBookRow/QuickBookRowNotesAndAttachment';
import QuickBookRowScopeSelect from '~/components/QuickBookRow/QuickBookRowScopeSelect';
import { useStyles } from '~/components/QuickBookRow/transactionTemplates/styles';
import { PersonContext } from '~/contexts/PersonContext';
import { create } from '~/feathersFunctionalWrapper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import {
  getAccountOptions,
  getFixedAssetOptions,
  getVendorOptions,
} from '~/helpers/utils/functionalAutocompleteLibrary';

export default function QuickBookRowFixedAssetPurchaseTemplate({
  transaction,
  onTransactionAction,
  onTransactionTypeChange,
  onTemplateLoad,
}) {
  const classes = useStyles();
  const isMobile = checkIsSmallScreen();
  const tracking = useSegmentTrack();
  const { organizationId, vendorTracking } = useContext(PersonContext);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldAddRule, setShouldAddRule] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddFixedAssetDialogOpen, setIsAddFixedAssetDialogOpen] = useState(false);
  const [isAddVendorDialogOpen, setIsAddVendorDialogOpen] = useState(false);
  const [isCapitalizationBoxDisplayed, setIsCapitalizationBoxDisplayed] = useState(false);
  const [journal, setJournal] = useState(null);
  const [error, setError] = useState(null);

  const [fixedAssetOptions, setFixedAssetOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    const getData = async () => {
      const fixedAssetOptions = await getFixedAssetOptions(organizationId, true);
      const vendorOptions = await getVendorOptions(organizationId, true);
      const accountOptions = await getAccountOptions(organizationId, { type2: 'Fixed Asset' });
      setFixedAssetOptions(fixedAssetOptions);
      setVendorOptions(vendorOptions);
      setAccountOptions(accountOptions);

      const journalObject = getManualBasicJournal();
      const { account, id, description, date, amount } = transaction;
      journalObject.creditLines[0].accountIdSelect = account;
      journalObject.creditLines[0].yodleeTransactionId = id;
      journalObject.creditLines[0].credit = amount;
      journalObject.debitLines[0].debit = amount;
      journalObject.description = description;
      journalObject.date = date;
      journalObject.amount = amount;
      journalObject.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
      setJournal(journalObject);
      setIsLoading(false);
      onTemplateLoad();
    };
    getData();
  }, [isLoading, onTemplateLoad, organizationId, transaction]);

  const handleSwitchToExpense = () => {
    const expenseTransactionType = { label: 'Expense', value: 'expense' };
    onTransactionTypeChange(expenseTransactionType);
  };

  const addVendor = async (newVendor) => {
    const vendorOptions = await getVendorOptions(organizationId, true);
    setVendorOptions(vendorOptions);

    setJournal({ ...journal, vendorIdSelect: newVendor });
    setIsAddVendorDialogOpen(false);
  };

  const addFixedAsset = async (newFixedAsset) => {
    const fixedAssetOptions = await getFixedAssetOptions(organizationId, true);
    setFixedAssetOptions(fixedAssetOptions);
    setJournal({ ...journal, fixedAssetIdSelect: newFixedAsset });
    setIsAddFixedAssetDialogOpen(false);
  };

  const addFixedAssetPurchase = async (event) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const journalSubmit = { ...journal };

    journalSubmit.organizationId = organizationId;
    journalSubmit.type = 'Fixed Asset Purchase';

    if (journalSubmit.fixedAssetIdSelect) {
      journalSubmit.fixedAssetId = journal.fixedAssetIdSelect.id;
    }

    journalSubmit.vendorId = journalSubmit.vendorIdSelect?.id;

    journalSubmit.journalLines = journalSubmit.debitLines.concat(journalSubmit.creditLines);

    journalSubmit.journalLines.forEach((line) => {
      if (line.accountIdSelect) {
        line.accountId = line.accountIdSelect.id;
      }
    });

    try {
      create('journals', journalSubmit);
    } catch (err) {
      setError(err);
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    onTransactionAction(shouldAddRule, journalSubmit);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {isAddVendorDialogOpen ? (
        <AddVendorDialog
          isOpen
          closeDialog={() => {
            setIsAddVendorDialogOpen(false);
          }}
          onAddVendor={addVendor}
        />
      ) : null}
      {isAddFixedAssetDialogOpen ? (
        <AddFixedAssetDialog
          isOpen
          closeDialog={() => {
            setIsAddFixedAssetDialogOpen(false);
          }}
          onAddFixedAsset={(newFixedAsset) => {
            addFixedAsset(newFixedAsset);
          }}
        />
      ) : null}
      {journal.debitLines[0].debit <= 2500 && !isCapitalizationBoxDisplayed && (
        <Alert severity="info" className={classes.infoAlert}>
          {'Purchases of $2,500 or less may be eligible for the de minimis election. '}
          <Box component="span" className={classes.link} onClick={() => setIsCapitalizationBoxDisplayed(true)}>
            Learn more.
          </Box>
        </Alert>
      )}
      {isCapitalizationBoxDisplayed && (
        <Alert severity="info" className={classes.infoAlert}>
          {`The IRS allows you to make a de minimis safe harbor election.
            Taxpayers making this election may expense, rather than capitalize, items costing up to $2,500.
            This typically reduces upfront taxes and simplifies record keeping. `}
          <Box component="span" className={classes.link} onClick={() => handleSwitchToExpense()}>
            Use an expense
          </Box>
          {' to record these transactions in REI Hub if you will be making this common election.'}
        </Alert>
      )}
      <form onSubmit={addFixedAssetPurchase}>
        <Box className={classes.rootContainer}>
          <Box className={classes.container}>
            <QuickBookRowAutocomplete
              options={fixedAssetOptions}
              onChange={(value) => {
                if (value?.id === 'New Instance') {
                  setIsAddFixedAssetDialogOpen(true);
                  return;
                }
                setJournal({ ...journal, fixedAssetIdSelect: value });
              }}
              label="Fixed Asset"
              value={journal.fixedAssetIdSelect}
              required
            />
            <QuickBookRowAutocomplete
              options={accountOptions}
              onChange={(value) => {
                setJournal({ ...journal, debitLines: [{ ...journal.debitLines[0], accountIdSelect: value }] });
              }}
              label="Asset Account"
              value={journal.debitLines[0].accountIdSelect}
              required
            />
            {vendorTracking && (
              <QuickBookRowAutocomplete
                options={vendorOptions}
                onChange={(value) => {
                  if (value?.id === 'New Instance') {
                    setIsAddVendorDialogOpen(true);
                    return;
                  }
                  setJournal({ ...journal, vendorIdSelect: value });
                }}
                label="Vendor (optional)"
                value={journal.vendorIdSelect}
              />
            )}
          </Box>
          <Box className={classes.container}>
            <TextField
              className={isMobile ? classes.mobileDescriptionField : classes.descriptionField}
              label="Description"
              placeholder="Description"
              variant="outlined"
              size="small"
              value={journal.description}
              onChange={(event) => {
                setJournal({ ...journal, description: event.target.value });
              }}
            />
          </Box>
          <QuickBookRowScopeSelect journal={journal} setJournal={setJournal} />
          <QuickBookRowNotesAndAttachment
            journal={journal}
            onChange={(newJournal) => {
              setJournal(newJournal);
            }}
          />
          {error && error.message ? (
            <Alert severity="error" className={classes.errorAlert}>
              {error.message}
            </Alert>
          ) : null}
          <Box className={classes.actionsContainer}>
            <QuickBookRowActions
              journal={journal}
              setJournal={setJournal}
              transaction={transaction}
              onTransactionAction={onTransactionAction}
            />
            <Box className={classes.rowActionsSecondaryContainer}>
              <Box className={classes.checkboxContainer}>
                <Checkbox
                  color="primary"
                  checked={shouldAddRule}
                  onClick={() => {
                    tracking('create_rule clicked', { location: 'Inline Booking - Import Feed' });
                    setShouldAddRule(!shouldAddRule);
                  }}
                />
                <Typography variant="body1">Create rule after saving</Typography>
              </Box>
              <Button
                variant="contained"
                className={classes.saveButton}
                size="small"
                fullWidth={false}
                type="submit"
                onClick={() => {
                  tracking('save clicked', { location: 'Inline Booking - Import Feed' });
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
}

QuickBookRowFixedAssetPurchaseTemplate.propTypes = {
  transaction: PropTypes.object.isRequired,
  onTransactionAction: PropTypes.func.isRequired,
  onTransactionTypeChange: PropTypes.func.isRequired,
  onTemplateLoad: PropTypes.func.isRequired,
};
