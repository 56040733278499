import React from 'react';
import PropTypes from 'prop-types';

export default function ContentPasteOff({ color, ...rest }) {
  return (
    <svg height="24px" width="24px" fill={color} {...rest}>
      <path d="M21.19,21.19L2.81,2.81L1.39,4.22L3,5.83V19c0,1.1,0.9,2,2,2h13.17l1.61,1.61L21.19,21.19z M5,19V7.83L16.17,19H5z M17,8V5 h2v11.17l2,2V5c0-1.1-0.9-2-2-2h-4.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H5.83l5,5H17z M12,3c0.55,0,1,0.45,1,1s-0.45,1-1,1 s-1-0.45-1-1S11.45,3,12,3z" />
    </svg>
  );
}

ContentPasteOff.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

ContentPasteOff.defaultProps = {
  color: 'currentColor',
  height: 24,
  width: 24,
};
