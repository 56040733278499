import React from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import { SIZE, VARIANT } from '~/components/CurrencyField/constants';

const CustomNumberFormat = (props) => {
  const { inputRef, onChange, name, ...rest } = props;
  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
      {...rest}
    />
  );
};

CustomNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      textAlign: (props) => props.align,
    },
  },
}));

export const CurrencyField = ({ align = 'left', label, isRequired, variant, size, onChange, value, ...rest }) => {
  const classes = useStyles({ align });

  return (
    <TextField
      classes={{ root: classes.root }}
      label={label}
      required={isRequired}
      variant={variant}
      value={value}
      size={size}
      backgroundColor="white"
      onChange={onChange}
      InputProps={{
        inputComponent: CustomNumberFormat,
      }}
      {...rest}
    />
  );
};

CurrencyField.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(VARIANT)),
  size: PropTypes.oneOf(Object.values(SIZE)),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};
