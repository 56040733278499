import React from 'react';
import NumberFormat from 'react-number-format';
import { ButtonBase } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import moment from 'moment';
import PropTypes from 'prop-types';

import { nameLabel } from '~/components/Autocomplete/Library';
import { getManualBasicJournal } from '~/components/SearchSelect/TransactionTypeOptions';
import { PersonContext } from '~/contexts/PersonContext';
import { create, find } from '~/feathersWrapper';
import {
  asyncHandleChange,
  handleAutocompleteChange,
  handleTextFieldChange,
  handleUploaderChange,
} from '~/functions/InputHandlers';
import { sumProperty } from '~/functions/SumFunctions';
import { getPartnerLink } from '~/helpers/utils/getPartnerLink';

const styles = (theme) => ({
  hideWidget: {
    display: 'none',
  },
  notesButton: {
    color: theme.palette.secondary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
    margin: 0,
    padding: 0,
  },
});

class AddPartnerDepositDialogContent extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    this.setInitialState();
  }

  setInitialState = async () => {
    const { yodleeTransaction } = this.props;
    const { organizationId } = this.context;
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      displayNotesSection: false,
    };

    const journal = getManualBasicJournal();
    journal.type = 'Transfer';

    const partnerAccountData = await find(this, 'accounts', {
      query: {
        organizationId,
        type2: 'Partner',
      },
    });

    if (yodleeTransaction) {
      journal.debitLines[0].accountIdSelect = yodleeTransaction.account;
      journal.debitLines[0].yodleeTransactionId = yodleeTransaction.id;
      journal.debitLines[0].debit = yodleeTransaction.amount;
      journal.description = yodleeTransaction.description;
      journal.date = yodleeTransaction.date;

      if (
        journal.description === null ||
        !(
          journal.description.toLowerCase().includes('propay') ||
          journal.description.toLowerCase().includes('turbotenant')
        )
      ) {
        const manualAccount = partnerAccountData.data.find((account) => account.default.includes('Manual'));
        if (manualAccount) {
          journal.creditLines[0].accountIdSelect = manualAccount;
        }
      } else {
        const electronicAccount = partnerAccountData.data.find((account) => !account.default.includes('Manual'));
        if (electronicAccount) {
          journal.creditLines[0].accountIdSelect = electronicAccount;
        }
      }
    }

    initialState.journal = journal;

    initialState.partnerAccountOptions = partnerAccountData.data;

    this.setState(initialState);
  };

  formatNegative = (value, transaction) => {
    const { classes } = this.props;
    if (transaction.account.type === 'Asset') {
      if (transaction.yodleeType === 'CREDIT') {
        return value;
      }
      if (transaction.yodleeType === 'DEBIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    if (transaction.account.type === 'Liability') {
      if (transaction.yodleeType === 'DEBIT') {
        return value;
      }
      if (transaction.yodleeType === 'CREDIT') {
        return <span className={classes.red}>{`(${value})`}</span>;
      }
    }
    return value;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true });
    closeDialog();
  };

  addTransaction = async (event) => {
    event.preventDefault();
    const { submitting, journal } = this.state;

    const { onAddTransaction } = this.props;
    const { organizationId } = this.context;

    if (submitting) {
      return;
    }

    journal.creditLines[0].credit = journal.debitLines[0].debit;
    journal.creditLines[0].accountId = journal.creditLines[0].accountIdSelect.id;
    journal.debitLines[0].accountId = journal.debitLines[0].accountIdSelect.id;

    const debitAmount = sumProperty(journal.debitLines, 'debit');

    if (!journal.date) {
      this.setState({ error: { message: 'Please enter a valid date for this transaction' } });
      return;
    }

    this.setState({ submitting: true });

    journal.organizationId = organizationId;
    journal.amount = debitAmount;
    journal.journalLines = journal.debitLines.concat(journal.creditLines);

    // errors will be displayed within the dialog rather than throwing to the error boundary
    // since they occur on the server I should still be notified
    create(this, 'journals', journal, true)
      .then((result) => {
        this.closeDialog();
        onAddTransaction(result);
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  render() {
    const { yodleeTransaction, classes } = this.props;
    const { partnerName } = this.context;
    const { loading, error, journal, partnerAccountOptions, displayNotesSection } = this.state;

    if (loading) {
      return null;
    }

    return (
      <form onSubmit={this.addTransaction}>
        <DialogTitle id="alert-dialog-title">Add Deposit</DialogTitle>
        <DialogContent>
          <Box border={1} borderColor="grey.500" borderRadius="borderRadius" padding={2} marginY={2}>
            <Typography variant="body2">
              {`Use this template to record the deposit of funds when a paid invoice (electronic or manually marked as paid) exists in ${partnerName}.
              This is not additional revenue. Invoices from ${partnerName} are automatically synced into REI Hub and revenue is recorded to the correct property and unit through these automated payments. `}
              <a href={getPartnerLink(partnerName)} target="_blank" rel="noopener noreferrer">
                Learn more about partner payments.
              </a>
            </Typography>
          </Box>
          {yodleeTransaction && (
            <FormControl margin="dense" fullWidth>
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transaction
              </FormLabel>
              <Typography>
                {moment(yodleeTransaction.date).format('M/D/YYYY')}
                <Box px={2} component="span">
                  |
                </Box>
                <NumberFormat
                  displayType="text"
                  value={yodleeTransaction.amount}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  renderText={(value) => this.formatNegative(value, yodleeTransaction)}
                />
                <Box px={2} component="span">
                  |
                </Box>
                {yodleeTransaction.account.name}
              </Typography>
            </FormControl>
          )}
          <Autocomplete
            options={partnerAccountOptions}
            getOptionLabel={nameLabel}
            value={journal.creditLines[0].accountIdSelect}
            onChange={handleAutocompleteChange('nested_journal_creditLines_0_accountIdSelect', this)}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Partner Payment Account"
                placeholder="Type to Search"
                fullWidth
                required
              />
            )}
          />
          <TextField
            label="Description (optional)"
            fullWidth
            margin="dense"
            InputProps={{
              value: journal.description,
              name: 'nested_journal_description',
              onChange: handleTextFieldChange(this),
            }}
          />
          {displayNotesSection && (
            <TextField
              label="Additional Notes (optional)"
              fullWidth
              multiline
              minRows="3"
              maxRows="8"
              variant="filled"
              color="secondary"
              margin="dense"
              InputProps={{
                value: journal.notes,
                name: 'nested_journal_notes',
                onChange: handleTextFieldChange(this),
              }}
            />
          )}
          {!displayNotesSection && (
            <Box pb={2} pt={2}>
              <ButtonBase
                component="div"
                className={classes.notesButton}
                onClick={() => {
                  this.setState({ displayNotesSection: true });
                }}
              >
                Add Additional Notes
              </ButtonBase>
            </Box>
          )}
          <FormControl margin="dense">
            <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
              Attachments
            </FormLabel>
            <div className={journal.attachmentURL ? null : classes.hideWidget}>
              <Widget
                ref={this.uploadcareWidget}
                multiple
                tabs="file url camera"
                value={journal.attachmentURL}
                onChange={handleUploaderChange('nested_journal_attachmentURL', this)}
              />
            </div>
            {!journal.attachmentURL && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.editImageButton}
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to Add Pictures or Files
              </Button>
            )}
            {journal.attachmentURL && (
              <Button
                color="primary"
                className={classes.editImageButton}
                onClick={async () => {
                  await asyncHandleChange('nested_journal_attachmentURL', '', this);
                  this.uploadcareWidget.current.reloadInfo();
                }}
              >
                Remove All Attachments
              </Button>
            )}
          </FormControl>
          <Typography color="error">{error && error.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disableElevation>
            Save Deposit
          </Button>
          <Button onClick={this.closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </form>
    );
  }
}

AddPartnerDepositDialogContent.contextType = PersonContext;

AddPartnerDepositDialogContent.defaultProps = {
  yodleeTransaction: null,
  unitId: null,
  propertyId: null,
  bankAccountId: null,
};

AddPartnerDepositDialogContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddTransaction: PropTypes.func.isRequired,
  unitId: PropTypes.node,
  propertyId: PropTypes.node,
  bankAccountId: PropTypes.node,
  yodleeTransaction: PropTypes.objectOf(PropTypes.any),
};

export default withStyles(styles)(AddPartnerDepositDialogContent);
