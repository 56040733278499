import makeStyles from '@material-ui/core/styles/makeStyles';

export const COLOR = {
  DEFAULT: 'default',
  ERROR: 'error',
  INFO: 'info',
  INHERIT_TEXT: 'inheritText',
  INHERIT_WHITE: 'inheritWhite',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const useStyles = makeStyles((theme) => {
  const colorsMap = {
    [COLOR.DEFAULT]: {
      color: theme.palette.action.active,
      onFocus: {
        backgroundColor: theme.palette.action.focus,
      },
      onHover: {
        backgroundColor: theme.palette.action.hover,
      },
    },
    [COLOR.ERROR]: {
      color: theme.palette.error.main,
      onFocus: {
        backgroundColor: theme.palette.error._states.focusVisible,
      },
      onHover: {
        backgroundColor: theme.palette.error._states.hover,
      },
    },
    [COLOR.INFO]: {
      color: theme.palette.info.main,
      onFocus: {
        backgroundColor: theme.palette.info._states.focusVisible,
      },
      onHover: {
        backgroundColor: theme.palette.info._states.hover,
      },
    },
    [COLOR.INHERIT_TEXT]: {
      color: theme.palette.text.primary,
      onFocus: {
        backgroundColor: theme.palette.action.focus,
      },
      onHover: {
        backgroundColor: theme.palette.action.hover,
      },
    },
    [COLOR.INHERIT_WHITE]: {
      color: theme.palette.common.whiteStates.main,
      onFocus: {
        backgroundColor: theme.palette.common.whiteStates.focus,
      },
      onHover: {
        backgroundColor: theme.palette.common.whiteStates.hover,
      },
    },
    [COLOR.PRIMARY]: {
      color: theme.palette.primary.main,
      onFocus: {
        backgroundColor: theme.palette.primary._states.focusVisible,
      },
      onHover: {
        backgroundColor: theme.palette.primary._states.hover,
      },
    },
    [COLOR.SECONDARY]: {
      color: theme.palette.secondary.main,
      onFocus: {
        backgroundColor: theme.palette.secondary._states.focusVisible,
      },
      onHover: {
        backgroundColor: theme.palette.secondary._states.hover,
      },
    },
    [COLOR.SUCCESS]: {
      color: theme.palette.success.main,
      onFocus: {
        backgroundColor: theme.palette.success._states.focusVisible,
      },
      onHover: {
        backgroundColor: theme.palette.success._states.hover,
      },
    },
    [COLOR.WARNING]: {
      color: theme.palette.warning.main,
      onFocus: {
        backgroundColor: theme.palette.warning._states.focusVisible,
      },
      onHover: {
        backgroundColor: theme.palette.warning._states.hover,
      },
    },
  };

  const sizesMap = {
    [SIZE.LARGE]: {
      container: '52px',
      icon: theme.tokens.components.icon.size.xmd,
    },
    [SIZE.MEDIUM]: {
      container: '40px',
      icon: theme.tokens.components.icon.size.md,
    },
    [SIZE.SMALL]: {
      container: '30px',
      icon: theme.tokens.components.icon.size.sm,
    },
  };

  return {
    iconButton: (props) => ({
      'backgroundColor': 'transparent',
      'height': sizesMap[props.size].container,
      'width': sizesMap[props.size].container,
      'color': colorsMap[props.color].color,

      '&:focus-visible': colorsMap[props.color].onFocus,
      '&:hover': colorsMap[props.color].onHover,

      '& svg': {
        height: sizesMap[props.size].icon,
        width: sizesMap[props.size].icon,
      },
    }),
  };
});

export default useStyles;
