import React, { useContext, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

import AutocompleteField from '~/components/TransactionFields/AutocompleteField';
import useScopeFields from '~/components/TransactionFields/hooks/useScopeFields';
import ScopeFields from '~/components/TransactionFields/ScopeFields';
import TransactionTypeSelect from '~/components/TransactionTypeSelect';
import { PersonContext } from '~/contexts/PersonContext';
import {
  getAccountOptions,
  getBalanceSheetAccountOptions,
  getFixedAssetOptions,
  getVendorOptions,
} from '~/helpers/utils/functionalAutocompleteLibrary';

import { TRANSACTION_TYPES } from './constants';

const TRANSACTION_TYPES_MAP = [
  { label: 'Expense', value: TRANSACTION_TYPES.EXPENSE },
  { label: 'Revenue', value: TRANSACTION_TYPES.REVENUE },
  { label: 'Fixed Asset Purchase', value: TRANSACTION_TYPES.FIXED_ASSET_PURCHASE },
  { label: 'Transfer To', value: TRANSACTION_TYPES.TRANSFER_TO },
  { label: 'Transfer From', value: TRANSACTION_TYPES.TRANSFER_FROM },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: theme.spacing(1),
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  transactionTypeSelect: {
    marginBottom: theme.spacing(3),
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export default function Book({
  journal,
  selectedDestinationAccount,
  selectedFixedAsset,
  selectedTransactionType,
  selectedVendor,
  setJournal,
  setSelectedDestinationAccount,
  setSelectedFixedAsset,
  setSelectedTransactionType,
  setSelectedVendor,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [expenseAccountOptions, setExpenseAccountOptions] = useState([]);
  const [revenueAccountOptions, setRevenueAccountOptions] = useState([]);
  const [fixedAssetOptions, setFixedAssetOptions] = useState([]);
  const [fixedAssetAccountOptions, setFixedAssetAccountOptions] = useState([]);
  const [balanceSheetAccountOptions, setBalanceSheetAccountOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);

  const { organizationId, vendorTracking } = useContext(PersonContext);
  const {
    isLoading: isLoadingScopeFields,
    propertyOptions,
    scopeOptions,
    subportfolioOptions,
    unitOptions,
    handlePropertyChange,
    handleScopeChange,
    handleSubportfolioChange,
    handleUnitChange,
  } = useScopeFields({ setJournal });
  const classes = useStyles();

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const [
        expenseAccountOptionsResult,
        revenueAccountOptionsResult,
        fixedAssetOptionsResult,
        fixedAssetAccountOptionsResult,
        balanceSheetAccountOptionsResult,
      ] = await Promise.allSettled([
        getAccountOptions(organizationId, { type: 'Expense' }),
        getAccountOptions(organizationId, { type: 'Revenue' }),
        getFixedAssetOptions(organizationId),
        getAccountOptions(organizationId, { type2: 'Fixed Asset' }),
        getBalanceSheetAccountOptions(organizationId),
      ]);
      setExpenseAccountOptions(expenseAccountOptionsResult.value);
      setRevenueAccountOptions(revenueAccountOptionsResult.value);
      setFixedAssetOptions(fixedAssetOptionsResult.value);
      setFixedAssetAccountOptions(fixedAssetAccountOptionsResult.value);
      setBalanceSheetAccountOptions(balanceSheetAccountOptionsResult.value);

      if (vendorTracking) {
        const vendorOptions = await getVendorOptions(organizationId);
        setVendorOptions(vendorOptions);
      }

      setIsLoading(false);
    }
    getData();
  }, [organizationId, vendorTracking]);

  if (isLoading || isLoadingScopeFields) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <TransactionTypeSelect
        className={classes.transactionTypeSelect}
        selectedTransactionType={selectedTransactionType}
        transactionTypes={TRANSACTION_TYPES_MAP}
        onTransactionTypeChange={setSelectedTransactionType}
      />

      {selectedTransactionType ? (
        <div className={classes.fields}>
          {selectedTransactionType.value === TRANSACTION_TYPES.EXPENSE && (
            <AutocompleteField
              label="Expense Account"
              options={expenseAccountOptions}
              required
              value={selectedDestinationAccount}
              onChange={(value) => setSelectedDestinationAccount(value)}
            />
          )}
          {selectedTransactionType.value === TRANSACTION_TYPES.REVENUE && (
            <AutocompleteField
              label="Revenue Account"
              options={revenueAccountOptions}
              required
              value={selectedDestinationAccount}
              onChange={(value) => setSelectedDestinationAccount(value)}
            />
          )}
          {selectedTransactionType.value === TRANSACTION_TYPES.FIXED_ASSET_PURCHASE && (
            <>
              <AutocompleteField
                label="Fixed Asset"
                options={fixedAssetOptions}
                required
                value={selectedFixedAsset}
                onChange={(value) => setSelectedFixedAsset(value)}
              />
              <AutocompleteField
                label="Fixed Asset Account"
                options={fixedAssetAccountOptions}
                required
                value={selectedDestinationAccount}
                onChange={(value) => setSelectedDestinationAccount(value)}
              />
            </>
          )}
          {selectedTransactionType.value === TRANSACTION_TYPES.TRANSFER_TO && (
            <AutocompleteField
              label="Transfer To Account"
              options={balanceSheetAccountOptions}
              required
              value={selectedDestinationAccount}
              onChange={(value) => setSelectedDestinationAccount(value)}
            />
          )}
          {selectedTransactionType.value === TRANSACTION_TYPES.TRANSFER_FROM && (
            <AutocompleteField
              label="Transfer From Account"
              options={balanceSheetAccountOptions}
              required
              value={selectedDestinationAccount}
              onChange={(value) => setSelectedDestinationAccount(value)}
            />
          )}
          {[TRANSACTION_TYPES.EXPENSE, TRANSACTION_TYPES.FIXED_ASSET_PURCHASE].includes(
            selectedTransactionType.value,
          ) &&
            vendorOptions.length > 0 && (
              <AutocompleteField
                label="Vendor (optional)"
                options={vendorOptions}
                value={selectedVendor}
                onChange={(value) => setSelectedVendor(value)}
              />
            )}

          <ScopeFields.TransactionScopeField journal={journal} options={scopeOptions} onChange={handleScopeChange} />
          <ScopeFields.SubportfolioField
            journal={journal}
            options={subportfolioOptions}
            onChange={handleSubportfolioChange}
          />
          <ScopeFields.PropertyField journal={journal} options={propertyOptions} onChange={handlePropertyChange} />
          {unitOptions.length > 0 && (
            <ScopeFields.UnitField journal={journal} options={unitOptions} onChange={handleUnitChange} />
          )}
        </div>
      ) : null}
    </div>
  );
}

Book.propTypes = {
  journal: PropTypes.object,
  selectedDestinationAccount: PropTypes.object,
  selectedFixedAsset: PropTypes.object,
  selectedTransactionType: PropTypes.object,
  selectedVendor: PropTypes.object,
  setJournal: PropTypes.func,
  setSelectedDestinationAccount: PropTypes.func,
  setSelectedFixedAsset: PropTypes.func,
  setSelectedTransactionType: PropTypes.func,
  setSelectedVendor: PropTypes.func,
};
