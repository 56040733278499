import React, { useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, ButtonBase } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import PropTypes from 'prop-types';

import { PersonContext } from '../../contexts/PersonContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
  },
  appBar: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  toolBar: {
    height: '66px',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    lineHeight: 1,
  },
  logo: {
    // imageRendering: '-webkit-optimize-contrast',
  },
  inline: {
    display: 'inline',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10,
  },
  textButton: {
    textDecoration: 'underline',
    color: 'inherit',
    cursor: 'pointer',
    verticalAlign: 'baseline',
    display: 'inline',
  },
  tabContainer: {
    marginLeft: 32,
  },
  tabItem: {
    paddingTop: 23,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 'auto',
  },
  appBarSpacer: {
    width: '50px',
  },
  appBarGrow: {
    flexGrow: '1',
  },
  background: {
    flexGrow: 1,
    position: 'relative',
    backgroundImage: 'url(/rei_hub_home_hero_gradient_3.png)',
    backgroundSize: '100% 524px',
    backgroundColor: '#F7F9FB',
    backgroundRepeat: 'no-repeat',
  },
  content: {
    position: 'absolute',
    overflow: 'auto',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  contentFlex: {
    'display': 'flex',
    'flexDirection': 'column',
    'min-height': '100%',
  },
  contentGridContainer: {
    flexGrow: 1,
  },
  contentGridItem: {
    'max-width': theme.breakpoints.values.xl,
  },
  footer: {
    justifyContent: 'center',
    color: '#7F7F7F',
    backgroundColor: '#F7F9FB',
  },
}));

export default function AuthenticateLayout({ children }) {
  const location = useLocation();
  const { id, logOut, adminLogin, setAdminUserSelect } = useContext(PersonContext);
  const classes = useStyles();

  function getLink() {
    if (id) {
      return (
        <Typography variant="body1">
          {adminLogin && (
            <Box component="span" pr={2}>
              <ButtonBase className={classes.textButton} onClick={() => setAdminUserSelect(null)}>
                <Typography variant="body1">Admin</Typography>
              </ButtonBase>
            </Box>
          )}
          <RouterLink className={classes.textButton} to="/" onClick={logOut}>
            Sign Out
          </RouterLink>
        </Typography>
      );
    }
    if (location.pathname.startsWith('/sign-in')) {
      return (
        <Typography variant="body1">
          <Hidden smDown>Need an account?&nbsp;</Hidden>
          <RouterLink className={classes.textButton} to="/register">
            Register
          </RouterLink>
        </Typography>
      );
    }

    if (location.pathname.startsWith('/invite')) {
      return null;
    }

    return (
      <Typography variant="body1">
        <Hidden smDown>Have an account?&nbsp;</Hidden>
        <RouterLink className={classes.textButton} to="/sign-in">
          Sign In
        </RouterLink>
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Hidden mdDown>
            <div className={classes.appBarSpacer} />
          </Hidden>
          <Link href="https://www.reihub.net/" target="_blank" className={classes.link}>
            <img src="/rei_hub_logo_white.svg" height="47px" alt="REI Hub Homepage" className={classes.logo} />
          </Link>
          <div className={classes.appBarGrow} />
          {getLink()}
          <Hidden mdDown>
            <div className={classes.appBarSpacer} />
          </Hidden>
        </Toolbar>
      </AppBar>
      <div className={classes.background}>
        <div className={classes.content}>
          <div className={classes.contentFlex}>
            <Grid container justifyContent="center" className={classes.contentGridContainer}>
              <Grid item xs={12} className={classes.contentGridItem}>
                {children}
              </Grid>
            </Grid>
            <AppBar position="relative" elevation={0}>
              <Toolbar className={classes.footer}>
                <Typography variant="caption" color="inherit" align="center">
                  {`© ${moment().format('YYYY')}, REI Hub`}
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
        </div>
      </div>
    </div>
  );
}

AuthenticateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
