import React from 'react';
import PropTypes from 'prop-types';

export default function DriveFileRenameOutlineOutlined({ color, ...rest }) {
  return (
    <svg fill={color} viewBox="0 0 24 24" {...rest}>
      <path d="m15 16-4 4h10v-4zm-2.94-8.81L3 16.25V20h3.75l9.06-9.06zM5.92 18H5v-.92l7.06-7.06.92.92zm12.79-9.96c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75z" />
    </svg>
  );
}

DriveFileRenameOutlineOutlined.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

DriveFileRenameOutlineOutlined.defaultProps = {
  color: 'currentColor',
  height: 24,
  width: 24,
};
