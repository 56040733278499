import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CallSplitOutlinedIcon from '@material-ui/icons/CallSplitOutlined';
import PropTypes from 'prop-types';

import ContentPasteOffOutlinedIcon from '~/icons/ContentPasteOffOutlined';
import DriveFileRenameOutlineOutlinedIcon from '~/icons/DriveFileRenameOutlineOutlined';

import { RULE_TYPES } from './constants';

export const RULE_TYPES_MAP = {
  [RULE_TYPES.BOOK]: {
    ...RULE_TYPES.BOOK,
    label: 'Book as',
    icon: <DriveFileRenameOutlineOutlinedIcon />,
  },
  [RULE_TYPES.IGNORE]: {
    ...RULE_TYPES.IGNORE,
    label: 'Ignore it',
    icon: <ContentPasteOffOutlinedIcon />,
  },
  [RULE_TYPES.SPLIT]: {
    ...RULE_TYPES.SPLIT,
    label: 'Split into',
    icon: <CallSplitOutlinedIcon />,
  },
};

const useStyles = makeStyles((theme) => ({
  selectIcon: {
    height: theme.tokens.components.icon.size.md,
    width: theme.tokens.components.icon.size.md,
    color: theme.palette.secondary.main,
  },
  select: {
    'padding': 0,
    'paddingLeft': theme.spacing(1.25),
    'paddingRight': theme.spacing(3.5),

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  input: {
    color: theme.palette.secondary.main,
    ...theme.typography.h6,
  },
  menuItem: {
    'padding': `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    'width': '220px',
    'color': theme.palette.text.primary,

    '& > div': {
      marginRight: theme.spacing(3.5),
      color: theme.palette.action.active,
    },
  },
  menuIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function RuleTypeSelect({ ruleType, setRuleType }) {
  const classes = useStyles();

  return (
    <Select
      classes={{
        icon: classes.selectIcon,
        menu: classes.selectMenu,
        select: classes.select,
      }}
      input={<InputBase classes={{ input: classes.input }} />}
      value={ruleType}
      onChange={(event) => setRuleType(event.target.value)}
      renderValue={(value) => RULE_TYPES_MAP[value].label.toLowerCase()}
    >
      {Object.keys(RULE_TYPES_MAP).map((ruleType) => (
        <MenuItem classes={{ root: classes.menuItem }} key={ruleType} value={ruleType}>
          <div className={classes.menuIconContainer}>{RULE_TYPES_MAP[ruleType].icon}</div>
          {RULE_TYPES_MAP[ruleType].label}
        </MenuItem>
      ))}
    </Select>
  );
}

RuleTypeSelect.propTypes = {
  ruleType: PropTypes.string.isRequired,
  setRuleType: PropTypes.func.isRequired,
};
